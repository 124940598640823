import GavtForm from './GavtForm';
import RefTable from './table/RefTable';
import CaliTable from './table/CaliTable';

import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { Alert, Space, Modal, Button } from 'antd';

const GavtSelection: React.FC = () => {
  const [refOpen, setRefOpen] = useState(false);
  const [caliOpen, setCaliOpen] = useState(false);

  return (
    // For real-time frequency display, wrap the GavtForm component in the FreqProvider component
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    }}>
      <h2>Resonance Practice</h2>
      <Space direction="vertical" style={{ width: '50%', marginBottom: '20px'}}>
        {/* <Alert
          style={{ 
            backgroundColor: '#FFEFEF',
            borderColor: '#FFEFEF'
          }} 
          message="Setup"
          description={
            <>
              <div>
                Before you start to practice, it's highly recommended to use the <Link to="/resonance/calibrate"> calibration routine </Link> first. 
                You will get the <a onClick={()=>setCaliOpen(true)}>calibrated frequency values</a> for each vowel sound after the calibration.
                However, if you only wish to use the <a onClick={()=>setRefOpen(true)}>reference frequency</a> as a training target, 
                please select the vowel, gender, region, etc. directly below and start practicing immediately.
              </div>
            </>
          }
          type="info"
        /> */}
      </Space>
      <GavtForm />
      <Modal
        title="Reference Frequency Values"
        centered
        open={refOpen}
        onOk={() => setRefOpen(false)}
        onCancel={() => setRefOpen(false)}
        width={1000}
        footer={[
          <Button key="submit" type="primary" onClick={() => setRefOpen(false)}>
            OK
          </Button>
        ]}
      >
        <RefTable />
      </Modal>
      <Modal
        title="Calibrated Frequency Values"
        centered
        open={caliOpen}
        onOk={() => setCaliOpen(false)}
        onCancel={() => setCaliOpen(false)}
        width={1000}
        footer={[
          <Button key="submit" type="primary" onClick={() => setCaliOpen(false)}>
            OK
          </Button>
        ]}
      >
        <CaliTable />
      </Modal>
    </div>

  );
  };
  
  export default GavtSelection;