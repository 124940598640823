import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { Button, Statistic, Slider, Typography, Col, Row } from 'antd';
import useAudioRecorder from '../function/AudioRecorder';
import { getPitch, IPitchDetectionConfig } from '../function/getPitch';


interface AssessmentProps {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  showModal: () => void;
  handleCancel: () => void;
}

const { Paragraph, Title } = Typography;
const fontAlign = "center";

const Assessment: React.FC<AssessmentProps> = ({ isModalOpen, setIsModalOpen, showModal, handleCancel }) => {
  const [page, setPage] = useState<'intro' | 'recording1' | 'recording2' | 'survey' | 'results' | 'assessment' | 'newPage'>('intro');
  const [showRecording, setShowRecording] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [isStopped, setIsStopped] = useState(true);
  const [seconds, setSeconds] = useState<number>(3);
  const [pitch, setPitch] = useState<number | null>(null);
  const [ePitch, setEPitch] = useState<number[] | null>(null);
  const [eMeanPitch, setEMeanPitch] = useState<number | null>(null);
  const [rainbowPitch, setRainbowPitch] = useState<number[] | null>(null);
  const [rainbowMeanPitch, setRainbowMeanPitch] = useState<number | null>(null);
  const { startRecording, stopRecording, recordedAudioURL } = useAudioRecorder();
  const [displayTimer, setDisplayTimer] = useState(false);
  const [recordingIndicator, setRecordingIndicator] = useState(false);

  const marks = {
    0: 'No Effort',
    100: 'A Lot of Effort',
  };

  const config: IPitchDetectionConfig = {
    SRATE: 48000,
    fxmin: 50,
    fxlow: 50 + 50,
    fxhigh: 600 - 200,
    fxmax: 600
  };

  const handlePitchDetected = (detectedPitch: number | null) => {
    setPitch(detectedPitch);
  };

  const handlePageChange = (newPage: 'intro' | 'recording1' | 'recording2' | 'survey' | 'results' | 'assessment' | 'newPage') => {
    setSeconds(3); // Reset the timer to 3 seconds
    setIsRecording(false); // Reset the recording status
    setPitch(null);
    setIsStopped(true); // Reset the stopped status
    setDisplayTimer(false); // Hide the timer once page changes
    if (newPage === 'recording1' || newPage === 'recording2') {
      setShowRecording(true);
    } else {
      setShowRecording(false);
    }
    setPage(newPage);
  };

  const startTimer = () => {
    setSeconds(3); // Reset the timer to 3 seconds
    setIsRecording(false); // Reset the recording status
    setPitch(null);
    setIsStopped(false); // Reset the stopped status
    setDisplayTimer(true); // Display the timer once start button is pressed
  };

  const redoTimer = () => {
    setIsRecording(false);
    startTimer();
    setIsStopped(true);
    if (page === 'recording1') {
      setEPitch(null);
    } else if (page === 'recording2') {
      setRainbowPitch(null);
    }
  };

  const handleReplay = () => {
    if (recordedAudioURL) {
      const audioElement = new Audio(recordedAudioURL);
      audioElement.play();
    } else {
      console.error('No audio to replay');
    }
  };

  const stopTimer = () => {
    setIsStopped(true); // Set the stopped status to true
    setPitch(null);
  };

  useEffect(() => {
    // Start the timer interval
    const interval = setInterval(() => {
      if (isRecording && !isStopped) {
        setSeconds(prevSeconds => prevSeconds + 1);
        getPitch(config, handlePitchDetected);
      } else {
        setPitch(null); // Reset the pitch value when not recording
        setSeconds(prevSeconds => prevSeconds - 1);
      }
    }, 1000);

    if (page === 'recording1' && pitch !== null) {
      setEPitch(prevEPitch => (prevEPitch ? [...prevEPitch, pitch] : [pitch]));
    } else if (page === 'recording2' && pitch !== null) {
      setRainbowPitch(prevRainbowPitch => (prevRainbowPitch ? [...prevRainbowPitch, pitch] : [pitch]));
    }

    // Clear the interval and set recording status when timer hits 0
    if (seconds === 0) {
      setIsRecording(true);
      setRecordingIndicator(true);
      startRecording();
    }
    if (isStopped || (page === 'recording1' && seconds === 5)) {
      stopRecording();
      setRecordingIndicator(false);
      setPitch(null);
      clearInterval(interval);
      if (ePitch !== null) {
        setEMeanPitch(ePitch.reduce((a, b) => a + b, 0) / ePitch.length);
      }
      if (rainbowPitch !== null) {
        setRainbowMeanPitch(rainbowPitch.reduce((a, b) => a + b, 0) / rainbowPitch.length);
      }
    }
    // Cleanup function to clear the interval when the component unmounts
    return () => clearInterval(interval);
  }, [seconds, isRecording, isStopped]);

  // Calculate minutes and seconds from total seconds
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  // Format minutes and seconds with leading zeros
  const formattedMinutes = String(minutes).padStart(2, '0');
  const formattedSeconds = String(remainingSeconds).padStart(2, '0');

  return (
    <div style={{
      width: '100%',
      height: '5rem', // Fixed height in pixels
      textAlign: 'center',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: '10px' // Ensure no margin at the bottom
    }}>
      {/* Introduction Section */}
      {page === 'intro' && (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
            <Typography>
              <Paragraph style={{ textAlign: fontAlign, fontSize: "35px", width: '40%', margin: '0 auto', marginBottom: '50px' }}>
                To begin your voice assessment, record your voice reading the transcript prompt
              </Paragraph>
              <div style={{ textAlign: 'center' }}>
                <Button onClick={() => handlePageChange('recording1')} style={{ border: '1.5px solid rgb(41, 41, 130)', borderRadius: '30px', fontSize: '17px', background: 'linear-gradient(to left, #5A82E1, #2f2a5a)', WebkitBackgroundClip: 'text', backgroundClip: 'text', color: 'transparent', width: '180px', textAlign: 'center', margin: '0 auto', marginTop: '2px', marginBottom: '20px', letterSpacing: '4px', fontWeight: 'bold', paddingTop: "2px" }}>Begin</Button>
              </div>
            </Typography>
          </div>
        )}

        {showRecording && (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <Typography>
              {page === 'recording1' && (
                <div>
                  <Paragraph style={{ textAlign: fontAlign, fontSize: "35px", width: '40%', margin: '0 auto', marginBottom: '50px' }}>
                    Hold an ee sound for five seconds. Press Record to begin recording.
                  </Paragraph>
                  {!isRecording ? (
                    <div style={{ textAlign: 'center' }}>
                      <Button onClick={startTimer} style={{ marginTop: '50px', border: '1.5px solid rgb(41, 41, 130)', borderRadius: '20px', fontSize: '14px', background: 'linear-gradient(to left, #5A82E1, #2f2a5a)', WebkitBackgroundClip: 'text', backgroundClip: 'text', color: 'transparent', width: '140px', textAlign: 'center', margin: '0 auto', marginBottom: '20px', letterSpacing: '2px', fontWeight: 'bold', paddingTop: '2px' }}>Record</Button>
                      <Button onClick={() => handlePageChange('recording2')} style={{ marginTop: '50px', border: '1.5px solid rgb(41, 41, 130)', borderRadius: '20px', fontSize: '14px', background: 'linear-gradient(to left, #5A82E1, #2f2a5a)', WebkitBackgroundClip: 'text', backgroundClip: 'text', color: 'transparent', width: '140px', textAlign: 'center', margin: '0 auto', marginBottom: '20px', letterSpacing: '2px', fontWeight: 'bold', paddingTop: '2px' }}>Skip</Button>
                    </div>
                  ) : (
                    <div style={{ textAlign: 'center' }}>
                      <Button onClick={stopTimer} style={{ marginTop: '50px', border: '1.5px solid rgb(41, 41, 130)', borderRadius: '20px', fontSize: '14px', background: 'linear-gradient(to left, #5A82E1, #2f2a5a)', WebkitBackgroundClip: 'text', backgroundClip: 'text', color: 'transparent', width: '100px', textAlign: 'center', margin: '0 auto', marginBottom: '20px', letterSpacing: '2px', fontWeight: 'bold', paddingTop: '2px' }}>Stop</Button>
                      <Button onClick={handleReplay} style={{ marginTop: '50px', border: '1.5px solid rgb(41, 41, 130)', borderRadius: '20px', fontSize: '14px', background: 'linear-gradient(to left, #5A82E1, #2f2a5a)', WebkitBackgroundClip: 'text', backgroundClip: 'text', color: 'transparent', width: '100px', textAlign: 'center', margin: '0 auto', marginBottom: '20px', letterSpacing: '2px', fontWeight: 'bold', paddingTop: '2px' }}>Replay</Button>
                      <Button onClick={() => handlePageChange('recording2')} style={{ marginTop: '50px', border: '1.5px solid rgb(41, 41, 130)', borderRadius: '20px', fontSize: '14px', background: 'linear-gradient(to left, #5A82E1, #2f2a5a)', WebkitBackgroundClip: 'text', backgroundClip: 'text', color: 'transparent', width: '110px', textAlign: 'center', margin: '0 auto', marginBottom: '20px', letterSpacing: '2px', fontWeight: 'bold', paddingTop: '2px' }}>Continue</Button>
                      <Button onClick={redoTimer} style={{ marginTop: '50px', border: '1.5px solid rgb(41, 41, 130)', borderRadius: '20px', fontSize: '14px', background: 'linear-gradient(to left, #5A82E1, #2f2a5a)', WebkitBackgroundClip: 'text', backgroundClip: 'text', color: 'transparent', width: '100px', textAlign: 'center', margin: '0 auto', marginBottom: '20px', letterSpacing: '2px', fontWeight: 'bold', paddingTop: '2px' }}>Redo</Button>
                    </div>
                  )}
                  {displayTimer && (
                    <div style={{ textAlign: 'center' }}>
                      {!isRecording && <div>Starting in... {formattedMinutes}:{formattedSeconds}</div>}
                      {isRecording && recordingIndicator && <div>Recording... {formattedMinutes}:{formattedSeconds}</div>}
                      {isRecording && !recordingIndicator && <div>Done... {formattedMinutes}:{formattedSeconds}</div>}
                    </div>
                  )}
                </div>
              )}

              {page === 'recording2' && (
                <div>
                  <Paragraph style={{ textAlign: fontAlign, fontSize: "25px", width: '30%', margin: '0 auto', marginBottom: '-20px', marginTop: '0' }}>
                    Read the following text out loud. Press Record to begin recording, and press Stop when you are finished. <br />
                    <i>"The rainbow is a division of white light into many beautiful colors. These take the shape of a long round arch, with its path high above, and its two ends apparently beyond the horizon"</i>
                  </Paragraph>
                  {!isRecording ? (
                    <div style={{ textAlign: 'center', marginBottom: '20px' }}>
                      <Button onClick={startTimer} style={{ border: '1.5px solid rgb(41, 41, 130)', borderRadius: '30px', fontSize: '17px', background: 'linear-gradient(to left, #5A82E1, #2f2a5a)', WebkitBackgroundClip: 'text', backgroundClip: 'text', color: 'transparent', width: '180px', textAlign: 'center', margin: '0 auto', marginTop: '50px', marginBottom: '20px', letterSpacing: '4px', fontWeight: 'bold', paddingTop: "2px" }}>Record</Button>
                    </div>
                  ) : (
                    <div style={{ textAlign: 'center' }}>
                      <Button onClick={stopTimer} style={{ marginTop: '50px', border: '1.5px solid rgb(41, 41, 130)', borderRadius: '20px', fontSize: '14px', background: 'linear-gradient(to left, #5A82E1, #2f2a5a)', WebkitBackgroundClip: 'text', backgroundClip: 'text', color: 'transparent', width: '90px', textAlign: 'center', margin: '0 auto', marginBottom: '20px', letterSpacing: '2px', fontWeight: 'bold', paddingTop: '2px' }}>Stop</Button>
                      <Button onClick={handleReplay} style={{ marginTop: '50px', border: '1.5px solid rgb(41, 41, 130)', borderRadius: '20px', fontSize: '14px', background: 'linear-gradient(to left, #5A82E1, #2f2a5a)', WebkitBackgroundClip: 'text', backgroundClip: 'text', color: 'transparent', width: '90px', textAlign: 'center', margin: '0 auto', marginBottom: '20px', letterSpacing: '2px', fontWeight: 'bold', paddingTop: '2px' }}>Replay</Button>
                      <Button onClick={() => handlePageChange('survey')} style={{ marginTop: '50px', border: '1.5px solid rgb(41, 41, 130)', borderRadius: '20px', fontSize: '14px', background: 'linear-gradient(to left, #5A82E1, #2f2a5a)', WebkitBackgroundClip: 'text', backgroundClip: 'text', color: 'transparent', width: '100px', textAlign: 'center', margin: '0 auto', marginBottom: '20px', letterSpacing: '2px', fontWeight: 'bold', paddingTop: '2px' }}>Continue</Button>
                      <Button onClick={redoTimer} style={{ marginTop: '50px', border: '1.5px solid rgb(41, 41, 130)', borderRadius: '20px', fontSize: '14px', background: 'linear-gradient(to left, #5A82E1, #2f2a5a)', WebkitBackgroundClip: 'text', backgroundClip: 'text', color: 'transparent', width: '90px', textAlign: 'center', margin: '0 auto', marginBottom: '20px', letterSpacing: '2px', fontWeight: 'bold', paddingTop: '2px' }}>Redo</Button>
                    </div>
                  )}
                  {displayTimer && (
                    <div style={{ textAlign: 'center' }}>
                      {!isRecording && <div>Starting in... {formattedMinutes}:{formattedSeconds}</div>}
                      {isRecording && recordingIndicator && <div>Recording... {formattedMinutes}:{formattedSeconds}</div>}
                      {isRecording && !recordingIndicator && <div>Done... {formattedMinutes}:{formattedSeconds}</div>}
                    </div>
                  )}
                </div>
              )}
            </Typography>
          </div>
        )}

        {page === 'survey' && (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <Typography>
              <Typography.Title level={2} style={{ textAlign: fontAlign, fontSize: "30px", width: '50%', margin: '0 auto', marginBottom: '-40px', marginTop: '40px' }}>Survey Section</Typography.Title>
              <Paragraph style={{ textAlign: fontAlign, fontSize: "20px", maxWidth: '80%', margin: '0 auto', marginBottom: '30px', marginTop: '70px' }}>
                Rate the physical vocal effort needed to create your target voice:
              </Paragraph>
              <div style={{ width: '75%', margin: '0 auto' }}>
                <Slider
                  marks={marks}
                  defaultValue={0}
                  tooltip={{ formatter: null }}
                />
              </div>
              <br />
              <br />
              <Paragraph style={{ textAlign: fontAlign, fontSize: "20px", maxWidth: '80%', margin: '0 auto', marginBottom: '30px', marginTop: '-20px' }}>
                Rate the cognitive vocal effort needed to create your target voice:
              </Paragraph>
              <div style={{ width: '75%', margin: '0 auto' }}>
                <Slider
                  marks={marks}
                  defaultValue={0}
                  tooltip={{ formatter: null }}
                />
                <br />
                <br />
              </div>
              <div style={{ textAlign: 'center' }}>
                <Button onClick={() => handlePageChange('results')} style={{ marginTop: '10px', border: '1.5px solid rgb(41, 41, 130)', borderRadius: '20px', fontSize: '14px', background: 'linear-gradient(to left, #5A82E1, #2f2a5a)', WebkitBackgroundClip: 'text', backgroundClip: 'text', color: 'transparent', width: '185px', textAlign: 'center', margin: '0 auto', marginBottom: '90px', letterSpacing: '2px', fontWeight: 'bold', paddingTop: '2px' }}>Finish Assessment</Button>
              </div>
            </Typography>
          </div>
        )}

        {page === 'results' && (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <Typography>
              <Typography.Title level={2} style={{ textAlign: fontAlign, fontSize: "40px", maxWidth: '80%', margin: '0 auto', marginBottom: '90px', marginTop: '-20px' }}>Results Section</Typography.Title>
              <Row gutter={16}>
                <Col span={12}>
                  <Statistic title="Mean Pitch eee" value={eMeanPitch?.toFixed(1) + ' Hz' ?? undefined} />
                </Col>
                <Col span={12}>
                  <Statistic title="Mean Pitch Rainbow Passage" value={rainbowMeanPitch?.toFixed(1) + ' Hz' ?? undefined} style={{ textAlign: fontAlign, fontSize: "20px", maxWidth: '80%', margin: '0 auto', marginBottom: '30px', marginTop: '-20px' }} />
                </Col>
              </Row>
            </Typography>
          </div>
        )}

        {page === 'assessment' && (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100vh', position: 'relative' }}>
            <div style={{ width: '100%', height: '100%' }}>
              <div style={{ textAlign: 'center', marginTop: '100px' }}>
                <Typography.Title level={4} style={{ textAlign: fontAlign, color: '#6E5F87' }}>ASSESSMENT</Typography.Title>
                <Paragraph style={{ textAlign: fontAlign, fontSize: '18px', maxWidth: '60%', margin: '0 auto' }}>
                  Describe point of assessment here and anything else need to include for this page. Lorem ipsomes esos ipsomes esos ipsom eso lorem ipsomes esos placeholder copy goes here ipsomes esos lipsom.
                </Paragraph>
                <Button style={{ marginTop: '30px', backgroundColor: '#6660AD', color: 'white', borderRadius: '20px', padding: '10px 20px', fontSize: '16px' }}>History</Button>
              </div>
            </div>
          </div>
        )}

      {/* Bottom Section */}
      <div style={{ padding: '0px', backgroundColor: 'white', minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
  <div style={{ textAlign: 'center', marginBottom: '20px' }}>
    <Typography.Title level={2} style={{ textDecoration: 'underline', textDecorationSkipInk: 'auto', textUnderlineOffset: '10px', color: '#8778AA' }}>Assessment</Typography.Title>
    <Typography.Paragraph style={{ fontSize: '20px', maxWidth: '750px', margin: '0 auto', color: '#6E6E73', lineHeight: '40px' }}>
    Using the assessment feature regularly will help you track your progress in achieving your desired voice. This function provides valuable feedback and allows you to adjust your practice as needed. Complete the assessment at your convenience to see how your vocal qualities are improving over time.
    </Typography.Paragraph>
  </div>
</div>




</div>
  );
  };

export default Assessment;
