import React, { useState, useContext, useEffect, useRef } from 'react';
import { useFreq } from "../FreqContext";

import { Table, Input, Form } from 'antd';
const EditableContext = React.createContext(null);

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  inputType = 'number',
  index,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);

  useEffect(() => {
    if (editing) {
      inputRef.current?.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({
        ...record,
        ...values,
      });
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{ margin: 0 }}
        name={dataIndex}
        rules={[
          {
            required: false,
            message: `${title} is required.`,
          },
          {
            type: 'number',
            min: 0,
            max: 4096,
            transform: value => Number(value),
            message: 'The value must be an integer between 0 and 4096.'
          }          
        ]}
      >
        <Input ref={inputRef} onPressEnter={save} onBlur={save} type={inputType}/>
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
          minHeight: '32px',
          display: 'flex',
          alignItems: 'center'
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }  
  return <td {...restProps}>{childNode}</td>;
};

const CaliTable = () => {
  const { freqData, updateFreqData } = useFreq();
  const [data, setData] = useState([]);
  const [form] = Form.useForm();

  // Initialize data from localStorage
  useEffect(() => {
    const fetchData = () => {
      const processedData = Object.keys(freqData.caliBaseline).map(key => {
        const measuredValue = freqData.caliBaseline[key];
        return {
          key,
          vowel: key,
          caliBaseline: measuredValue,
          moderatelyDarker: Math.round((measuredValue * 0.92)),
          slightlyDarker: Math.round((measuredValue * 0.98)),
          slightlyBrighter: Math.round((measuredValue * 1.02)),
          moderatelyBrighter: Math.round((measuredValue * 1.08)),
          customFrequency: freqData.customFrequencies[key] || ''
        };
      });
      console.log("Calibration Data for Table:", freqData.caliBaseline);
      console.log("Custom Frequencies Data for Table:", freqData.customFrequencies);
      setData(processedData);
    };
    fetchData();
  }, [freqData.caliBaseline, freqData.customFrequencies]);

  const columns = [
    {
      title: 'Vowel',
      dataIndex: 'vowel',
      key: 'vowel',
      editable: false
    },
    {
      title: 'Calibrated Baseline (Hz)',
      dataIndex: 'caliBaseline',
      key: 'caliBaseline',
      editable: true
    },
    {
      title: 'Moderately Darker (Hz)',
      dataIndex: 'moderatelyDarker',
      key: 'moderatelyDarker',
      editable: false
    },
    {
      title: 'Slightly Darker (Hz)',
      dataIndex: 'slightlyDarker',
      key: 'slightlyDarker',
      editable: false
    },
    {
      title: 'Slightly Brighter (Hz)',
      dataIndex: 'slightlyBrighter',
      key: 'slightlyBrighter',
      editable: false
    },
    {
      title: 'Moderately Brighter (Hz)',
      dataIndex: 'moderatelyBrighter',
      key: 'moderatelyBrighter',
      editable: false
    },
    {
      title: 'Custom Frequency (Hz)',
      dataIndex: 'customFrequency',
      key: 'customFrequency',
      editable: true
    }
  ].map(col => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  const handleSave = (row) => {
      // const newData = [...data];
      // const index = newData.findIndex((item) => row.key === item.key);
      // const item = newData[index];

      // newData[index] = {
      //   ...item,
      //   ...row,
      // };

      const newData = data.map(item => 
        item.key === row.key ? { ...item, ...row } : item
      );
  

    const updatedData = newData.map(item => ({
      ...item,
      moderatelyDarker: item.caliBaseline ? Math.round(item.caliBaseline * 0.92) : null,
      slightlyDarker: item.caliBaseline ? Math.round(item.caliBaseline * 0.98) : null,
      slightlyBrighter: item.caliBaseline ? Math.round(item.caliBaseline * 1.02) : null,
      moderatelyBrighter: item.caliBaseline ? Math.round(item.caliBaseline * 1.08) : null,
    }));

    setData(updatedData);

    const updateForContext = {
      caliBaseline: { [row.key]: row.caliBaseline },
      customFrequencies: { [row.key]: row.customFrequency }
  };

    updateFreqData(updateForContext);
    
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  return (
    <Form form={form} component={false}>
      <h2>Resonance Frequency Analysis - F2</h2>
      <p>  
        The calibrated Baseline and Custom Value are editable. You can click the cell to edit.
        Effective range: 0 - 4096 Hz, integer only.
      </p>
      <Table
        components={components}
        rowClassName="editable-row"
        bordered
        dataSource={data}
        columns={columns}
        pagination={false}
      />
    </Form>
  );
};

export default CaliTable;
