import { useEffect, useRef, RefObject  } from 'react';
import { map } from '../function/canvasDefault';

// const canvasRef = useRef<HTMLCanvasElement>(null);
function useCanvasMouseText(canvasRef: RefObject<HTMLCanvasElement>, mouseHeight:number, initialRange:number[]) {
    useEffect(() => {
        const canvas = canvasRef.current;
        if (canvas) {
            const ctx = canvas.getContext('2d');
            if (ctx) {
                ctx.font = '16px Arial';
                ctx.fillStyle = 'black';
                const textX = canvas.width - 100;
                const textY = 20;
                const mappedHeight = map(mouseHeight, 0, 100, initialRange[1], initialRange[0]);
                const text = `pitch: ${mappedHeight.toFixed(2)}`;
                ctx.clearRect(textX - 5, textY - 21, ctx.measureText(text).width + 10, 26);
                ctx.fillText(text, textX, textY);
            }
        }
    }, [mouseHeight, initialRange]);
}


export default useCanvasMouseText;