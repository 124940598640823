import React  from  'react';

import { Table } from 'antd';
import { LogoutOutlined } from '@ant-design/icons';

import data from '../data/refFreq.json';

const regions = ['New_England', 'Mid_Atlantic', 'North', 'Midland', 'South', 'West'];

const columns = [
    {
      title: 'Vowel',
      dataIndex: 'Word',
      key: 'Word'
    },
    ...regions.map(region => ({
      title: region.replace('_', ' '),
      dataIndex: ['f2Formant', region],
      key: region
    }))
  ];

const RefTable: React.FC = () => {
  const manData = data.man.map(item => ({
    ...item,
    key: item.Word,
  }));

  const womanData = data.woman.map(item => ({
    ...item,
    key: item.Word,
  }));

    return (
        <div>
            <span>
                Data Source: Clopper, C. G., Pisoni, D. B., & De Jong, K. (2005). Acoustic characteristics of the vowel systems of six regional varieties of American English. 
                The Journal of the Acoustical society of America, 118(3), 1661-1676. 
                  <a 
                    href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3432912/pdf/nihms399880.pdf"
                    target='_blank'
                    rel='noopener noreferrer'
                    style={{ marginLeft: '4px' }}
                  >
                    Link to Paper
                    <LogoutOutlined style={{ marginLeft: '4px' }} />
                  </a>
                  
            </span>
            <h2>Men's Vowel Frequencies</h2>
            <Table columns={columns} dataSource={manData} pagination={false} />
            <h2>Women's Vowel Frequencies</h2>
            <Table columns={columns} dataSource={womanData} pagination={false} />
        </div>
    );
};

export default RefTable;