import { Tabs } from 'antd';
import CustomWave from './CustomWave'
import TutorialGraphDisplay from './TutorialGraphDisplay'

const onChange = (key) => {
  console.log(key);
};

const GavtTutorial = () => {

  return (
      <Tabs
        tabPosition='left'
        onChange={onChange}
        type="card"
        items={[
          {
            label: 'Blank Slide with Wave',
            key: '1',
            children: <CustomWave />,
          },
          {
            label: 'Vowel toggle',
            key: '2',
            children: <TutorialGraphDisplay />
          },
          {
            label: 'Brighter/Darker toggle',
            key: '3',
            children: 'TEST',
          },
        ]}
        style={{ marginTop: '50px', width: '100%', height: '100%' }}
      />
  )
}

export default GavtTutorial;