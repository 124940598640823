import React, { useState, useEffect, useRef } from 'react';
import { Button, Flex, } from 'antd';
import { SettingOutlined, CustomerServiceOutlined } from '@ant-design/icons';
import { Col, Row, Tour, ConfigProvider } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';
import { Slider, Input } from 'antd';
import { Breadcrumb, Layout, Menu, Dropdown, theme, Avatar } from 'antd';
import { Drawer, Cascader, Radio } from 'antd';
import { RedoOutlined, PauseOutlined, StepForwardOutlined, StepBackwardOutlined } from '@ant-design/icons';
import { Tooltip, FloatButton, Space, Switch } from 'antd';
import '../CSS/sample.css';
import { Modal } from 'antd';
import ConstantTxt from '../Canvas/ConstantTxt'
import Fixed from '../Canvas/Fixed'
import Stair from '../Canvas/Stair'
import Heteronym from '../Canvas/Heteronym'
import type { RadioChangeEvent } from 'antd';
import type { TourProps } from 'antd';
import ReactAudioPlayer from 'react-audio-player';
import type { SliderSingleProps } from 'antd';
import type { UploadProps } from 'antd';
import { UploadOutlined, ArrowLeftOutlined, RollbackOutlined } from '@ant-design/icons';
import { message, Upload } from 'antd';
import hark from 'hark';
import { useLocation } from 'react-router-dom';
import { Typography } from 'antd';
import type { CollapseProps } from 'antd';
import { Collapse } from 'antd';
import { Image } from 'antd';
import divide_1 from '../icon/Divider2-half.png'
import { UserOutlined } from '@ant-design/icons';
import { QuestionOutlined } from '@ant-design/icons';
import { adjustCanvasScale, map, drawBackground, generateNotesAndFrequencies } from '../function/canvasDefault';
import { useTemString } from '../hooks/useTemString';
import { useCanvasHooks } from '../hooks/useCanvasHooks';
import type { MenuProps } from 'antd';
// @ts-ignore
import pdfToText from 'react-pdftotext';
import useCanvasAdjustHeight from '../hooksUseEffect/useCanvasAdjustHeight';
import AudioPlayer from 'react-h5-audio-player';
import { createFromIconfontCN } from '@ant-design/icons';
import { useOptionsHooks } from '../hooks/useOptionsHooks';

const PlayIcon = createFromIconfontCN({
  scriptUrl: '//at.alicdn.com/t/c/font_4566050_zpduwnlv2t.js',
});

const { TextArea } = Input;
const { Title, Paragraph, Text, Link } = Typography;
type MenuTheme = 'show' | 'hide';


const { Content } = Layout;

const marksRange: SliderSingleProps['marks'] = {
  50: '50Hz',
  600: '600Hz'
};

const marksBallPos: SliderSingleProps['marks'] = {
  0: '0%',
  1: '100%'
};
const marksVol: SliderSingleProps['marks'] = {
  50: '50dB',
  90: '90dB'
};


interface SampleProps {
  genderName: string;
  gender: string;
  setGender: (str: string) => void;
  audioKey: number;
  audioSrc: string;
  fetchAudioData: () => void;
  handleIconClick: (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
  itemsAvatar: MenuProps['items'];
}

const Sample: React.FC<SampleProps> = ({
  genderName, 
  gender, 
  setGender,
  audioKey,
  audioSrc,
  fetchAudioData,
  handleIconClick,
  itemsAvatar,

}) => {
  const {
    // gender,
    // setGender,
    // genderName,
    audioPlayerNew,
    // audioKey,
    // audioSrc,

    // fetchAudioData,

    // handleIconClick,
    // itemsAvatar

  } = useOptionsHooks();

  // strings
  const {
    items_3,
    stairFilenames,
    heteronymFilenames,
    shortDescription,
  } = useTemString();

  // Canvas values
  const size = [400, 1400];
  const [initialRange, setInitialRange] = useState<number[]>([100, 300]);
  const [config, setConfig] = useState({
    SRATE: 48000,
    fxmin: 50,
    fxlow: 50 + 50, // This initial value will be updated by useEffect below
    fxhigh: 600 - 200, // This initial value will be updated by useEffect below
    fxmax: 600
  });
  const [COLORS, setCOLORS] = useState({
    realVoiceColor: '#d679de', 
    targetVoiceColor: '#4669C8', 
    closeVoiceColor: '#15dbcc', 
  }
);
const [COLORSMode,setCOLORSMode] = useState("de");
  // const COLORS = {
  //   realVoiceColor: '#d679de', // red
  //   targetVoiceColor: '#4669C8', // blue
  //   closeVoiceColor: '#15dbcc', // yellow
  // };

  // open/close 
  const [openSetting, setOpenSetting] = useState(false);
  const [txtShow, setTxtShow] = useState<string>("true");
  const [txtShowSwitch, setTxtShowSwitch] = useState<MenuTheme>('show');
  const [openTour, setOpenTour] = useState<boolean>(false);
  const [isPlaying, setIsPlaying] = useState(true);
  const [isRetry, setIsRetry] = useState(false);
  const [enableVol, setEnableVol] = useState<boolean>(false);
  const [isOpenTextbox, setIsOpenTextbox] = useState<boolean>(false);
  const [showNextPre, setShowNextPre] = useState<boolean>(false);
  const [showListen, setShowListen] = useState<boolean>(false);

  // special vatiables
  const location = useLocation();
  const ref12 = useRef(null);
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const parentRef = useRef<HTMLDivElement>(null);

  // initial Value
  const [volume, setVolume] = useState(0);
  const [threshold, setThreshold] = useState<number>(70);
  const [maxLyricCount, setMaxLyricCount] = useState<number>(2);
  const [playLyricCount, setPlayLyricCount] = useState<number>(0);
  const [divisor, setDivisor] = useState<number>(10);
  const [ballPosition, setBallPosition] = useState<number>(0.5);

  // Hz/Notes
  const [showNotesPar, setShowNotesPar] = useState(false);

  // selected choice
  const [component, setComponent] = useState<string>("ConstantTxt");
  const [currentSelection, setCurrentSelection] = useState('Constant');
  // const [gender, setGender] = useState<string>("Female");
  // const [genderName, setGenderName] = useState<string>("Fem01");
  const [syllableCount, setSyllableCount] = useState<string>("none");
  const [baseFilenames, setBaseFilenames] = useState<string[]>([]);
  const [fileContent, setFileContent] = useState<string[]>([]);
  const [selectedNum, setSelectedNum] = useState<string[]>(["2"]);

  // auido
  const audioPlayer = useRef<ReactAudioPlayer>(null);
  // const audioPlayerNew = useRef<AudioPlayer>(null);
  const [audioSrcFromChild, setAudioSrcFromChild] = useState<string>('');
  const [isListen, setIsListen] = useState<number>(1);

  // profile (Need to rebuild)
  const [selectedItem, setSelectedItem] = useState('Settings');

  // 
  const setPlaying = () => {
    setIsPlaying(true);
  };
  const setPlayingPause = () => {
    setIsPlaying(false);
  };
  const handleBeginTour = () => {
    setOpenTour(true);
  };
  const onClickMenu: MenuProps['onClick'] = (e) => {
    setSelectedItem(e.key);
  };
  const onClose = () => {
    setOpenSetting(false);
  };
  const ClearTxt = () => {
    setFileContent([]);
    setMaxLyricCount(1);
  };

  // Constant Page upload and input
  const handleTextInput = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const content = e.target.value as string;
    const separators = /[;,./\n]/;
    const splitContent = content.split(separators)
      .map(s => s.trim())
      .filter(Boolean);
    console.log(splitContent);
    setFileContent(splitContent);
    setMaxLyricCount(splitContent.length - 1);
    setPlayLyricCount(0);
  }
  const props: UploadProps = {
    name: 'file',
    accept: '.txt, .pdf',
    beforeUpload: (file) => {
      if (file.type !== 'text/plain' && file.type !== 'application/pdf') {
        message.error(`${file.name} is not a valid text/pdf file.`);
        return false;
      }
      if (file.type === 'application/pdf') {
        pdfToText(file)
          .then((text: string) => {
            const separators = /[;,./\n]/;
            const splitContent = text.split(separators)
              .map((s: string) => s.trim())
              .filter(Boolean);
            console.log(splitContent);
            setFileContent(splitContent);
            setMaxLyricCount(splitContent.length - 1);
            setPlayLyricCount(0);
          })
          .catch((error: any) => { // Explicitly specify the type of 'error' parameter as 'any'
            message.error('Error reading PDF file:', error);
          });
        return false;
      }
      if (file.type === 'text/plain') {
        const reader = new FileReader();
        reader.onload = (e: ProgressEvent<FileReader>) => {
          if (e.target?.result) {
            const content = e.target.result as string;
            // const separators = /[;,./]/;
            const separators = /[;,./\n]/;
            const splitContent = content.split(separators)
              .map(s => s.trim())
              .filter(Boolean);
            console.log(splitContent);
            setFileContent(splitContent);
            setMaxLyricCount(splitContent.length - 1);
            setPlayLyricCount(0);
          }
        };

        reader.readAsText(file);

        return false;
      }
    },
    onChange(info) {
      if (info.file.status === 'done') {
        message.success(`${info.file.name} file read successfully`);
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file read failed.`);
      }
    },
  };
  const changeOnEnter = (e: any) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      console.log("Enter Key Pressed!");
      handleTextInput(e);
    }
  }

  // audio
  const handleAudioSrc = (newAudioSrc: string) => {
    setAudioSrcFromChild(newAudioSrc);
  };
  const playAudio = () => {

    // if (audioPlayer.current && audioPlayer.current.audioEl.current) {
    // audioPlayer.current.audioEl.current.play()
    if (audioPlayerNew.current && audioPlayerNew.current.audio.current) {
      audioPlayerNew.current.audio.current.play()
        .then(() => {
          setIsListen(isListen + 1);
        })
        .catch(error => {
          console.error('Fail play audios: ', error);
        });
    }

  };

  // options audio
  useEffect(() => {
    fetchAudioData();
  }, [genderName]);

  // Volume
  useEffect(() => {
    navigator.mediaDevices.getUserMedia({ audio: true, video: false })
      .then(stream => {
        const harkInstance = hark(stream, {});

        harkInstance.on('volume_change', (volume, threshold) => {
          setVolume(volume);
        });

        return () => harkInstance.stop();
      })
      .catch(error => {
        console.error('Error accessing the microphone', error);
      });
  }, []);
  useEffect(() => {
    if (volume > threshold - 100 && enableVol) {
      setIsPlaying(true);
    }
  }, [volume]);

  // Tour
  const steps: TourProps['steps'] = [
    {
      title: 'Pitch Exercises',
      description: 'Switch between different exercises',
      placement: 'top',
      target: () => ref12.current,
    },
    {
      title: 'Canvas Display',
      description: 'Visualize your voice pitch and target pitch curve in real-time.',
      placement: 'top',
      target: () => ref1.current,
    },
    {
      title: 'Function Buttons',
      cover: (
        <ul style={{ textAlign: 'left' }}>
          <li>Start/Stop: Begin or stop recording.</li>
          <li>Retry: Clear display and restart.</li>
          <li>Upload txt/pdf File (optional): Upload text or pdf for you (in .txt/.pdf format) to read while recording.</li>
          <li>Previous Next (if text uploaded): Switch between lines of text.</li>
        </ul>
      ),
      target: () => ref2.current,
    },
    {
      title: 'Slider',
      description: 'Use this slides to change the target pitch value that you want to aim for.',
      target: () => parentRef.current as HTMLDivElement,
    },
    {
      title: 'Options menu',
      description: 'Adjust settings such as the pitch display range.',
      target: () => ref3.current,
    },

  ];
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const init = queryParams.get('init');
    if (init === 'true') {
      handleBeginTour();
    }
  }, [location]);

  // Options of different Exercise 
  const handleStairAndConstantClick = (e: any) => {
    setPlayLyricCount(0);
    setCurrentSelection(e.target.innerText);
    console.log(e.target.innerText);
    if (e.target.innerText === "Constant" || e.target.innerText === "CONSTANT") {
      setComponent("ConstantTxt");
      setSelectedNum(["2"]);

    } else if (e.target.innerText === "Stair" || e.target.innerText === "STAIR") {
      setComponent("Stair");
      setSelectedNum(["3"]);

    } else {
      setComponent("Heteronym");
      setSelectedNum(["4"]);
    }
    setSyllableCount("none");
  }
  // const saveGender = (e: any) => {
  //   const [genderType, genderNameType] = e.key.split('-');
  //   setGender(genderType);
  //   setGenderName(genderNameType);
  //   console.log(genderType);
  //   console.log('genderName', genderName)
  // }
  const handleMenuClick = (e: any) => {
    setPlayLyricCount(0);
    const [componentType, genderType, syllableCountType] = e.key.split('-');
    if (componentType == "Constant") {
      setCurrentSelection("Constant");
    } else if (componentType == "ConstantTxt") {
      setCurrentSelection("Constant");
    } else if (componentType == "Stair") {
      setCurrentSelection("Staircase");
    } else {
      let tt = "Syllables " + syllableCountType
      setCurrentSelection(tt);
      setSelectedNum(["5"]);

    }

    setComponent(componentType);
    setGender(genderType);
    setSyllableCount(syllableCountType);

    setCurrentSelection(e.key + "syllable " + genderName);
  };
  const renderTruncatedFilenames = (filenames: string[]) => {
    return filenames.map((text, index) => {
      const isNearEnd = filenames.length - playLyricCount <= 2;
      const startIndex = isNearEnd ? filenames.length - 3 : playLyricCount;
      const showCondition = index >= startIndex && index <= startIndex + 2;
      return (
        showCondition && (
          <li key={index} className={playLyricCount === index ? 'highlighted' : ''}>{text}</li>
        )
      );
    });
  };
  const handlePlayLyricCountChange = (newCount: number) => {
    setPlayLyricCount(newCount);
  };


  /**
 * Defines the items for the syllable menu based on the gender.
 * If the gender is 'none', it only includes a single item prompting the user to select an avatar.
 * Otherwise, it includes multiple items representing the number of syllables, with corresponding click handlers.
 */
  const itemsSyllable: MenuProps['items'] = gender === 'none' ? [
    {
      key: 'SelectAvatar',
      label: 'Please select avatar',
    },
  ] : [
    {
      key: `Fixed-${gender}-2`,
      label: "2 syllables",
      onClick: handleMenuClick,
    },
    {
      key: `Fixed-${gender}-3`,
      label: "3 syllables",
      onClick: handleMenuClick,
    },
    {
      key: `Fixed-${gender}-4`,
      label: "4 syllables",
      onClick: handleMenuClick,
    },
    {
      key: `Fixed-${gender}-5`,
      label: "5 syllables",
      onClick: handleMenuClick,
    },
  ];
  const onChangeStairSwitch = (value: boolean) => {
    setTxtShowSwitch(value ? 'hide' : 'show');
    setTxtShow(value ? 'true' : 'false');
  };
  const renderSelectedComponent = (): React.ReactNode => {
    switch (component) {
      // case 'Constant':
      //   return <Constant ballPosition={ballPosition} isRetry={isRetry} setPlayingPause={setPlayingPause} setPlaying={setPlaying} COLORS={COLORS} showNotesPar={showNotesPar} size={size} config={config} isPlaying={isPlaying} />;
      case 'ConstantTxt':
        return <ConstantTxt ref={parentRef} initialRange={initialRange} divisor={divisor} ballPosition={ballPosition} isRetry={isRetry} setPlayingPause={setPlayingPause} COLORS={COLORS} showNotesPar={showNotesPar} size={size} config={config} isPlaying={isPlaying} />;
      case 'Stair':
        return <Stair setShowNotesPar={setShowNotesPar} setMaxLyricCount={setMaxLyricCount} initialRange={initialRange} divisor={divisor} txtShow={txtShow} playLyricCount={playLyricCount} setPlayingPause={setPlayingPause} isRetry={isRetry} COLORS={COLORS} showNotesPar={showNotesPar} size={size} config={config} isPlaying={isPlaying} />;
      case 'Fixed':
        return <Fixed setMaxLyricCount={setMaxLyricCount} setIsListen={setIsListen} isListen={isListen} initialRange={initialRange} divisor={divisor} setPlayingPause={setPlayingPause} isRetry={isRetry} onAudioSrcChange={handleAudioSrc} setBaseFilenames={setBaseFilenames} onPlayLyricCountChange={handlePlayLyricCountChange} syllableCount={syllableCount} gender={gender} genderName={genderName} COLORS={COLORS} showNotesPar={showNotesPar} playLyricCount={playLyricCount} size={size} config={config} isPlaying={isPlaying} />;
      case 'Heteronym':
        return <Heteronym setMaxLyricCount={setMaxLyricCount} initialRange={initialRange} divisor={divisor} txtShow={txtShow} playLyricCount={playLyricCount} setPlayingPause={setPlayingPause} isRetry={isRetry} setPlaying={setPlaying} COLORS={COLORS} showNotesPar={showNotesPar} size={size} config={config} isPlaying={isPlaying} heteronymFile={heteronymFilenames} />;
      default:
        return <span>Canvas: Picture</span>;
    }
  }
  const MainButtons = (): React.ReactNode => {
    return (
      <Flex justify="center" wrap="wrap" gap="small">

        <Tooltip title="Retry">
          <Button onClick={() => setIsRetry(!isRetry)} shape="circle" icon={<RedoOutlined />} />
        </Tooltip>
        {/* <ReactAudioPlayer
          className="audio-player-hidden"
          src={audioSrcFromChild}
          ref={audioPlayer}
        /> */}

        <AudioPlayer
          autoPlayAfterSrcChange={false}
          src={audioSrcFromChild}
          ref={audioPlayerNew}
          style={{ display: 'none' }}
        />
        {showListen &&
          <Tooltip title="Listen">
            <Button disabled={component !== "Fixed"} shape="circle" onClick={playAudio} icon={<CustomerServiceOutlined />} />
          </Tooltip>
        }

        {
          showNextPre &&
          <Tooltip title="Previous">
            <Button shape="circle" onClick={onChangePrevious} icon={<StepBackwardOutlined />} />
          </Tooltip>
        }
        <Tooltip title={isPlaying ? "Pause" : "Play"}>
          <Button onClick={onChangePause} shape="circle" icon={isPlaying ? <PauseOutlined /> : <CaretRightOutlined />} />
        </Tooltip>
        {
          showNextPre &&
          <Tooltip title="Next">
            <Button shape="circle" onClick={onChangeNext} icon={<StepForwardOutlined />} />
          </Tooltip>
        }

      </Flex>
    );
  };
  const renderSelectedComponentLyric = (): React.ReactNode => {
    switch (component) {

      case 'ConstantTxt':
        return (
          <div className='lyric'>

            <Space size="middle">
              {MainButtons()}
              <Space.Compact>

                <Tooltip title="Clear existing files and upload new ones">
                  <Button onClick={ClearTxt}>Clear text</Button>
                </Tooltip>

                <Tooltip title="Upload PDF or .txt file to read during practice">
                  <Upload maxCount={1} {...props}>
                    <Button icon={<UploadOutlined />}>Upload File</Button>
                  </Upload>
                </Tooltip>
                <Tooltip title="Type/Paste text to read during parctice">
                  <Button onClick={() => setIsOpenTextbox(!isOpenTextbox)}>
                    {!isOpenTextbox ? "Enter Text" : "Close Textbox"}
                  </Button>
                </Tooltip>
              </Space.Compact>

            </Space>
            <>
              {isOpenTextbox && fileContent.length === 0 && (
                <TextArea rows={4} placeholder='Enter or paste Text Entry' style={{ height: 40, resize: 'none' }} onKeyDown={changeOnEnter} />
              )

              }

            </>
            <ul>
              {fileContent.map((text, index) => {
                const maxIndexToShow = playLyricCount + 2 >= fileContent.length ? fileContent.length - 1 : playLyricCount + 2;

                const shouldShow = index >= playLyricCount && index <= maxIndexToShow;
                const isNearEnd = fileContent.length - playLyricCount <= 2;
                const startIndex = isNearEnd ? fileContent.length - 3 : playLyricCount;
                const showCondition = index >= startIndex && index <= startIndex + 2;

                return (
                  showCondition && (
                    <li key={index} className={playLyricCount === index ? 'highlighted' : ''}>{text}</li>
                  )
                );
              })}
            </ul>
          </div>
        )
      case 'Stair':
        return (
          <div className='lyric'>
            <Flex align="center" gap="middle">
              {MainButtons()}

              <Tooltip title="Show/Hide 5-syllable phrases to read">
                <Switch defaultChecked checkedChildren="show" unCheckedChildren="hide" onChange={onChangeStairSwitch} />
              </Tooltip>

            </Flex>
            {(() => {
              if (txtShow === 'true') {
                return (
                  <ul>
                    {renderTruncatedFilenames(stairFilenames)}
                  </ul>
                );
              }
              return null;
            })()}

          </div>
        )
      case 'Heteronym':
        return (
          <div className='lyric'>
            {MainButtons()}
            {(() => {
              if (txtShow === 'true') {
                return (
                  <ul>
                    {renderTruncatedFilenames(heteronymFilenames)}
                  </ul>
                );
              }
              return null;
            })()}

          </div>
        )
      case 'Fixed':
        return (
          <div className='lyric'>
            {MainButtons()}
            <ul>
              {renderTruncatedFilenames(baseFilenames)}
            </ul>
          </div>
        )
      default:
        return <span>Canvas: Picture</span>;
    }
  }
  useEffect(() => {
    if (component == "ConstantTxt" && fileContent.length === 0) {
      setShowNextPre(false);
      setShowListen(false);
    } else if (component == "ConstantTxt" && fileContent.length != 0) {
      setShowNextPre(true);
      setShowListen(false);
    }
    else if (component == "Fixed") {
      setShowNextPre(true);
      setShowListen(true);
    } else if (component == "Stair") {
      setShowNextPre(true);
      setShowListen(false);
    } else if (component == "Heteronym") {
      setShowNextPre(true);
      setShowListen(false);
    } else {
      setShowNextPre(true);
      setShowListen(false);
    }
  }
    , [component, fileContent]);

  // Options Changing functions
  const onChangeRange = (rangeValue: number[]) => {
    setInitialRange([rangeValue[0], rangeValue[1]]);
  }
  const onChangePosition = (positionValue: number) => {
    setBallPosition(positionValue);
  }
  const onChangeSpeed = (speedValue: number) => {
    const mappedValue = Math.round((speedValue * 20) + 4);
    setDivisor(mappedValue);
  }
  const onChangeHz = ({ target: { value } }: RadioChangeEvent) => {
    console.log(value);
    if (value === 'hz') {
      setShowNotesPar(false);
    } else {
      setShowNotesPar(true);
    }
  }
  const onChangeColor = ({ target: { value } }: RadioChangeEvent) => {
    console.log(value);
    if (value === 'de') {
      setCOLORSMode(value);
      setCOLORS({
        realVoiceColor: '#d679de', 
        targetVoiceColor: '#4669C8', 
        closeVoiceColor: '#15dbcc', 
      });

    } else {
      setCOLORSMode(value);
      setCOLORS({
        realVoiceColor: '#3399ff', 
        targetVoiceColor: '#ff0000', 
        closeVoiceColor: 'yellow', 
      });    
    }
  }

  const EnableVoluneMode = (e: boolean) => {
    console.log(e);
    if (e) {
      setEnableVol(true);

    } else {
      setEnableVol(false);

    }
  }

  // Button functions
  const onChangePause = () => {
    setIsPlaying(!isPlaying);
  }
  const onChangePrevious = () => {
    const tem = playLyricCount;
    if (tem <= 0) {
      setPlayLyricCount(0);
    } else {
      setPlayLyricCount(tem - 1);
    }
  };
  const onChangeNext = () => {
    const tem = playLyricCount;
    if (tem >= maxLyricCount) {
      setPlayLyricCount(maxLyricCount);
    } else {
      setPlayLyricCount(tem + 1);
    }
  };

  // tooltips
  const onChangeThreshold = (e: number) => { setThreshold(e); };
  const formatterdB = (value: number | undefined) => `${value} dB`;
  const formatterHz = (value: number | undefined) => `${value} Hz`;
  const formatterPos = (value: number | undefined) => `${value ? (value * 100).toFixed(0) : '0'}%`;

  // onChangeCollapse
  const onChangeCollapse = (e: string | string[]) => {
    console.log(e);
    if (Array.isArray(e)) {
      setSelectedNum(e);
    } else {
      setSelectedNum([e]);
    }
  }
  return (
    <Row>
      <Col span={24}>
        <Layout className="layoutSample">

          <Content >

            <Flex align="center" justify="center" gap="middle" vertical>

              <div style={{ marginTop: "2vw" }}>
                <ConfigProvider
                  theme={{
                    token: {
                      colorPrimary: "rgb(132, 118, 171)",
                      borderRadius: 0,
                    },
                  }}
                >

                  <Space size="middle" ref={ref12}>
                    <Space.Compact>

                      <Tooltip title="Switch to the Constant exercise">
                        <Button type={component === "ConstantTxt" ? "primary" : "default"} onClick={handleStairAndConstantClick}>
                          CONSTANT
                        </Button>
                      </Tooltip>

                      <Tooltip title="Switch to the Stair exercise">
                        <Button type={component === "Stair" ? "primary" : "default"} onClick={handleStairAndConstantClick}>
                          STAIR
                        </Button>
                      </Tooltip>

                      <Tooltip title="Switch to the Heteronyms exercise">
                        <Button type={component === "Heteronym" ? "primary" : "default"} onClick={handleStairAndConstantClick}>
                          HETERONYMS
                        </Button>
                      </Tooltip>
                      <Tooltip title="Switch to the Human Curve exercise">
                        <Dropdown menu={{ items: itemsSyllable }} placement="bottom">
                          <Button type={component === "Fixed" ? "primary" : "default"}>
                            {syllableCount === "none" ? 'HUMAN CURVE' : syllableCount + ' SYLLABLE'}
                          </Button>
                        </Dropdown>

                      </Tooltip>


                    </Space.Compact>

                  </Space>

                </ConfigProvider>

              </div>
              {shortDescription(component)}



              <div style={{ width: "100%" }}>
                {renderSelectedComponent()}
              </div>


              <Flex vertical justify="center" wrap="wrap" gap="small" ref={ref2} >
                {renderSelectedComponentLyric()}
              </Flex>
              {/* <Tooltip title="Settings"> */}
              <FloatButton style={{
                width: 60,
                height: 60,
                right: 15,
                bottom: 30,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
                onClick={() => setOpenSetting(true)}
                ref={ref3}
                shape="square"
                description="SETTINGS"
                icon={<SettingOutlined />} />
              {/* </Tooltip> */}

              {/* <Tooltip title="Tour"> */}
              <FloatButton
                style={{
                  width: 60,
                  height: 60,
                  right: 100,
                  bottom: 30,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
                shape="square"
                description="TOUR"
                onClick={handleBeginTour}
                icon={<QuestionOutlined />} />
              {/* </Tooltip>   */}

              <Image
                preview={false}
                src={divide_1}
                style={{ width: '100%' }}
              />
              <Typography
                style={{
                  width: "100%", paddingLeft: "30px", paddingRight: "40px",
                }}>
                <Typography.Title id="part-1" level={2} style={{ margin: 0 }}>
                  How to use
                </Typography.Title>
                <Collapse
                  activeKey={selectedNum}
                  // accordion
                  onChange={onChangeCollapse}
                  // defaultActiveKey={selectedNum} 
                  ghost
                  items={items_3} />
              </Typography>
            </Flex>


            <Drawer title="Options" placement="right" onClose={() => setOpenSetting(false)} open={openSetting} width={430}>
              <Flex vertical gap="middle">

                <Space>
                  <p># Model Selection: </p>

                  <Dropdown.Button
                    menu={{ items: itemsAvatar }}
                    placement="bottom"
                    trigger={["click"]}
                    overlayClassName='custom-dropdown'
                    buttonsRender={([leftButton, rightButton]) => [
                      <Tooltip>
                        <Button
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                          }}
                          onClick={handleIconClick}
                          icon={
                            <PlayIcon
                              style={{
                                fontSize: '1.7em',
                              }}

                              type="icon-audioPlay"
                            />
                          }
                        >
                          {genderName === "none" ? "Model Voice" : gender === "Female" ? genderName : 'Male01'}

                        </Button>

                      </Tooltip>

                      ,

                      React.cloneElement(rightButton as React.ReactElement<any, string>, { loading: false }),

                    ]}
                  >

                  </Dropdown.Button>

                </Space>
                <AudioPlayer    
        autoPlayAfterSrcChange={false}
        src={audioSrc} 
        ref={audioPlayerNew}
        key={audioKey}
        style={{display: 'none'}}
        onEnded={() => console.log('audio ended')}
        onError={(e) => console.log('audio error', e)}
        />
                <Space>
                  <p># Coordinate Display Mode</p>
                  <Radio.Group onChange={onChangeHz} value={showNotesPar ? 'notes' : 'hz'} defaultValue="notes" buttonStyle="solid">
                    <Radio.Button value="hz"> Hz </Radio.Button>
                    <Radio.Button value="notes">Notes</Radio.Button>
                  </Radio.Group>
                  <br />
                </Space>

                <Space>
                  <p># Pitch Display Color Scheme </p>
                  <Radio.Group onChange={onChangeColor} value={(COLORSMode === 'co') ? 'co' : 'de'} defaultValue="default" buttonStyle="solid">
                    <Radio.Button value="de">TruVox </Radio.Button>
                    <Radio.Button value="co">Colorblind</Radio.Button>
                  </Radio.Group>
                  <br />
                </Space>

                <div>
                  <p># Pitch Display Range</p>
                  <Slider
                    range
                    step={10}
                    min={50}
                    max={600}
                    onChange={onChangeRange}
                    tooltip={{
                      formatter: formatterHz,
                    }}
                    marks={marksRange}
                    defaultValue={[100, 300]} />
                </div>

                {component !== "Fixed" && component !== "Stair" && component !== "Heteronym" && (
                  <div>
                    <p># Pitch Indicator Horizontal Position</p>
                    <Slider
                      step={0.1}
                      min={0}
                      max={1}
                      onChange={onChangePosition}
                      tooltip={{
                        formatter: formatterPos,
                      }}
                      defaultValue={0.5}
                      marks={marksBallPos}
                    />
                  </div>
                )}

                <div>
                  <p># Pitch Indicator Speed</p>
                  <Slider
                    step={0.05}
                    min={0}
                    max={1}
                    onChange={onChangeSpeed}
                    defaultValue={0.3}
                    tooltip={{ formatter: null }}
                  />
                </div>

                <div>
                  <Space>
                    <p># Auto-Start When Voice Detected</p>
                    <Tooltip title="If enabled, an exercise will start if your microphone detects speech. If disabled, you must start exercises manually.">
                      <Switch defaultChecked={false} onChange={EnableVoluneMode} />

                    </Tooltip>

                  </Space>

                  {enableVol &&
                    <Slider
                      step={1}
                      min={50}
                      max={90}
                      onChange={onChangeThreshold}
                      tooltip={{
                        formatter: formatterdB,
                      }}
                      defaultValue={70}
                      marks={marksVol}
                    />
                  }
                </div>

                <br />
                <Button onClick={onClose}>Close</Button>
              </Flex>
            </Drawer>
            <Tour open={openTour} onClose={() => setOpenTour(false)} steps={steps} />
          </Content>
        </Layout>

      </Col>
    </Row>

    // </div>
  );
}

export default Sample;