// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.VerticalSlider {
    margin-left: 0 !important;
    .ant-slider-rail  {
    width: 10px !important;
}
.ant-slider-track  {
    width: 10px !important;
}

.ant-slider-step  {
    width: 10px !important;
}

.ant-slider-vertical   {
    width: 10px !important;
}
.ant-slider-handle {
    left: 3.7px;
}
}
`, "",{"version":3,"sources":["webpack://./src/CSS/VerticalSlider.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB;IACA,sBAAsB;AAC1B;AACA;IACI,sBAAsB;AAC1B;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,sBAAsB;AAC1B;AACA;IACI,WAAW;AACf;AACA","sourcesContent":[".VerticalSlider {\n    margin-left: 0 !important;\n    .ant-slider-rail  {\n    width: 10px !important;\n}\n.ant-slider-track  {\n    width: 10px !important;\n}\n\n.ant-slider-step  {\n    width: 10px !important;\n}\n\n.ant-slider-vertical   {\n    width: 10px !important;\n}\n.ant-slider-handle {\n    left: 3.7px;\n}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
