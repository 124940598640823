import { getMean } from './spectroHelpers.js';

const ENABLE_MEAN_MAG = false;
const color = "#50D3D6";


const waveDrawLoop = (ctx, count, mags, peaks, strokeStyle = "#50D3D6") => {
    const context = ctx; 
    const WIDTH = ctx.canvas.width;
    const HEIGHT = ctx.canvas.height;

    // console.log('DRAWING')

 
    const magnitudes = mags;
    // console.log(magnitudes)
    //const peaks = peaks
    const numMags = magnitudes.length;
    const numPeaks = peaks.length;

    const canvas_width = WIDTH; //canvas.width; // pixels.
    const canvas_height = HEIGHT; //canvas.height; // pixels.

    const lineWidth = canvas_width / (numMags * 1.0);

    context.lineWidth = 1; //lineWidth + 1; // add a 1 px extra length to remove white lines inbetween pixels.

    // console.log(mags)

    // ----------------------
    ctx.clearRect(0, 0, WIDTH, HEIGHT);

    // ctx.fillStyle = "rgb(200, 200, 200)";
    // ctx.fillRect(0, 0, WIDTH, HEIGHT);

    context.beginPath();
    // // FOR WAVE CUSTOMIZATION!
    context.strokeStyle = strokeStyle; //color;
    // if (wavePaused) {
    //     context.strokeStyle = "#D3D3D3";
    // }

    const meanMag = getMean(magnitudes);
    let adjustedMag;

    // for (let i = 0; i < numMags; i++) {
    //     let magnitude = magnitudes[i];
    //     adjustedMag = ENABLE_MEAN_MAG ? 0.5 + magnitude - meanMag : magnitude;
    //     let xPos = Math.floor(i * lineWidth);
    //     let yPos = canvas_height;

    //     context.moveTo(xPos, yPos);
    //     context.lineTo(xPos, canvas_height * (1 - adjustedMag));
    // }

    for (let i = 0; i < numMags; i++) {
        let magnitude = magnitudes[i];

        adjustedMag = ENABLE_MEAN_MAG ? 0.5 + magnitude - meanMag : magnitude;

        // console.log( canvas_height * (1 - adjustedMag))
        
        let xPos = Math.floor(i * lineWidth);
        let yPos = canvas_height;

        context.moveTo(xPos, yPos);
        // context.lineTo(xPos, (canvas_height * (1 - adjustedMag)/4));
        context.lineTo(xPos, canvas_height * (1 - adjustedMag ));
    }

    context.stroke();

    context.beginPath();

    context.lineWidth = 1;
    context.strokeStyle = "white";
    for (let i = 0; i < numPeaks; i++) {
        let peakPosition = peaks[i];
        let xPos = peakPosition * lineWidth;
        let yPos = canvas_height;
        let magnitude = magnitudes[peakPosition];
        adjustedMag = ENABLE_MEAN_MAG ? 0.5 + magnitude - meanMag : magnitude;
        context.moveTo(xPos, yPos);
        context.lineTo(xPos, canvas_height * (1 - adjustedMag));
    }
    context.stroke();

}

export default waveDrawLoop

/* */