import fxauto from "./fxauto.js";
import React, { useContext, useEffect, useRef, useState } from "react";
export interface IPitchDetectionConfig {
  fxmin: number;
  fxlow: number;
  fxhigh: number;
  fxmax: number;
  SRATE: number;
}

export async function getPitch(
  config: IPitchDetectionConfig,
  onPitchDetected: (pitch: number | null) => void
): Promise<() => void> {
  try {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    const audioContext = new window.AudioContext();
    const source = audioContext.createMediaStreamSource(stream);
    const fxCalc = new fxauto(
      config.fxmin,
      config.fxlow,
      config.fxhigh,
      config.fxmax,
      config.SRATE
    );
    const captureNode = audioContext.createScriptProcessor(2048, 1, 1);

    let pitchValues: number[] = [];
    let startTime = Date.now();

    captureNode.onaudioprocess = (e: AudioProcessingEvent) => {
      const buf = e.inputBuffer.getChannelData(0);
      const fxest = fxCalc.CalculateFx(buf, buf.length);

      if (fxest.en > 0) {
        if (fxest.fx < 1) {
          const randomtem = Math.random();
          onPitchDetected(randomtem);
        } else {
          onPitchDetected(fxest.fx);
        }
      }
    };

    source.connect(captureNode);
    captureNode.connect(audioContext.destination);

    // Return cleaning function
    return () => {
      captureNode.disconnect();
      source.disconnect();
      audioContext.close();
    };
  } catch (err) {
    onPitchDetected(null);
    return () => {};
  }
}
