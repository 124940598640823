import { useState, useEffect } from "react";

const useUserMedia = () => {

  const [stream, setStream] = useState(null);
  const [error, setError] = useState(null);

  const getMedia = async ( constraintName ) => {

    let constraints = {
      video: (constraintName === "video") ? true : false,
      audio: {
        echoCancellation: false,
        noiseSuppression: false,
        autoGainControl: false,
      }
    }
    
    console.log('getting stream now')
      try {
          setStream(await navigator.mediaDevices.getUserMedia(constraints));
      } catch (e) {
          setError(e);
      }
  };

  const cancelMedia = () => {
      if(!stream) return;

      console.log('streaming media is stopped')

      if (stream?.getVideoTracks) {
          stream.getVideoTracks().map(track => track.stop());
      }
      if (stream?.getAudioTracks) {
          stream.getAudioTracks().map(track => track.stop());
      }
      if (stream?.stop) {
          stream.stop();
      }
  };
  
  // INTI ----------------------------
  useEffect(() => {
    if (stream) return;

    // onMount   enable this if you want it to run immediately
    // getMedia();
      
    // willUnmount (aka cleanup)
    return cancelMedia
  
  }, [stream, error]);

  return { stream, error, getMedia, cancelMedia, };
};

export default useUserMedia 