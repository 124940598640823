import React, { createContext, useState, useEffect, useContext } from 'react';

const FreqContext = createContext();

export const useFreq = () => useContext(FreqContext);

const defaultFreqData = {
    caliBaseline: {
        '/i/': '', '/ɪ/': '', '/ɛ/': '', '/æ/': '', '/ɑ/': '', '/ɔ/': '', '/ʌ/': '', '/u/': '', '/ʊ/': ''
    },
    customFrequencies: {
        '/i/': '', '/ɪ/': '', '/ɛ/': '', '/æ/': '', '/ɑ/': '', '/ɔ/': '', '/ʌ/': '', '/u/': '', '/ʊ/': ''
    }
};


export const FreqProvider = ({ children }) => {
    const [freqData, setFreqData] = useState(defaultFreqData);
    const channel = new BroadcastChannel('freq_data_channel');

    useEffect(() => {
        const loadedCaliBaseline = localStorage.getItem('caliBaseline');
        const loadedCustomFrequencies = localStorage.getItem('customFrequencies');

        setFreqData({
            caliBaseline: loadedCaliBaseline ? JSON.parse(loadedCaliBaseline) : defaultFreqData.caliBaseline,
            customFrequencies: loadedCustomFrequencies ? JSON.parse(loadedCustomFrequencies) : defaultFreqData.customFrequencies,
        });
    }, []);

    useEffect(() => {
        channel.onmessage = (event) => {
            // console.log('Context received updated data:', event.data);
            setFreqData(event.data);
        };
    
        return () => {
            channel.close();
        };
    }, [channel]);

    const updateFreqData = (updates) => {
        setFreqData(prevState => {
            const newState = { ...prevState };
    
            Object.entries(updates).forEach(([type, value]) => {
                newState[type] = {
                    ...prevState[type],
                    ...value
                };
                localStorage.setItem(type, JSON.stringify(newState[type]));
            });
    
            try {
                channel.postMessage(newState);
            } catch (error) {
                console.error('Failed to broadcast updated data:', error);
            }
    
            return newState;
        });
    };

    return (
        <FreqContext.Provider value={{ freqData, updateFreqData }}>
            {children}
        </FreqContext.Provider>
    );
};