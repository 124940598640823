import * as jsonData from './data/refFreq.json';

interface F2Formant {
    [key: string]: number;
    New_England: number;
    Mid_Atlantic: number;
    North: number;
    Midland: number;
    South: number;
    West: number;
}

const shuffleArray = <T>(array: T[]): T[] => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
};

const getResFreq = async (
    selectedVowels: string[], 
    gender: 'man' | 'woman', 
    // region: string,
    shuffleOrder: boolean
): Promise<{ [word: string]: number | undefined }> => {
    try {
        const frequencies: { [word: string]: number | undefined } = {};

        const vowelsToCheck = shuffleOrder ? shuffleArray(selectedVowels) : selectedVowels;

        for (const word of vowelsToCheck) {
            const wordEntry = jsonData[gender].find((entry: any) => entry.Word === word);

            // if (wordEntry && 'f2Formant' in wordEntry && region in wordEntry.f2Formant) {
            if (wordEntry && 'f2Formant' in wordEntry) {
                const f2Formant: F2Formant = wordEntry.f2Formant;
                frequencies[word] = f2Formant['default'] || undefined;
            } else {
                frequencies[word] = undefined;
            }
        }
        return frequencies;
    } catch (error) {
        console.error('Error fetching or parsing JSON file:', error);
        return {};
    }
};

export default getResFreq;