// Manually created for GAVT project on 2024-06-03, based on commit 10df1d9 of the original project
import { useRef, useEffect } from 'react'
import useUserMedia from './useUserMedia'

import { initAudio, updateCoeffsOffline, updateData, FRAME_SIZE } from './waveAudio'
import './Wave.css'

// =====================================================
const WaveCanvas = props => {  
  const {draw, // this is the drawLoop sketch
    isPaused, // req'd for renderer
    isTracking,
    strokeStyle = "#50D3D6",
    ...rest} = props;

  // Audio Vars ------
  const SAMPLE_RATE = 44100;
  const refAudioCtx = useRef(null);       
  const analyser = useRef();
  let microphone = useRef(null);
  let audioData = [];                   // Holds the time domain data.
  let lastReceivedCoeffs = [];   // self-explanatory
  const BROADCASTS_PER_SECOND = 6; // used in audioData Interval, affects drawing smoothness, and will eventually be used with Vonage

  // Canvas Vars -------
  const refCanvas = useRef();   // html holds canvasEle
  const refCtx = useRef();      // holds canvas drawing ctx
  const refAniId = useRef();    // holds window.requestAnimationFrame
  let count = 0                 // just a var to count the draw loops
  let drawData = { magnitudes: [], peaks: [], } // set each audio interval

  // Services ---------
  const { stream, error, getMedia } = useUserMedia();

  // ------------------------------------------------
  const renderer = () => {
    let ctx = refCtx.current;
    count++
    drawData = updateData(drawData, lastReceivedCoeffs, isTracking)

    if(!isPaused) { // drawLoop. Drawing is passed in thru props.
      draw(ctx, count, drawData.magnitudes, drawData.peaks, strokeStyle) // FOR WAVE CUSTOMIZATION!
    }
    refAniId.current = window.requestAnimationFrame(renderer, isPaused, isTracking)
  }

  // onMount & onWillUnmount ------------------------------------------------
  useEffect(() => { 
    refCtx.current = refCanvas.current.getContext('2d');
    let interval;

    if(!stream) { getMedia(); }

    if(stream) {
      console.log('canvas has stream')
      console.log('stream.active = ' + stream.active)

      // Anything that attaches to a DOM ele should be CREATED here with a ref.
      // Once the refs are init'd/created, they can be passed to external scripts for maipulation.
      refAudioCtx.current = new AudioContext({sampleRate: SAMPLE_RATE,});
      analyser.current = refAudioCtx.current.createAnalyser();

      // I assume this will be needed later...but I don't really know
      microphone.current = refAudioCtx.current.createMediaStreamSource(stream);

      // configs the analyser to our mic input
      analyser.current = initAudio(microphone.current, refAudioCtx.current, analyser.current)

      // ready to send to audioData to LPC script, which we will do at an interval
      interval = setInterval(() => {
        // console.log('audioData interval');
        // creates a 1D array
        audioData = new Float32Array(analyser.current.fftSize);
        // updates that array with analyser data
        analyser.current.getFloatTimeDomainData(audioData);

        // call to audio script
        lastReceivedCoeffs = updateCoeffsOffline(audioData);
      }, Math.round(1000 * (FRAME_SIZE / SAMPLE_RATE)));
      console.log(Math.round(1000 * (FRAME_SIZE/SAMPLE_RATE)));


      console.log('audioReady. Starting render')
      renderer()
    }

    // onUnmount -----------------
    return () => {
      clearInterval(interval);
      console.log('cancel render / cancelAnimationFrame')
      window.cancelAnimationFrame(refAniId.current)
    } 
  }, [stream, isPaused, isTracking])


  // _________________________________________
  return (
      <canvas className="fullWidth" ref={refCanvas} {...rest}>
          Please use an HTML5 browser.
      </canvas>
  )
}

export default WaveCanvas
