import {useState} from "react";

import createTutorialData from "./data/tutorialData";

import './css/_waveMinis.scss'
import './css/_waveGraph.scss'
import './css/_globals.scss'
import './css/_appLayout.scss'

const currentStep = '7'; //p3s1

const colors = {
  waveBlue: '#50d3d6',
  red: '#FF0000',
  gold500: '#ffd477',
  gold600:'#ffc861',
  navy: '#408ba2'
};

const TutorialGraphDisplay = ()=>{
  const [tutData] = useState(createTutorialData());
  const [currentWaveIndex, setCurrentWaveIndex] = useState(0);

  const waveBox = tutData[currentStep]?.waveBox;

  const handleSwitch = () => {
    setCurrentWaveIndex((prevIndex) => (prevIndex === 0 ? 1 : 0));
  };

  const renderWaveGraphics = () => {
    const graphic = waveBox?.waveGraphics && waveBox.waveGraphics.length > 0 
    ? waveBox.waveGraphics[currentWaveIndex] || waveBox.waveGraphics[0] 
    : null;

    return (
      graphic && (
      <div
        className={`waveOverlayBox waveOverlay${currentWaveIndex}`}
        style={{ marginLeft: '200px' }}
        // style={{ position: 'relative', left: '350px', top: '20px' }} 
      >
        <svg id={`svgWave${currentWaveIndex}`} className="svgWave" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 768 324">
          
          <g id={`bgPeaks${currentWaveIndex}`} className="bgPeakGroup">
            {graphic.bgPeaks?.polygons?.map((polygon, idx) => (
              <polygon
                key={idx}
                className={`fill-${graphic.bgPeaks.polygonClass}`}
                points={polygon.points}
                //style={{ fill: colors[graphic.bgPeaks.polygonClass] }}
              />
            ))}
          </g>

          <g id={`diagram${currentWaveIndex}`} className="diagramGroup">
            {graphic.diagram?.polygons?.map((polygon, idx) => (
              <polygon
                key={idx}
                className={polygon.class}
                points={polygon.points}
                // style={{ fill: colors[graphic.bgPeaks.polygonClass] }}
              />
            ))}

            {graphic.diagram?.bubs?.map((bub, idx) => (
              <g key={idx}>
                <circle 
                  cx={bub.circle.cx}
                  cy={bub.circle.cy}
                  r={bub.circle.r}
                  className={`diagramCircles ${bub.circle.style}`}
                />
                <text
                  className={`diagramText fill-${bub.class}`}
                  transform={bub.transform}
                  // textAnchor="middle"
                  // dy=".3em"
                >
                  {bub.text}
                </text>
              </g>
            ))}

            {graphic.diagram?.text?.map((textItem, idx) => (
              <text
                key={idx}
                transform={textItem.transform}
                className={textItem.class}
              >
                {textItem.text}
              </text>
            ))}

            {graphic.diagram?.rects?.map((rect, idx) => (
              <rect
                key={idx}
                x={rect[0]}
                y={rect[1]}
                width={rect[2]}
                height={rect[3]}
                className={rect[4]}
              />
            ))}

            {graphic.diagram?.lines?.map((line, idx) => (
              <line
                key={idx}
                x1={line[0]}
                y1={line[1]}
                x2={line[2]}
                y2={line[3]}
                className={line[4]}
              />
            ))}
        </g>

          {graphic.model?.waveModel && 
            <path 
              id={`wavePath${currentWaveIndex}`}
              // className={`waveModel ${graphic.style === "filled" ? `fill-${graphic.model.color}`: ""}`}
              d={graphic.style === "filled" ? graphic.model.waveModel : ''}
              style={{ fill: '#67016b' }}
            />}
          
          <g id={`lines${currentWaveIndex}`} className="lineGroup">
            {graphic.model?.lines?.map((line, idx) => (
              <line
                  key={idx}
                  x1={line[0]}
                  y1={line[1]}
                  x2={line[2]}
                  y2={line[3]}
                  className={`waveLine stroke-${graphic.model.lineColor}`}
                />
              ))}
          </g>

          {graphic.top && graphic.model?.topPath && (
            <path 
              id={`topLine${currentWaveIndex}`}
              className={`topLine stroke-${graphic.model.lineColor}`}
              d={graphic.model.topPath}
              style={{
                stroke: colors[graphic.model.lineColor],
                fill: "none",
              }}
            />
          )}
        </svg>
      </div>
    ));
  };

  const renderWaveMini= () => {

    const waveMini = tutData[currentStep]?.waveMini;

    return(
      <div id="waveMiniBox">
      {waveMini?.map((item, index) => {
        const isActive = 
          (item.initPos === 'top' && currentWaveIndex === 0) || 
          (item.initPos === 'bottom' && currentWaveIndex === 1);
        const color = isActive ? '#67016b' : '#ccc'; // Turn to grey when is not active
      
      return (
        <div key={index} id={`waveMini_${item.initPos}Slot`} className="miniSlot" style={{ marginBottom: '50px' }} >
          <div id={`wm_btnBtn_${item.initPos}`} className="wm_imgBtn">
            <div id={`wm_svgBox_${item.initPos}`} className="wm_svgBox">
              <div 
                id={`wm_waveBox_${item.initPos}`} 
                className="wm_waveBox" 
                style={item.initPos === 'mid' ? { display: 'none' } : {}}
              >
                {
                  <svg id={`wm_svg_${item.initPos}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 212 40">

                    <path 
                      id={`waveModel_${item.initPos}`}
                      className="waveModel"
                      d={item.initPos === 'mid' ? "m0,40V9.85c70.67.05,141.33.1,212,.15v30H0Z" : item.btnContent.waveModel}
                      style={{ fill: color }}
                    />
                    
                    <g id={`lines_${item.initPos}`}>
                      {item.type === 'waveBtn' && item.btnContent.lines.map((line, lineIndex) => (
                        <line 
                          key={lineIndex}
                          x1={line[0]}
                          y1={line[1]}
                          x2={line[2]}
                          y2={line[3]}
                          style={{ stroke: 'white'}}
                        />
                      ))}
                    </g>
                  </svg>
                }
              </div>

              {item.type === 'switch' && (
                <button id="wm_swapBox" onClick={handleSwitch}>
                  <svg id="swapBtn" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 212 40">
                    <rect 
                      className="bg"
                      x="10" y="2.68"
                      width="190.1"
                      height="34.63"
                      rx="10.14"
                      ry="10.14"
                    />
                    <path 
                      id="topLine"
                      className={`wmColor_1 wm_svg-${item.btnContent.color[0]}`}
                      d="m11.14,20.14v-7.92c0-5.2,4.23-9.44,9.44-9.44h168.87c5.2,0,9.44,4.23,9.44,9.44v7.92h2.03v-7.92c0-6.33-5.13-11.46-11.46-11.46H20.57c-6.33,0-11.46,5.13-11.46,11.46v7.92h2.03Z"
                    />
                    <path 
                      id="bottomEle"
                      className={`wmColor_2 wm_svg-${item.btnContent.color[1]}`}
                      d="m198.88,19.86v7.92c0,5.2-4.23,9.44-9.44,9.44H20.57c-5.2,0-9.44-4.23-9.44-9.44v-7.92l-.07-4.47,8.1,2.93C15.53,14.15,12.1,7.41,10.08,1.99,8.07,7.41,4.64,14.15,1,18.32l8.12-2.94c0,1.49,0,2.99-.01,4.48v7.92c0,6.33,5.13,11.46,11.46,11.46h168.87c6.33,0,11.46-5.13,11.46-11.46v-7.92h-2.03Z"
                    />
                    <path 
                      id="rightArrow" 
                      className={`wmColor_1 wm_svg-${item.btnContent.color[0]}`} 
                      d="m201.12,21.05v-8.56h-2.32v8.56l-9.89-3.58c4.42,5.07,8.6,13.26,11.05,19.86,2.45-6.6,6.62-14.78,11.05-19.86l-9.88,3.58Z"
                    />
                    <text className="swapLabel" transform="translate(63.38 24)"><tspan x="0" y="0">switch waves</tspan></text>
                  </svg>
                </button>
              )}
            </div>
            <div 
              id={`wm_label_${item.initPos}`}
              className="wm_label" 
              style={item.initPos === 'mid' ? { display: 'none' } : {}}  
            >
              {item.type !== "switch" && item.btnContent.text}
            </div>
          </div>
        </div>
      );
      })}
    </div>
    )
  }


  return(
    // <div className="pageBody_parent">
    //   <div className="layoutColumnBase mainColumn">
    <>
        <div className="tut_topContentBox" style={{ bottom: '100px'}}>
          {renderWaveMini()}
        </div>
        <div id="waveBoxParent">
          <div id="waveBox">
            {renderWaveGraphics()}
          </div>
        </div>
      </>
    //   </div>
    // </div>
  )
}

export default TutorialGraphDisplay;