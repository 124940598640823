import React from 'react';
import { useState, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';

import Wave from './Wave/Wave';
import Word from './Word';

import { Divider, Breadcrumb } from 'antd';

const GavtPractice: React.FC = () => {
  const [currentVowelIndex, setCurrentVowelIndex] = useState(0);
  const location = useLocation();

  const { frequencies } = location.state || {};

  const refFreqArray = Object.entries(frequencies || {}).map(([vowel, freq]) => ({
    vowel,
    freq: Number(freq)
  }));

  const handleNextVowel = () => {
    setCurrentVowelIndex((prevIndex) => (prevIndex + 1) % refFreqArray.length);
  };

  return (
    <>
      <div style={{display: 'flex', justifyContent: 'start', width: '70%'}}> 
        <Breadcrumb 
          items={[
            {
              title: <Link to="/resonance/selection">Vowels Selection</Link>,
            },
            {
              title:'Practice',
            }
          ]}
        />
      </div>
      <Word 
        refFreqArray={refFreqArray}
        currentVowelIdx={currentVowelIndex}
        onNextVowel={handleNextVowel}
      />
      <Divider />
      <Wave
        currentVowelIdx={currentVowelIndex}
        refFreqArray={refFreqArray}
      />
    </>
  );
};

export default GavtPractice;