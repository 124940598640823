import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { Divider, Steps, Breadcrumb, Button, message, Table } from 'antd';
import { AudioOutlined, PlayCircleOutlined, PauseCircleOutlined } from '@ant-design/icons';

import { useFreq } from './FreqContext';
import { computeLPC, addToRunningLPC, returnAndClearFormants} from './lib/lpc';

const stepsTitles = [
  'Instruction',
  'Before You Start',
  'Record Your Voice',
  'Complete'
];

const stepsDescriptions = [
    `This module will record your production of different vowel sounds so we can provide resonance targets customized to your voice. 
    If you do not want to complete this procedure right now, or if you experience any technical difficulty, 
    you can use resonance targets derived from a published reference sample of cis women or men (under the “Reference Targets” tab). 
    It should take around five minutes to complete the customization process.`,

    `To measure your resonance for a vowel, we want you to sustain JUST the vowel sound for about two seconds. 
    (We will give you a key word to identify the vowel, but you should produce only the vowel sound, not the whole word.) 
    Try to produce the vowel in a way that feels natural and comfortable to you. We will use our measurements from this production as a baseline for any changes in resonance. 
    Everyone's vowels will be a bit different depending on their dialect or language background. That's OK!
    When you are ready to produce the vowel, click “start” and sustain the vowel for about two seconds, then click “stop.” `,

    `Follow the steps to record your voice. Click button to start, pronounce just the vowel for around 2 seconds, then click to stop. 
    Click play to listen to your recording. If you are satisfied with the recording, continue to record the next vowel.
    If you are not satisfied, you can re-record the vowel. After you have recorded all the vowels, click “Next” to complete the calibration process`,

    `You're done with calibration! Now you can start practicing matching a target resonance.
     You will later be asked what type of target you would like to match, such as slightly darker 
     or moderately brighter. If you are satisfied with the calibration result, click “Save and Back to HOME” 
     to save the calibration result and return to the GAVT home page.`
];

const recordingSteps = [
  { key: '/i/', title: '“ee” like in “seed”' },
  { key: '/ɪ/', title: '“ih” like in “hid”' },
  { key: '/ɛ/', title: '“eh” like in “head”' },
  { key: '/æ/', title: '“ae” like in “sad”  ' },
  { key: '/ɑ/', title: '“ah” like in “god”' },
  { key: '/ɔ/', title: '“aw” like in “lawn”' },
  { key: '/ʌ/', title: '“uh” like in “bud”' },
  { key: '/u/', title: ' “ooh” like in “spoon” ' },
  { key: '/ʊ/', title: ' “oo” like in “good” ' }
];

declare global {
  interface Window {
    webkitAudioContext: typeof AudioContext;
  }
}

interface AudioURLs {
  [key: string]: string;
}

interface AnalysisResults {
    [key: string]: number;
}

const GavtCali: React.FC = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [audioURLs, setAudioURLs] = useState<AudioURLs>({}); // Store the audio URL for each vowel
  const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>(null);
  const [recordingStates, setRecordingStates] = useState<Record<string, boolean>>({});
  const [results, setResults] = useState<AnalysisResults>({});
  const activeRecordingStepKeyRef = useRef<string | null>(null); // Store the key of the active recording step
  const navigate = useNavigate();
  const { freqData, updateFreqData } = useFreq();

  useEffect(() => {
    // ----------------- Recorder -------------------
    async function setupRecorder() {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        const recorder = new MediaRecorder(stream);
        setMediaRecorder(recorder);
  
        let audioChunks: BlobPart[] = [];
  
        recorder.ondataavailable = (event: BlobEvent) => {
          audioChunks.push(event.data);
        };
  
        recorder.onstop = () => {
            const audioBlob = new Blob(audioChunks, { type: 'audio/mp4' });
            const newAudioURL = URL.createObjectURL(audioBlob);
            console.log('Audio Blob:', audioBlob, 'Audio URL:', newAudioURL);
            const key = activeRecordingStepKeyRef.current;
            if (key) {
              setAudioURLs(prev => ({ ...prev, [key]: newAudioURL }));
              console.log(`Recording for ${key} complete!`);
            }
            activeRecordingStepKeyRef.current = null;
            audioChunks = [];
          };
      } catch (error) {
        console.error('Error setting up the media recorder:', error);
      }
    }
  
    setupRecorder();
  
    return () => {
      mediaRecorder?.stream.getTracks().forEach(track => track.stop());
    };
  }, []); 

  const toggleRecording = (key: string) => {
    console.log(`Toggling recording for ${key}...`)
    const isRecording = recordingStates[key];

    if (!isRecording && mediaRecorder) {
      mediaRecorder.start();
      setRecordingStates(prev => ({ ...prev, [key]: true }));
      activeRecordingStepKeyRef.current = key;
    } else if (isRecording && mediaRecorder) {
      mediaRecorder.stop();
      setRecordingStates(prev => ({ ...prev, [key]: false }));
    }
  };

  const handlePlayback = (audioUrl: string) => {
    new Audio(audioUrl).play();
  };

  const renderRecordingControls = (stepKey: string) => {
    const isRecording = recordingStates[stepKey];
    const currentRecording = audioURLs[stepKey];
    const anyRecordingActive = Object.values(recordingStates).some(state => state); // Check if any recording is active

    return (
        <div>
        {currentRecording ? (
          <>
            <Button
                icon={<PlayCircleOutlined />}
                onClick={() => handlePlayback(currentRecording)}
            >
              Play
            </Button>
            <Button
                icon={<AudioOutlined />}
                onClick={() => {
                    console.log(`Deleting recording for ${stepKey}`);
                    setAudioURLs(prev => {
                        const newUrls = { ...prev };
                        delete newUrls[stepKey];
                        return newUrls;
                    });
                    setRecordingStates(prev => ({ ...prev, [stepKey]: false }));
                }}
            >
              Re-record
            </Button>
          </>
        ) : (
          <Button
            icon={isRecording ? <PauseCircleOutlined /> : <AudioOutlined />}
            onClick={() => toggleRecording(stepKey)}
            shape='circle'
            type={isRecording ? "default" : "primary"}
            disabled={anyRecordingActive && !isRecording} // Disable other recording buttons when one is active
          >
          </Button>
        )}
      </div>
    );
  };

  const allRecordingsComplete = recordingSteps.every(step => audioURLs[step.key])

  const handleAnalyzeAll = async () => {
    const newResults: AnalysisResults = {};
    for (const key of Object.keys(audioURLs)) {
      const audioUrl = audioURLs[key];
      const response = await fetch(audioUrl);
      const audioData = await response.arrayBuffer();
      const audioContext = new (window.AudioContext || window.webkitAudioContext)();
      // Full-length audio file
      const audioArray = await audioContext.decodeAudioData(audioData);

      // Average F2 formant frequency for the audio file.
      const formant = calculateF2(audioArray);
      newResults[key] = formant !== null ? formant : 0;
    }
    setResults(newResults);
    console.log('Analysis complete:', newResults);
    localStorage.setItem('latestTestResult', JSON.stringify(newResults));
    setCurrentStep(currentStep + 1);
  };

  const calculateF2 = (audioBuffer: AudioBuffer): number | null => {
    // `audioBuffer` and this type are the full-length audio file, not the chunk.
    const channelData = audioBuffer.getChannelData(0);
    const sampleRate = audioBuffer.sampleRate;

    // Threshold for detecting voiced sound. Set to 0.001 to bypass the threshold check.
    const threshold = 0.001; 

    // For the voice detection, find the start and end timestamps of the voiced sound
    let startTime: number | null = null;
    let endTime: number | null = null;

    for (let i = 0; i < channelData.length; i++) {
      if (Math.abs(channelData[i]) > threshold) {
          if (startTime === null) {
              startTime = i / sampleRate; // Convert sample index to time
          }
          endTime = i / sampleRate; // Convert sample index to time
      }
    }

    if (startTime !== null && endTime !== null) {
      // console.log(`Voiced sound detected from ${startTime} to ${endTime} in ${audioBuffer}`);
      const startSample = Math.floor(startTime * sampleRate);
      const endSample = Math.ceil(endTime * sampleRate);

      // Extract the voiced sound segment
      const soundSegment = channelData.slice(startSample, endSample);

      const chunkSize = 4096;

      // Split the voiced sound segment into chunks and analyze each chunk
      for (let i = 0; i < soundSegment.length; i += chunkSize) {

        const chunk = soundSegment.slice(i, i + chunkSize);

        // Skip the last chunk if it's too short
        if (chunk.length < chunkSize) continue;

        // Compute LPC coefficients for the chunk
        const lpcCoefficients = computeLPC(chunk);

        // Accumulate the LPC coefficients for the voiced sound segment
        addToRunningLPC(lpcCoefficients);
      }
      returnAndClearFormants(true); 
      const f2 = Math.round(returnAndClearFormants(false));
      return f2;
    }

    return null;

  }

  // ----------------- Draw Table -------------------

  const columns = [
    { title: 'Vowel', dataIndex: 'vowel', key: 'vowel' },
    { title: 'Result', dataIndex: 'result', key: 'result' }
  ];

  const dataSource = Object.keys(results).map(key => ({
    key,
    vowel: key,
    result: results[key]
  }));

  // ----------------- Handle Save -------------------
  const handleSaveAndReturn = () => {
    const latestTestResult = JSON.parse(localStorage.getItem('latestTestResult') || '{}');
    console.log(`Latest test result: ${latestTestResult}`);
    if ( latestTestResult ) {
        // localStorage.setItem('caliBaseline', JSON.stringify(latestTestResult));
        const updates = {
          caliBaseline: latestTestResult
        }
        updateFreqData(updates);
        message.success('Calibration baseline updated successfully! Will return to Resonance Home in 3 seconds...');
    }
    setTimeout(()=>navigate('/resonance'), 3000);
    ;
  };


  // ----------------- Step Setting -------------------
  const renderStepContent = () => {
    if (currentStep === 2) {
      return (    
        <div>
            <p>{stepsDescriptions[currentStep]}</p>
            <Steps current={-1} direction="vertical">
            {recordingSteps.map(step => (
              <Steps.Step key={step.key} title={step.title} description={renderRecordingControls(step.key)} />
            ))}
          </Steps>
        </div>
      );
    } else {
      return <p>{stepsDescriptions[currentStep]}</p>;
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '20px', width: '80%' }}>
        <Breadcrumb
            style={{ marginBottom: '20px', alignSelf: 'start' }}
            items={[
                {title: <Link to="/resonance/selection">Resonance Practice</Link>,},
                {title:'Calibration',}
            ]}
        />
        <Steps progressDot current={currentStep} items={stepsTitles.map((title, index) => ({ key: index.toString(), title }))} />

        <Divider />

        {renderStepContent()}
        {currentStep === 3 && (
            <Table dataSource={dataSource} columns={columns} pagination={false}/>
        )}
        <div style={{ marginTop: '20px' }}>
            <Button disabled={currentStep === 0} onClick={() => setCurrentStep(currentStep - 1)}>Previous</Button>
            <Button
                type="primary"
                onClick={() => {
                    if (currentStep === 2) {
                        handleAnalyzeAll();
                    } else if (currentStep === 3) {
                        handleSaveAndReturn();
                    } else {
                        setCurrentStep(currentStep + 1);
                    }}
                }
                disabled={ currentStep === 2 && !allRecordingsComplete }
                style={{ marginLeft: "20px" }}>
                {currentStep === stepsTitles.length - 1 ? 'Save and Back to HOME' : 'Next'}
            </Button>
        </div>
    </div>
  );
};

export default GavtCali;
