import { useState, useRef } from 'react';
import { COLORS } from '../types/configTypes';
import { adjustCanvasScale, map, drawBackground } from '../function/canvasDefault';
import {Dropdown} from 'antd'; 
import AudioPlayer from 'react-h5-audio-player';
import { createFromIconfontCN } from '@ant-design/icons';
import { Button } from 'antd';
import type { MenuProps } from 'antd';


interface CustomButtonProps {
  label: string;
  handleIconClick?: (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void; 
}

const PlayIcon = createFromIconfontCN({
  scriptUrl: '//at.alicdn.com/t/c/font_4566050_zpduwnlv2t.js',
});

export function useOptionsHooks() {
  const [gender, setGender] = useState<string>("Female");
  const [genderName, setGenderName] = useState<string>("Fem01");
  const audioPlayerNew = useRef<AudioPlayer>(null);
  const [jsonFiles, setJsonFiles] = useState<string[]>([]);
  const [audioKey, setAudioKey] = useState<number>(123);
  const [audioSrc, setAudioSrc] = useState<string>('');
  const [jsonFileLength,setJsonFileLength] = useState<number>(2);
  const [playLyricCount,setPlayLyricCount] = useState<number>(1);

  const syllableCount = '5';

  const saveGender = (e: any) => {
    const [genderType, genderNameType] = e.key.split('-');
    setGender(genderType);
    setGenderName(genderNameType);
    console.log(genderType);
    console.log('genderName', genderName)
  }
  
    // audio
    const playAudio = () => {
      if (audioPlayerNew.current && audioPlayerNew.current.audio.current) {
        audioPlayerNew.current.audio.current.play()
        const audio = audioPlayerNew.current.audio.current;
        if (audio.readyState === 4) {
          audio.play()
            .then(() => {
              // setIsListen(isListen + 1);
            })
            .catch((error:Error) => {
              console.error('Fail play audios: ', error);
            });
        } else {
          audio.addEventListener('canplay', () => {
            audio.play()
              .then(() => {
                // setIsListen(isListen + 1);
              })
              .catch((error:Error) => {
                console.error('Fail play audios: ', error);
              });
          });
        }
      }
    };

    const fetchAudioData = () => {
      let url = '';
      if (gender === 'male') {
        url = `https://ceas5.uc.edu/transvoice/jsondata/${syllableCount}/${gender}/list.json`;
      } else {
        url = `https://ceas5.uc.edu/transvoice/jsonDataOm/${gender}/${genderName}/${syllableCount}syllable/list.json`;
      }
  
      fetch(url)
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(data => {
          setJsonFiles(data.json_files);
          setJsonFileLength(data.json_files.length);
          if (data.json_files.length > 0) {
            let  playLyricCountIn = Math.floor(Math.random() * data.json_files.length);
            while (playLyricCountIn === playLyricCount) {
              playLyricCountIn = Math.floor(Math.random() * data.json_files.length);
            }
            setPlayLyricCount(playLyricCountIn);
            let audioFilename = '';
            let audioUrl = '';
            var timestamp = new Date().getTime(); 
            if (gender === 'male') {
              audioFilename = `${gender}-${data.json_files[playLyricCountIn].replace('.json', '')}.wav`;
              audioUrl = `https://ceas5.uc.edu/transvoice/audio/${syllableCount}/${gender}/${audioFilename}?t=${timestamp}`;
            } else {
              audioFilename = `${genderName}-${gender}-${data.json_files[playLyricCountIn].replace('.json', '')}.wav`;
              audioUrl = `https://ceas5.uc.edu/transvoice/audioOm/${gender}/${genderName}/${syllableCount}syllable/${audioFilename}?t=${timestamp}`;
            }
            console.log(audioUrl);
  
            setAudioKey(Date.now());
            setAudioSrc(audioUrl);
            // if (audioPlayerNew.current && audioPlayerNew.current.audio.current) {
            //   audioPlayerNew.current.audio.current.pause();
            // }
          }
        })
        .catch(error => {
          console.error('Error fetching JSON files:', error);
        });
    };


    const handleIconClick = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
      fetchAudioData();
      setTimeout(() => {
        playAudio();
        console.log("play audio!");
      }, 50); 
    };
  
    const handleIconClickDropdown = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
      // setIsClickListen(isClickListen+1);
      setTimeout(() => {
        playAudio();
        console.log("play audio!");
      }, 400); 
    };

    
const CustomButton: React.FC<CustomButtonProps> = ({ label, handleIconClick }) => {
  return (
    <Button
      style={{
        display: 'flex',
        alignItems: 'center',
      }}
      className='dropdownIcon'
      icon={
        <PlayIcon
          style={{
            fontSize: '1.7em',
          }}
          onClick={handleIconClick}
          type="icon-audioPlay"
           />
      }
    >
      {label}
    </Button>
  );
};

    const itemsAvatar: MenuProps['items'] = [
      {
        key: 'Female-Fem01',
        label: (
          <CustomButton
          label="Female-01"
          handleIconClick={handleIconClickDropdown}
          />
        ),
        onClick: saveGender,
      },
      {
        key: 'Female-Fem02',
        label: (
          <CustomButton
          label="Female-02"
          handleIconClick={handleIconClickDropdown}
          />
        ),
        onClick: saveGender,
      },
      {
        key: 'Female-Fem03',
        label: (
          <CustomButton
          label="Female-03"
          handleIconClick={handleIconClickDropdown}
          />
        ),
        onClick: saveGender,
      },
      {
        key: 'Female-Fem04',
        label: (
          <CustomButton
          label="Female-04"
          handleIconClick={handleIconClickDropdown}
          />
        ),
        onClick: saveGender,
      },
      {
        key: 'Female-Fem05',
        label: (
          <CustomButton
          label="Female-05"
          handleIconClick={handleIconClickDropdown}
          />
        ),
        onClick: saveGender,
      },
      {
        key: 'Female-Fem06',
        label: (
          <CustomButton
          label="Female-06"
          handleIconClick={handleIconClickDropdown}
          />
        ),
        onClick: saveGender,
      },
      {
        key: 'Female-Fem07',
        label: (
          <CustomButton
          label="Female-07"
          handleIconClick={handleIconClickDropdown}
          />
        ),
        onClick: saveGender,
      },
      {
        key: 'male',
        label: (
          <CustomButton
          label="Male-01"
          handleIconClick={handleIconClickDropdown}
          />
        ),
        onClick: saveGender,
      },
    ];

    const ModelSelections = () => {
        <>
        {/* <p># Model Selection: </p>
              <Dropdown.Button
                menu={{ items: itemsAvatar }}
                placement="bottom"
                trigger={["click"]}
                overlayClassName='custom-dropdown'
                buttonsRender={([leftButton, rightButton]) => [
                  <Tooltip>
                    <Button
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                      onClick={handleIconClick}
                      icon={
                        <PlayIcon
                          style={{
                            fontSize: '1.7em',
                          }}
                          type="icon-audioPlay"
                           />
                      }
                    >
                      {genderName === "none" ? "Model Voice" : gender === "Female" ? genderName : 'Male01'}
                    </Button>
                  </Tooltip>
                  ,
                  React.cloneElement(rightButton as React.ReactElement<any, string>, { loading: false }),
                ]}
              >
              </Dropdown.Button> */}
        </>
    }
    return {
      gender,
      setGender,
      genderName,
      setGenderName,
      audioPlayerNew,
      jsonFiles,
      setJsonFiles,
      audioKey,
      setAudioKey,
      audioSrc,
      setAudioSrc,
      jsonFileLength,
      setJsonFileLength,
      playLyricCount,
      setPlayLyricCount,

      syllableCount,

playAudio,
fetchAudioData,


      saveGender,


      handleIconClick,
      handleIconClickDropdown,

      itemsAvatar,

    }
}