import React, { useState, useEffect, useRef } from 'react';
import { getPitch, IPitchDetectionConfig } from '../function/getPitch';
import hark from 'hark';
import { useNavigate } from 'react-router-dom';
import type { CollapseProps } from 'antd';
import { Collapse, Button, Anchor } from 'antd';
// import { Col, Row } from 'antd';
import { Divider, Typography, Col, Row, Space } from 'antd';
interface HelpProps {
}
const text = `                                                     
  qqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqq
`;
const { Title, Paragraph, Text, Link } = Typography;
const fontAlign = "justify";
const fontSizePara = 17;

const items_2: CollapseProps['items'] = [
  {
    key: 'part-2-1',
    label: <Typography.Title id="part-2-1" level={4} style={{ margin: 0 }}>How do we describe voice?</Typography.Title>,
    children: (
      <>
        <Paragraph style={{ textAlign: fontAlign, fontSize: fontSizePara }}>
        Voice is often described in three ways: pitch, loudness, and quality. We can work on these three different aspects to change how our voice is perceived.        </Paragraph>
      </>
    ),
  },
  {
    key: 'part-2-2',
    label: <Typography.Title id="part-2-2" level={4} style={{ margin: 0 }}>Why do trans people try to modify their voices?</Typography.Title>,
    children: (
      <>
        <Paragraph style={{ textAlign: fontAlign, fontSize: fontSizePara }}>
        Voice is a gender signifier: listeners partially determine a speaker’s gender based on their voice. Transgender people often want their voice to match their gender expression. This is often referred to as voice-gender congruence.
        </Paragraph>
      </>
    ),
  },
  {
    key: 'part-2-3',
    label: <Typography.Title id="part-2-3" level={4} style={{ margin: 0 }}>Gender-related Voice Training Targets</Typography.Title>,
    children: (
      <>
        <Paragraph style={{ textAlign: fontAlign, fontSize: fontSizePara }}>
        Multiple aspects of voice contribute to gender perception. Research has shown that the primary voice marker that relates to perceived gender is <Text strong italic>pitch</Text> - the perception of how high-frequency or low-frequency the voice is. Higher pitches are stereotypically associated with femininity and women, whereas lower pitches are associated with masculinity and men. A second aspect of voice related to perceived gender is <Text strong italic>resonance</Text> - a characteristic of the quality of the voice. A more forward resonance (when the sound of the voice vibrates the front part of the mouth and face) is perceived as more feminine, whereas a more backward resonance (when the back of the mouth and throat constricts), is perceived as more masculine. The combination of increased pitch and forward resonance results in the strongest perception of a feminine voice (Whiteside, 1998).        
        </Paragraph>
      </>
    ),
  },
  {
    key: 'part-2-4',
    label: <Typography.Title id="part-2-4" level={4} style={{ margin: 0 }}>What does the research say about voice training for transfeminine people?</Typography.Title>,
    children: (
      <>
        <Paragraph style={{ textAlign: fontAlign, fontSize: fontSizePara }}>
        Our previous research has found that transfeminine people who completed voice therapy showed an average increase in vocal pitch by half an octave (Gustin et al., 2022). They also changed their articulation patterns so that the tongue was placed more forward in the mouth to facilitate a forward resonance. Targeting pitch and resonance may assist in increasing vocal femininity.        </Paragraph>
      </>
    ),
  },
  {
    key: 'part-2-5',
    label: <Typography.Title id="part-2-5" level={4} style={{ margin: 0 }}>Resources and Citations</Typography.Title>,
    children: (
      <>
        <Paragraph style={{ textAlign: fontAlign, fontSize: fontSizePara }}>
        [1] Gustin, R.L., Dickinson, T.E., Shanley, S.N., Howell, R.J., Hobek, A.L., Patel, T.H., & McKenna, V.S. (2022). “Acoustic outcomes in Patients seeking Voice Feminization: Investigation into Articulatory and Spectral Measures.” 51st Annual Voice Foundation, Philadelphia, PA. [Oral Presentation].
        </Paragraph>
        <Paragraph style={{ textAlign: fontAlign, fontSize: fontSizePara }}>
        [2] Whiteside, S. P. (1998). The Identification of a Speaker’s Sex from Synthesized Vowels. Perceptual and Motor Skills, 87(2), 595-600. https://doi.org/10.2466/pms.1998.87.2.595        </Paragraph>
      </>
    ),
  },
];

const items_3: CollapseProps['items'] = [ 
  {
    key: 'part-3-1',
    label: <Typography.Title id="part-3-1" level={4} style={{ margin: 0 }}>3.1 Options/Setting</Typography.Title>,
    children: (
      <>
        <Paragraph style={{ textAlign: fontAlign, fontSize: fontSizePara }}>The possible settings are:</Paragraph>
        <Paragraph>
          <ul>
            <li>
              <p><strong>Model selection</strong>: Lets you pick between several prerecorded voices to serve as a target "avatar". These are currently used only in the Syllables exercise.</p>
            </li>
            <li>
              <p><strong>Exercise selection</strong>: Lets you pick between Constant, Stair, and Syllable exercises.</p>
            </li>
            <li>
              <p><strong>Coordinate Display Mode</strong>: changes the pitch display between hertz and musical notes. You can also change this by clicking the vertical scale on the graph itself.</p>
            </li>
            <li>
              <p><strong>Pitch display range</strong>: Changes the scale of the pitch graph. The blue part represents the current scale, and the white balls on the left and right can be dragged with your mouse to change the minimum or maximum of the scale. By default, it shows 100-300 Hz, but the maximum range is 50-600  Hz.</p>
            </li>
            <li>
              <p><strong>Pitch Indicator position (Only appears in the Constant exercise)</strong>: Changes where the "current pitch" Pitch indicator appears in the graph.</p>
            </li>
            <li>
              <p><strong>Pitch Indicator speed</strong>: Changes the speed with which the "current pitch" indicator ball moves across the screen.</p>
            </li>
            <li>
              <p><strong>Auto-Start when Voice Detected</strong>: If this is enabled and the pitch module is paused, it will automatically start tracking pitch when the module detects sound on your microphone. If this is disabled, you must manually start it after pausing it.</p>
            </li>
          </ul>
        </Paragraph>
      </>
    ),
  },
  {
    key: 'part-3-2',
    label: <Typography.Title id="part-3-2" level={4} style={{ margin: 0 }}>3.2 Constant exercise</Typography.Title>,
    children: (
      <>
        <Paragraph style={{ textAlign: fontAlign, fontSize: fontSizePara }}>
          The Constant exercise is the most basic exercise. When you talk, it shows your current pitch with a large black dot and the last few seconds of your pitch with small red dots. If you stop talking, the graph keeps moving but does not show new red dots.
        </Paragraph>
        <Paragraph style={{ textAlign: fontAlign, fontSize: fontSizePara }}>
          On the right side of the graph, you can see a vertical slider. Moving this slider up and down creates a constant pitch target that is shown as a horizontal dark blue line across the screen. You can try to match this target. If your pitch is close enough to the target, the target line changes from blue to yellow. You can change the target as you exercise if you want.
        </Paragraph>
        <Paragraph style={{ textAlign: fontAlign, fontSize: fontSizePara }}>
          If you want something to read while you practice matching a target pitch, you can use the Upload txt file button below the graph to upload a .txt file with text of your choosing. In the .txt file, a semicolon should indicate a new line . While reading, you can then move between lines using the Forward/Backward buttons underneath the text. Other file formats are not currently supported but are planned for future releases. 
        </Paragraph>
      </>
    ),
  },
  {
    key: 'part-3-3',
    label: <Typography.Title id="part-3-3" level={4} style={{ margin: 0 }}>3.3 Staircase exercise</Typography.Title>,
    children: (
      <>
        <Paragraph style={{ textAlign: fontAlign, fontSize: fontSizePara }}>
          When you talk, the Staircase exercise shows your current pitch with a large black dot and the last few seconds of your pitch with small red dots. If you stop talking, the graph keeps moving but does not show new red dots. This is the same as in the Constant exercise.
        </Paragraph>
        <Paragraph style={{ textAlign: fontAlign, fontSize: fontSizePara }}>
          A dark blue line shows a sequence of pitches for you to match within a few seconds. You can change the location of these 'target' pitches using the light blue vertical slider to the right of the graph – drag the bottom white ball up/down to move the lowest target or drag the top white ball to move the highest target.      
        </Paragraph>
        <Paragraph style={{ textAlign: fontAlign, fontSize: fontSizePara }}>
          Pressing the Play button starts moving the current pitch indicator. Press it when you are ready to try matching the pitches. You can pause at any time, and once the indicator gets to the end, you can press Play again to restart. As you speak, the dark blue target pitch curve will change to yellow if your pitch is close to it.      
        </Paragraph>
        <Paragraph style={{ textAlign: fontAlign, fontSize: fontSizePara }}>
          If you would like to have some sounds to make while matching the target pitches, the Show/Hide buttons  below the graph display a 5-syllable phrase. The full phrase is shown below the Show/Hide buttons, and each individual syllable is shown on one of the target pitch lines. The Forward/Backward buttons let you move to the next/previous phrase, and the Retry button resets the current phrase.  You don't have to use this function, it's just if you want to have something to say.       
        </Paragraph>
      </>
    ),
  },
  {
    key: 'part-3-4',
    label: <Typography.Title id="part-3-4" level={4} style={{ margin: 0 }}>3.4 Syllables exercise</Typography.Title>,
    children: (
      <>
        <Paragraph style={{ textAlign: fontAlign, fontSize: fontSizePara }}>
          To use the Syllables exercise, you must first select an avatar in the Options menu. Once you have done that, the Syllables exercise shows pitch curves from that recorded avatar while speaking 2-, 3-, 4-, or 5-syllable phrases in dark blue. As in the other two exercises, the graph shows your current pitch with a large black dot and the last few seconds of your pitch with small red dots. If you stop talking, the graph keeps moving but does not show new red dots.
        </Paragraph>
        <Paragraph style={{ textAlign: fontAlign, fontSize: fontSizePara }}>
          The goal of the exercise is to match the avatar's pitch curve as you say the same phrase. The target phrase is shown below the graph in green. Pressing the Play button starts moving the current pitch indicator. Press it when you are ready to try matching the curve. You can pause at any time, and once the indicator gets to the end, you can press Play again to restart the same phrase. As you speak, the dark blue target pitch curve will change to yellow if your pitch is close to it.
        </Paragraph>
        <Paragraph style={{ textAlign: fontAlign, fontSize: fontSizePara }}>
          Once the phrase finishes, you can press the Retry button to try matching it again, or press Forward/Backward to move to the next or previous phrase. The next two phrases are shown in grey below the current phrase.
        </Paragraph>
        <Paragraph style={{ textAlign: fontAlign, fontSize: fontSizePara }}>
          If you want to hear the phrase spoken by your selected avatar, press the Listen button. The dark blue curve will change to yellow as the phrase is played to indicate the timing.
        </Paragraph>
      </>
    ),
  },
  {
    key: 'part-3-5',
    label: <Typography.Title id="part-3-5" level={4} style={{ margin: 0 }}>3.5 Suggestions for using the pitch module</Typography.Title>,
    children: (
      <>
        <Paragraph style={{ textAlign: fontAlign, fontSize: fontSizePara }}>
          If you have limited ability to control your pitch, we recommend starting with the Constant exercise. Measure your current pitch and set the target pitch (with the slider on the right) to somewhere above your regular pitch. The "stereotypical" value for female pitch is about 200 Hz. Make sustained sounds (for example, "aaaaaaaaaaa") and try to consistently match the target pitch. Once you can consistently match the target pitch with all five vowels (a, e, i, o, u), try speaking longer phrases at the target pitch.
        </Paragraph>
        <Paragraph style={{ textAlign: fontAlign, fontSize: fontSizePara }}>
          When you feel comfortable matching the target pitch in the Constant exercise for short periods of time, add the Staircase exercise to your practice rotation. You can start by hiding the target text and just matching the target pitches with a sustained vowel sound (for example, "aaaaa" moving up and down in pitch). Once you can do that with all five vowel sounds, turn the target text on and try speaking the phrases. Once you can do that, you may also try the Syllables exercise, though you do not necessarily need to do the Syllables exercise – the Constant and Staircase will give you the fundamentals.
        </Paragraph>
        <Paragraph style={{ textAlign: fontAlign, fontSize: fontSizePara }}>
          If this is all of your voice practice, do it for 10-15 minutes two times a day. It will help you learn pitch control, though you should keep in mind that pitch is not the only component of transgender voice. You will want to move on to other components of voice as well.
        </Paragraph>
      </>
    ),
  },
];
const Help: React.FC<HelpProps> = () => {
  const navigate = useNavigate();
  const goToSample = () => {
    navigate('/pitch?init=true');
  };

  return (
    <div style={{ width: "100%" }}>
      <Row>
        <Col span={3}>
          <Anchor
            items={[
              {
                key: 'part-1',
                href: '#part-1',
                title: 'Introduction',
              },
              {
                key: 'part-2',
                href: '#part-2',
                title: 'How is voice related to gender?',
                children: [
                  {
                    key: 'part-2-1',
                    href: '#part-2-1',
                    title: 'How do we describe voice?',
                  },
                  {
                    key: 'part-2-2',
                    href: '#part-2-2',
                    title: 'Why do trans people try to modify their voices?',
                  },
                  {
                    key: 'part-2-3',
                    href: '#part-2-3',
                    title: 'Gender-related Voice Training Targets',
                  },
                  {
                    key: 'part-2-4',
                    href: '#part-2-4',
                    title: 'What does the research say about voice training for transfeminine people?',
                  },
                  {
                    key: 'part-2-5',
                    href: '#part-2-5',
                    title: 'Resources and Citations',
                  },
                ],
              },
              {
                key: 'part-3',
                href: '#part-3',
                title: 'Pitch module',
                children: [
                  {
                    key: 'part-3-1',
                    href: '#part-3-1',
                    title: '3.1 Options/Setting',
                  },
                  {
                    key: 'part-3-2',
                    href: '#part-3-2',
                    title: '3.2 Constant exercise',
                  },
                  {
                    key: 'part-3-3',
                    href: '#part-3-3',
                    title: '3.3 Staircase exercise',
                  },
                  {
                    key: 'part-3-4',
                    href: '#part-3-4',
                    title: '3.4 Syllables exercise',
                  },
                  {
                    key: 'part-3-5',
                    href: '#part-3-5',
                    title: '3.5 Suggestions',
                  },
                ],
              },
              {
                key: 'part-4',
                href: '#part-4',
                title: 'Volume module ',
              },
            ]}
          />
        </Col>
        <Col span={20}>
          <Typography style={{ paddingLeft: "30px", paddingRight: "40px" }}>
            <Typography.Title id="part-1" level={2} style={{ margin: 0 }}>
              1. Introduction
            </Typography.Title>
            <br />
            <Paragraph style={{ textAlign: fontAlign, fontSize: fontSizePara }}>
              Welcome to the University of Cincinnati Transgender Voice Coaching app, which aims to help transfeminine individuals practice changing their voice in different ways. In its current format, the app mainly focuses on pitch exercises, but is being rapidly expanded.

            </Paragraph>

            <Paragraph style={{ textAlign: fontAlign, fontSize: fontSizePara }}>

              You are currently on the Tutorial page. You can navigate through this tutorial below, or you can navigate to other sections of the page using the Pitch, Volume , and About tabs above.
            </Paragraph >
            <Button onClick={goToSample}>Begin Tour</Button>
            <br />
            <br />
            <br />

            <Typography.Title id="part-2" level={2} style={{ margin: 0 }}>
              2. How is voice related to gender?
            </Typography.Title>

            <Collapse defaultActiveKey={['2']} ghost items={items_2} />
            <br />

            {/* <Collapse defaultActiveKey={['1']} ghost items={itemsPitchMod} /> */}
            <Typography.Title level={2} id="part-3" style={{ margin: 0 }}>
              3. Pitch module
            </Typography.Title>
            <br />
            <Paragraph style={{ textAlign: fontAlign, fontSize: fontSizePara }}>
              The pitch module is currently the main component of the app. In the center of the page is a graph that shows your voice pitch over time as you talk into your microphone. The graph can be started and stopped using the Play/Pause button below the graph.


            </Paragraph>
            <Paragraph style={{ textAlign: fontAlign, fontSize: fontSizePara }}>
              By default, the module starts in the Constant exercise.  The Options/Setting menu can be accessed using the button below the Play/Pause button or using the gear icon on the bottom right. The menu allows you to choose between three exercises (Constant, Stair, and Syllables) as well as change several settings.

            </Paragraph>
            
            <Collapse defaultActiveKey={['2']} ghost items={items_3} />
            {/* <Typography.Title id="part-2-1" level={4} style={{ margin: 0 }}>
              2.1 What is pitch and how is it related to gender?
            </Typography.Title>
            <Paragraph style={{ textAlign: fontAlign, fontSize: fontSizePara }}>  TBD</Paragraph>

            <Typography.Title id="part-2-2" level={4} style={{ margin: 0 }}>
              2.2 Options/Setting
            </Typography.Title>
            <Paragraph style={{ textAlign: fontAlign, fontSize: fontSizePara }}>The possible settings are:</Paragraph>
            <Paragraph>
              <ul>
                <li>
                  <p><strong>Model selection</strong>: Lets you pick between several prerecorded voices to serve as a target “avatar”. These are currently used only in the Syllables exercise.</p>
                </li>
                <li>
                  <p><strong>Exercise selection</strong>: Lets you pick between Constant, Stair, and Syllable exercises.</p>
                </li>
                <li>
                  <p><strong>Coordinate Display Mode</strong>: changes the pitch display between hertz and musical notes. You can also change this by clicking the vertical scale on the graph itself.</p>
                </li>
                <li>
                  <p><strong>Pitch display range</strong>: Changes the scale of the pitch graph. The blue part represents the current scale, and the white balls on the left and right can be dragged with your mouse to change the minimum or maximum of the scale. By default, it shows 100-300 Hz, but the maximum range is 50-600  Hz.</p>
                </li>
                <li>
                  <p><strong>Pitch Indicator position (Only appears in the Constant exercise)</strong> : Changes where the “current pitch” Pitch indicator appears in the graph.</p>
                </li>
                <li>
                  <p><strong>Pitch Indicator speed</strong>: Changes the speed with which the “current pitch” indicator ball moves across the screen.</p>
                </li>
                <li>
                  <p><strong>Auto-Start when Voice Detected</strong>: If this is enabled and the pitch module is paused, it will automatically start tracking pitch when the module detects sound on your microphone. If this is disabled, you must manually start it after pausing it.</p>
                </li>
              </ul>

            </Paragraph>

            <Typography.Title id="part-2-3" level={4} style={{ margin: 0 }}>
              2.3 Constant exercise
            </Typography.Title>
            <Paragraph style={{ textAlign: fontAlign, fontSize: fontSizePara }}>
              The Constant exercise is the most basic exercise. When you talk, it shows your current pitch with a large black dot and the last few seconds of your pitch with small red dots. If you stop talking, the graph keeps moving but does not show new red dots.
            </Paragraph>
            <Paragraph style={{ textAlign: fontAlign, fontSize: fontSizePara }}>
              On the right side of the graph, you can see a vertical slider. Moving this slider up and down creates a constant pitch target that is shown as a horizontal dark blue line across the screen. You can try to match this target. If your pitch is close enough to the target, the target line changes from blue to yellow. You can change the target as you exercise if you want.
            </Paragraph>
            <Paragraph style={{ textAlign: fontAlign, fontSize: fontSizePara }}>
              If you want something to read while you practice matching a target pitch, you can use the Upload txt file button below the graph to upload a .txt file with text of your choosing. In the .txt file, a semicolon should indicate a new line . While reading, you can then move between lines using the Forward/Backward buttons underneath the text. Other file formats are not currently supported but are planned for future releases.
            </Paragraph>

            <Typography.Title id="part-2-4" level={4} style={{ margin: 0 }}>
              2.4 Staircase exercise
            </Typography.Title>
            <Paragraph style={{ textAlign: fontAlign, fontSize: fontSizePara }}>
              When you talk, the Staircase exercise shows your current pitch with a large black dot and the last few seconds of your pitch with small red dots. If you stop talking, the graph keeps moving but does not show new red dots. This is the same as in the Constant exercise.
            </Paragraph>
            <Paragraph style={{ textAlign: fontAlign, fontSize: fontSizePara }}>
              A dark blue line shows a sequence of pitches for you to match within a few seconds. You can change the location of these ‘target’ pitches using the light blue vertical slider to the right of the graph – drag the bottom white ball up/down to move the lowest target or drag the top white ball to move the highest target.
            </Paragraph>
            <Paragraph style={{ textAlign: fontAlign, fontSize: fontSizePara }}>
              Pressing the Play button starts moving the current pitch indicator. Press it when you are ready to try matching the pitches. You can pause at any time, and once the indicator gets to the end, you can press Play again to restart. As you speak, the dark blue target pitch curve will change to yellow if your pitch is close to it.
            </Paragraph>
            <Paragraph style={{ textAlign: fontAlign, fontSize: fontSizePara }}>
              If you would like to have some sounds to make while matching the target pitches, the Show/Hide buttons  below the graph display a 5-syllable phrase. The full phrase is shown below the Show/Hide buttons, and each individual syllable is shown on one of the target pitch lines. The Forward/Backward buttons let you move to the next/previous phrase, and the Retry button resets the current phrase.  You don’t have to use this function, it’s just if you want to have something to say.
            </Paragraph>

            <Typography.Title id="part-2-5" level={4} style={{ margin: 0 }}>
              2.5 Syllables exercise
            </Typography.Title>
            <Paragraph style={{ textAlign: fontAlign, fontSize: fontSizePara }}>
              To use the Syllables exercise, you must first select an avatar in the Options menu . Once you have done that, the Syllables exercise shows pitch curves from that recorded avatar while speaking 2-, 3-, 4-, or 5-syllable phrases in dark blue. As in the other two exercises, the graph shows your current pitch with a large black dot and the last few seconds of your pitch with small red dots. If you stop talking, the graph keeps moving but does not show new red dots.      </Paragraph>
            <Paragraph style={{ textAlign: fontAlign, fontSize: fontSizePara }}>
              The goal of the exercise is to match the avatar’s pitch curve as you say the same phrase. The target phrase is shown below the graph in green. Pressing the Play button starts moving the current pitch indicator. Press it when you are ready to try matching the curve. You can pause at any time, and once the indicator gets to the end, you can press Play again to restart the same phrase. As you speak, the dark blue target pitch curve will change to yellow if your pitch is close to it.       </Paragraph>
            <Paragraph style={{ textAlign: fontAlign, fontSize: fontSizePara }}>
              Once the phrase finishes, you can press the Retry button to try matching it again, or press Forward/Backward to move to the next or previous phrase. The next two phrases are shown in grey below the current phrase.      </Paragraph>
            <Paragraph style={{ textAlign: fontAlign, fontSize: fontSizePara }}>
              If you want to hear the phrase spoken by your selected avatar, press the Listen button. The dark blue curve will change to yellow as the phrase is played to indicate the timing.      </Paragraph>

            <Typography.Title id="part-2-6" level={4} style={{ margin: 0 }}>
              2.6 Suggestions for using the pitch module
            </Typography.Title>
            <Paragraph style={{ textAlign: fontAlign, fontSize: fontSizePara }}>
              If you have limited ability to control your pitch, we recommend starting with the Constant exercise. Measure your current pitch and set the target pitch (with the slider on the right) to somewhere above your regular pitch. The “stereotypical” value for female pitch is about 200 Hz. Make sustained sounds (for example, “aaaaaaaaaaa”) and try to consistently match the target pitch. Once you can consistently match the target pitch with all five vowels (a, e, i, o, u), try speaking longer phrases at the target pitch.       </Paragraph>
            <Paragraph style={{ textAlign: fontAlign, fontSize: fontSizePara }}>
              When you feel comfortable matching the target pitch in the Constant exercise for short periods of time, add the Staircase exercise to your practice rotation. You can start by hiding the target text and just matching the target pitches with a sustained vowel sound (for example, “aaaaa” moving up and down in pitch). Once you can do that with all five vowel sounds, turn the target text on and try speaking the phrases. Once you can do that, you may also try the Syllables exercise, though you do not necessarily need to do the Syllables exercise – the Constant and Staircase will give you the fundamentals.      </Paragraph>
            <Paragraph style={{ textAlign: fontAlign, fontSize: fontSizePara }}>
              If this is all of your voice practice, do it for 10-15 minutes two times a day. It will help you learn pitch control, though you should keep in mind that pitch is not the only component of transgender voice. You will want to move on to other components of voice as well.      </Paragraph> */}

            <br />
            <br />
            <Typography.Title id="part-4" level={2} style={{ margin: 0 }}>
              4. Volume module
            </Typography.Title>
            <br />
            <Paragraph style={{ textAlign: fontAlign, fontSize: fontSizePara }}>
              The Volume module is intended to help simultaneously practice pitch and loudness. It is currently not really functional and simply shows current volume in red and current pitch in blue. This will be expanded in a future update.

            </Paragraph>

          </Typography>
        </Col>
        <Col span={1}></Col>
      </Row>

      {/* <Row>
      <Col span={2}></Col>
      <Col span={9}>
      <Collapse items={items} defaultActiveKey={['1']} />
      </Col>
      <Col span={2}></Col>
      <Col span={9}>
      <Collapse items={items} defaultActiveKey={['1']} />
      </Col>
      <Col span={2}></Col>
    </Row> */}



      {/* <h1>Help Page:Catalog</h1> */}
    </div>
  );
};

export default Help;
