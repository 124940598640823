// Manually created for GAVT project on 2024-06-03, based on commit 10df1d9 of the original project

/*
 This file contains the code for LPC generation and its usage.
 The code used to generate the LPC was ported  from C++ code originally in the staRt iPad app.
 */

 import {Complex} from '../Wave/lib/complex.jsx';
 
 const _PI = 3.14159265;
 const _LPC_ORDER = 40;
 const _MAX_LPC_ORDER = _LPC_ORDER + 1;

 // Mannualy fixed for GAVT project on 2024-06-03
 const SAMPLE_RATE = 44100;
 
 let delsmp = 0.0;
 let last = false;
 
 const computeLPC = (audio_buffer) => {
     const lpc_coeffs = [];
     for (let i = 0; i < _LPC_ORDER; i++) {
         lpc_coeffs.push(0);
     }
 
     const buffer_size = audio_buffer.length;
     const win = _hann_window(buffer_size);
 
     const mean = _meanv(audio_buffer, buffer_size);
     // remove the mean
     audio_buffer = _vsadd(audio_buffer, -mean, buffer_size);
 
 
     audio_buffer = _vmul(audio_buffer, win, buffer_size);
 
     // Original code uses temp buffer that we were able to remove in javascript version.
     audio_buffer = _highPassFilter(audio_buffer, buffer_size);
 
 
     const coeffs = _lpc_from_data(_LPC_ORDER, buffer_size, audio_buffer);
     //lpc_coeffs[0] = 1.0;
     for (let i = 0; i < _LPC_ORDER; i++) {
         lpc_coeffs[i] = coeffs[i];
     }
     return lpc_coeffs;
 };
 
 // Originally this set the value of "win"
 // We should probably cache this result as it always generates the same value.
 // Dunno why this is ++i in the original code, out of bounds error? Fixed in mine.
 // CHECKED (Off by a bit for floating)
 const _hann_window = (buffer_size) => {
     const win = [];
     const W = 0.5;
     for (let i = 0; i < buffer_size; ++i) {
         win.push(W * (1 - Math.cos(2 * _PI * i / buffer_size)));
     }
     return win;
 };
 
 const _meanv = (buffer, buffer_size) => {
     let sum = 0;
     for (let i = 0; i < buffer_size; i++) {
         sum += buffer[i];
     }
     return sum / (buffer_size * 1.0);
 };
 
 const _vsadd = (audio_buffer, v, buffer_size) => {
     const result = [];
     for (let i = 0; i < buffer_size; i++) {
         result.push(audio_buffer[i] + v);
     }
     return result;
 };
 
 const _vmul = (buffer_a, buffer_b, buffer_size) => {
     const result = [];
     for (let i = 0; i < buffer_size; i++) {
         result.push(buffer_a[i] * buffer_b[i]);
     }
     return result;
 };
 
 const _highPassFilter = (inBuffer, winSize) => {
     const result = [];
     const MAGIC_FACTOR = .95;
     for (let i = 0; i < winSize; i++) {
         result.push(inBuffer[i] - MAGIC_FACTOR * delsmp);
         delsmp = inBuffer[i];
     }
     return result;
 };
 // New autocorrelation that's much simpler -- not sure why the above has
 // the slope calculations and the size normalization
 const _autocorr_simple = (order, data) => {
     const result = [];
     let max_value;
     for (let i = 0; i <= order; i++) { // Compute autocorrelation up to `order`
         let sum = 0;
         for (let j = 0; j < data.length - i; j++) {
             sum += data[j] * data[j + i];
         }
         if (i == 0) {
             // We normalize to RMS of the signal -- not sure if this is right
             result.push(sum);
             max_value = sum;
         } else {
             result.push(sum);
         } 
     }
     return result;
 };
 
 const levinsonDurbin = (autocorr, order) => {
     // p = 1 -- base case
     let alpha = [autocorr[1]/autocorr[0]];
     const dot = (a, b) => a.map((x, i) => a[i] * b[i]).reduce((m, n) => m + n);
 
     for (let i = 1; i < order; i++){
         // get arrays
         let beta = [...alpha].reverse()
         let r = autocorr.slice(1, i+1)
         let p = [...r].reverse()
 
         // compute k, epsilon
         let k = (autocorr[i+1] - dot(p, alpha))/(autocorr[0] - dot(r, alpha))
         let epsilon = beta.map(x => -k * x)
 
         // update alphas
         alpha.push(0)
         epsilon.push(k)
 
         alpha = alpha.map((e,i) => e + epsilon[i]);
 
         // iterate
     }
     
     return alpha
 }
 
 // order is lpc order
 // size is audio_buffer size
 // data is audio_buffer
 // coeffs is the result, a double array the size of order.
 const _lpc_from_data = (order, size, data) => {
 
     const corr = _autocorr_simple(order, data);
     let coeffs2 = levinsonDurbin(corr, _LPC_ORDER);
 
     return coeffs2;
 };
 
 // theta is resused on every call to getFrequenciesFromLPC
 // so we declare it here as a global variable and initialize it only once in the function
 // getFrequenciesFromLPC.
 let theta = null;
 
 // TODO Should pass an options dictionary instead of a long list of parameters.
 const getFrequenciesFromLPC = (lpcCoeffs, resolution, maxFrequency, sampleRate) => {
     // H(z) = 1/[1-(a1*z^-1 + a2*z^-2 + ... + aN*z^-N)
     if (theta === null) {
         theta = new Array(resolution);
         const inc = (maxFrequency / resolution) * 2 * Math.PI / sampleRate;
         for (let i = 0; i < resolution; i++) {
             theta[i] = inc * i;
         }
     }
 
     const mags = new Array(resolution);
     for (let i = 0; i < theta.length; i++) {
         let temp = new Complex(0,0);
         for (let j = 0; j < lpcCoeffs.length; j++) {
             // * (j+1) raises complex number to the power of (j+1)
             let z = new Complex(
                 Math.cos(theta[i] * (j+1)),
                 Math.sin(theta[i] * (j+1)));
             let az = new Complex(lpcCoeffs[j],0).mul(z);
             temp = temp.add(az);
         }
         let denom = new Complex(1,0).sub(temp);
         mags[i] = new Complex(1,0).div(denom);
         
     }
     
     for (let i = 0; i < mags.length; i++) {
         mags[i] = 1*Math.log10(mags[i].abs());
     }
     
     return mags;
 };
 
 
 const _biggerThanMyNeighbors = (mags, myLoc, numNeighbors) => {
     let result = true;
     // TODO Figure out this magic 5 number. How Local should be based on frequency resolution, atm 50hz.
     for (let i = myLoc - numNeighbors; i < myLoc + numNeighbors; i++) {
         result = result && mags[myLoc] > mags[i];
     }
     return result;
 };
 
 const getPeaks = (mags) => {
     const MIN_ENERGY = .315;
     // To improve this we need to make sure we only take the peaks with the most energy for NUM_PEAKS.
     // Peaks are when slope goes from positive to negative.
     // Allowed more local peaks (halved value) to help with F2/F3 discrepancy in 'r' sound
     const HOW_LOCAL = Math.floor(mags.length / 10.0);
     let slope = 0;
     let i = 0;
     const peaks = [];
     while (slope == 0 && i < mags.length) {
         if (mags[i] < mags[i + 1]) {
             slope = 1;
             break;
         } else if (mags[i] > mags[i + 1]) {
             slope = -1;
         }
         i++;
     }
     for (; i < mags.length; i++) {
         if (slope == 1) {
             if (mags[i] > mags[i + 1]) {
                 if (mags[i] > MIN_ENERGY) {
                     // if (i > HOW_LOCAL && i < mags.length - HOW_LOCAL && _biggerThanMyNeighbors(mags, i, HOW_LOCAL));
                     if (_biggerThanMyNeighbors(mags, i, HOW_LOCAL));
                     {
                         peaks.push(i);
                     }
                 }
                 slope = -1;
             }
         } else { // slope == -1
             if (mags[i] < mags[i + 1]) {
                 slope = 1;
             }
         }
     }
     return peaks;
 };
 
 export {computeLPC, getPeaks, getFrequenciesFromLPC};
 
 
 // FORMANT EXTRACTION CODE
 
 // Initiate F2 array & boolean gate
 let runningF1 = [];
 let runningF2 = [];
 let runningF3 = [];
 let averageF1;
 let averageF2;
 let averageF3;
 let coefficients = [];
 let runningLPC = new Float32Array(64);
 let lpcCounter = 0;
 
 // helper function to add values to array
 const addToRunningFormants = (values) => {
     runningF1.push(values[0]);
     runningF2.push(values[1]);
     runningF3.push(values[2]);
 }
 
 // close the gate & return value
 const returnAndClearFormants = (calculateFormants) => {
     if (calculateFormants) {
         // const formant1ValueElement = document.getElementById("formant1Value");
         // formant1ValueElement.textContent = "Tracking...";
         // const formant2ValueElement = document.getElementById("formant2Value");
         // formant2ValueElement.textContent = "Tracking...";
         // const formant3ValueElement = document.getElementById("formant3Value");
         // formant3ValueElement.textContent = "Tracking...";
     } else if (!calculateFormants && last) {
        
         runningF1 = runningF1.filter(function( element ) {
             return element !== undefined;
         });
         runningF2 = runningF2.filter(function( element ) {
             return element !== undefined;
         });
         runningF3 = runningF3.filter(function( element ) {
             return element !== undefined;
         });
         
        //  if (runningF1 === undefined || runningF1.length == 0) {
        //      averageF1 = "Please try again, no formants were recorded.";
        //  } else {
        //      averageF1 = (runningF1.reduce((accumulator, currentValue) => accumulator + currentValue, 0)/runningF1.length).toFixed(2);
        //  }
        //  if (runningF2 === undefined || runningF2.length == 0) {
        //      averageF2 = "Please try again, no formants were recorded.";
        //  } else {
        //      averageF2 = (runningF2.reduce((accumulator, currentValue) => accumulator + currentValue, 0)/runningF2.length).toFixed(2);
        //  }
        //  if (runningF3 === undefined || runningF3.length == 0) {
        //      averageF3 = "Please try again, no formants were recorded.";
        //  } else {
        //      averageF3 = (runningF3.reduce((accumulator, currentValue) => accumulator + currentValue, 0)/runningF3.length).toFixed(2);
        //  }
     
         // const formant1ValueElement = document.getElementById("formant1Value");
         // formant1ValueElement.textContent = averageF1;
         // const formant2ValueElement = document.getElementById("formant2Value");
         // formant2ValueElement.textContent = averageF2;
         // const formant3ValueElement = document.getElementById("formant3Value");
         // formant3ValueElement.textContent = averageF3;
         
        //  console.log('Peak Picking Formants: ', averageF1, averageF2, averageF3);
        //  console.log('');
 
         runningF1.length = 0;
         runningF2.length = 0;
         runningF3.length = 0;
         coefficients.length = 0;
         
         let overallLPC = calculateAverageLPC(runningLPC);
         let roots = durandKerner(overallLPC);
         let formants = extractFormants(roots, SAMPLE_RATE);
         let bestFormants = formants.sort((a,b) => a.bandwidth - b.bandwidth).slice(0,4).sort((a,b) => a.frequency - b.frequency);
         console.log('Durand Kerner F1, F2, F3, F4 estimates: ', bestFormants);

        // Customized to integrate with GAVT project
         if (bestFormants.length >= 2) {
            last = calculateFormants;
            return bestFormants[1].frequency;
        } else {
            console.log('Not enough formants detected.');
            last = calculateFormants;
            return null;
        }
     }
     last = calculateFormants;
 }
 
 const isImpulse = (array) => {
     let nonZeroCount = 0;
 
     for (let i = 0; i < array.length; i++) {
         if (array[i] !== 0) {
             nonZeroCount++;
             if (nonZeroCount > 1) {
                 return false; // More than one non-zero element
             }
         }
     }
 
     return nonZeroCount === 1; // True if exactly one non-zero element, false otherwise
 }
 
 const isEmpty = (myArray) => {
     let nanCounter = 0;
     for (let i = 0; i < myArray.length; i++){
         if (isNaN(myArray[i])){
             nanCounter++;
         }
     }
     let isEmpty = false;
     if (nanCounter == myArray.length) {
         isEmpty = true;
     }
     return isEmpty
 }
 
 const addToRunningLPC = (coeffs) => {
     if (!isEmpty(coeffs)){
         if (!isImpulse(coeffs)) {
             for (let i = 0; i <= coeffs.length; i++){
                 runningLPC[i] += coeffs[i]
             }
             lpcCounter += 1;
         }
     }
 }
 
 const calculateAverageLPC = () => {
     let averageLPC = new Float32Array(_LPC_ORDER);
     for (let i = 0; i < runningLPC.length; i++){
         averageLPC[i] = runningLPC[i]/lpcCounter;
     }
     runningLPC.fill(0);
     lpcCounter = 0;
     return averageLPC;
 }
 
 
 // UPDATED FORMANT ANALYSIS
 
 const durandKerner = (coefficients) => {
     let coeffs = Array.from(coefficients.slice().reverse());
     coeffs = coeffs.map(value => -value);
     coeffs.push(1);
     const tolerance = 1e-6;
     const maxIterations = 100;
     const n = coeffs.length - 1; // Degree of the polynomial
   
     // Initial guesses for roots (using a small perturbation from 1)
     let roots = Array.from({ length: n }, (_, i) => new Complex(0.4, 0.8).pow(i));
   
     for (let iter = 0; iter < maxIterations; iter++) {
       let isConverged = true;
   
       for (let i = 0; i < n; i++) {
         let prod = new Complex(1, 0);
         for (let j = 0; j < n; j++) {
           if (i !== j) {
             prod = prod.mul(roots[i].sub(roots[j]));
           }
         }
   
         const numerator = evaluatePolynomial(coeffs, roots[i]);
         const newRoot = roots[i].sub(numerator.div(prod));
   
         if (newRoot.sub(roots[i]).abs() > tolerance) {
           isConverged = false;
         }
         roots[i] = newRoot;
       }
   
       if (isConverged) {
         break;
       }
     }
   
     return roots.map(root => ({ real: root.re, imag: root.im }));
 }
   
 const evaluatePolynomial = (coefficients, z) => {
    // Bug fixed
    // Polynomial should be in form:
    // z^k + a1*z^(k-1) + ... + ak = 0
    let result = z.pow(coefficients.length);
    for (let i = 0; i < coefficients.length; i++) {
        result = result.add(new Complex(coefficients[i], 0).mul(z.pow(coefficients.length - i - 1)));
    }
    return result;
}
 
 const extractFormants = (roots, samplingRate) =>{
     let formants = roots
       .filter(root => root.imag !== 0) // Filter out non-complex roots
       .map(root => {
         const radius = Math.sqrt(root.real * root.real + root.imag * root.imag);
         const angle = Math.atan2(root.imag, root.real);
   
         return {
           frequency: (angle / (2 * Math.PI)) * samplingRate,
           bandwidth: (-(1/2)*Math.log(radius) / Math.PI) * samplingRate
         };
       })
       .filter(formant => formant.bandwidth > 0 && formant.bandwidth < 400) // Example bandwidth thresholds
       .filter(formant => formant.frequency > 20 && formant.frequency < 4096)
       .sort((a, b) => a.frequency - b.frequency);
   
     return formants;
 }
 
 export {addToRunningFormants, addToRunningLPC, isImpulse, returnAndClearFormants, coefficients, extractFormants, durandKerner, runningLPC};