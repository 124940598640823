// Manually created for GAVT project on 2024-06-03, based on commit 10df1d9 of the original project
import { createArrayWithSameValue } from './lib/spectroHelpers.js'

import {computeLPC, getPeaks, getFrequenciesFromLPC, 
    addToRunningFormants, addToRunningLPC, isImpulse,
    returnAndClearFormants, coefficients, } from '../lib/lpc.js';

import {    expandArraySmoothly,  // expandArraySmoothly(array, targetResolution int)
            getMean,              // getMean(array)
            updateMagnitudes,     // updateMagnitudes(newMMagArray, currentMagArray);
            normalizeIfNecessary
} from './lib/spectroHelpers.js';


const SAMPLE_RATE = 44100;
const FRAME_SIZE = 4096; // Number of audio samples used to generate LPC Coeffs.;
const MAX_LPC_FREQ = 4096;  // hc needs a better understanding of this
const LPC_DISPLAY_RES = 512; // 64

const EMPTY_WAVE_MAG =  0.3;


// These fns are imported into WaveCanvas


// ===================================================================
// AUDIO SETUP FNS

// initAudio Helper ------------------------------------
const convertToMono = (input, audioContextRef) => {
    const splitter = audioContextRef.createChannelSplitter(2);
    const merger = audioContextRef.createChannelMerger(2);
    input.connect(splitter);
    splitter.connect(merger, 0, 0);
    splitter.connect(merger, 0, 1);

    return merger;
};

// initAudio  ------------------------------------
const initAudio = (microphone, audioContextRef, analyser) => { 
    
    console.log('mic should be a MediaStreamAudioSourceNode')
    console.log(microphone);

    analyser.fftSize = FRAME_SIZE;

    const audioInput = convertToMono(microphone, audioContextRef);
    const HIGH_PASS = 200;
    const LOW_PASS = MAX_LPC_FREQ+1000;

    const highpass = audioContextRef.createBiquadFilter();
    const lowpass = audioContextRef.createBiquadFilter();

    highpass.type = "highpass";
    highpass.frequency.value = HIGH_PASS;
    lowpass.type = "lowpass";
    lowpass.frequency.value = LOW_PASS;
    lowpass.Q.value = 100

    // audioInput.connect(highpass);
    // highpass.connect(lowpass);
    audioInput.connect(analyser);

    return(analyser)
}

// ===================================================================
// COEFF UPDATERS / Interval Loop 

const updateCoeffsOffline = (audioData, ) => { // UPDATES COEFFS DATA
    let lpcCoeffs = computeLPC(audioData);
    let lastReceivedCoeffs = lpcCoeffs;
    //   console.log(lastReceivedCoeffs);

    return lastReceivedCoeffs
}

// TODO: --------------------------------------------
const updateCoeffsWithVonage = function () {}


// ===================================================================
// MAGS & PEAKS FROM LPC 
// gets and shapes wave mag & peak data to be used in drawing sketch

// Static scaling function -- scale_factor expected in range 0-200
var scale = function (lpc, scale_factor) {
    
    for (var i = 0; i < lpc.length; i++) {
        //console.log(sensitivityValueEle.value)
        // value ranges from 0-200
        var factor = scale_factor / 100.0;
        // following lines smooth the low end of the visualization
        // (doesn't effect any of the LPC calculations)
        if (i <= 100){
            factor = factor - .0005*(100-i)
        } else {
            factor = factor - .0001*(100-i)
        }
        lpc[i] = lpc[i] * factor;
    }
    return lpc;
};

const updateData = (drawData, lastReceivedCoeffs, calculateFormants) => {
    const DRAW_PEAK_THRESHOLD = 0.15;
    const HIDE_WAVE_MAG_THRESHOLD = -1; // -1 means always animate wave, otherwise min energy.
    const HIDE_WAVE_FRAME_THRESHOLD = 60;
    let numFramesBelowMin = 0;
    let mags = drawData.magnitudes;
    let peaks = drawData.peaks;

    let newMagnitudes = getFrequenciesFromLPC(
        lastReceivedCoeffs, 
        LPC_DISPLAY_RES,
        MAX_LPC_FREQ,
        SAMPLE_RATE
    );

    // ------------------------ Post LPC
    //newMagnitudes = expandArraySmoothly(newMagnitudes, 512);
    // TODO perhaps move this after scaling. move 256 num to const.
    
    newMagnitudes = scale(newMagnitudes, 20)
    let magMean = getMean(newMagnitudes);

    if (magMean < HIDE_WAVE_MAG_THRESHOLD) {
        numFramesBelowMin += 1;
        if (numFramesBelowMin > HIDE_WAVE_FRAME_THRESHOLD) {
            newMagnitudes = createArrayWithSameValue(
            EMPTY_WAVE_MAG,
            newMagnitudes.length
            );
        }
        } else {
            numFramesBelowMin = 0;
    }
  
    if (mags == 0) { 
        mags = newMagnitudes;
    } else {
        // console.log(mags)
        // console.log(newMagnitudes)
        mags = updateMagnitudes(newMagnitudes, mags); 
        // console.log(mags)
        //mags = newMagnitudes;
    }
    // console.log('mags after data update')
    // console.log(magsRef.current)
  
    if (magMean >= DRAW_PEAK_THRESHOLD) {
        peaks = getPeaks(mags);
        // choose only top 5 peaks if more than four exist
        let numPeaks = 5;
        // console.log(peaks)
        // console.log(peaks.map(id => mags[id]))
        if (peaks.length > 4){
            let indexedPeaks = peaks.map(id => ({
                value: mags[id],
                originalIdx: id
            }));

            indexedPeaks.sort((a,b) => b.value - a.value);
            let newPeaks = indexedPeaks.slice(0,numPeaks).map(item => item.originalIdx);

            peaks = newPeaks.sort((a, b) => a - b);
        }

    } else {
        peaks = [];
    }

    returnAndClearFormants(calculateFormants)
    // if (calculateFormants == true && !isImpulse(lastReceivedCoeffs)){
    //     coefficients.push(lastReceivedCoeffs);
    // }
    // Calculate frequencies of formants
    let formantFreqz = peaks.map(x => x * (MAX_LPC_FREQ/LPC_DISPLAY_RES));
    // If start button is pressed, add F2 to array
    // only capture formants if there are actually peaks present
    if (calculateFormants == true && peaks.length > 0){
        
        addToRunningFormants(formantFreqz);

        let lpcCoeffs = lastReceivedCoeffs;
        addToRunningLPC(lpcCoeffs);

        if (!isImpulse(lpcCoeffs)){
            coefficients.push(lpcCoeffs);
        }
    }

    mags = normalizeIfNecessary(mags);
  
    drawData = {
      magnitudes: mags,
      peaks: peaks
    }
    return drawData
}


// ===============================================
export { initAudio, updateData, updateCoeffsOffline, updateCoeffsWithVonage, FRAME_SIZE, LPC_DISPLAY_RES, SAMPLE_RATE};