import React, { useState } from 'react';
import { Typography, Col, Row } from 'antd';
import { Link } from 'react-router-dom';

const { Title, Paragraph } = Typography;

const fontSizePara = 17;
const fontAlign = "justify";
const paragraphStyle: React.CSSProperties = {
  textAlign: fontAlign,
  fontSize: fontSizePara,
  fontFamily: "'Roboto', 'sans-serif'",
};

interface AboutProps {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  showModal: () => void;
  handleCancel: () => void;
}

const About: React.FC<AboutProps> = ({ isModalOpen, setIsModalOpen, showModal, handleCancel }) => {
  const [currentSelection, setCurrentSelection] = useState('Profile');

  const renderContent = () => {
    switch (currentSelection) {
      case 'Profile':
        return <p>Profile content</p>;
      case 'Settings':
        return <p>Settings content</p>;
      case 'Other':
        return <p>Other content</p>;
      default:
        return null;
    }
  };

  return (
    <div style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
      <div className="aboutmain">
        <Row>
          <Col span={5}></Col>
          <Col span={14}>
          <Typography style={{ paddingLeft: "30px", paddingRight: "40px", marginBottom: "10px", textAlign: "center" }}>
  <div className="centerTitle">
    <Title className="customTitle" style={{ textDecoration: "underline", color: 'rgb(36, 36, 131)', textDecorationThickness: "2px" }}>
      ABOUT
    </Title>
  </div>

              <Paragraph style={paragraphStyle}>
                This prototype voice coaching app was developed by the University of Cincinnati Department of Electrical and Computer Engineering under supervision of Prof. Vesna Novak. Contributors include Xiangyi Wang, Sam Weese, Om Jadhav, Victoria McKenna, Maggie Lyon and Ansh Bhanushali. The project was funded by the National Institute of Deafness and Communication Disorders (project R21DC021537) and by a Collaborative Pilot Grant from the University of Cincinnati Office of Research. The pitch calculation and display code is based on code by
                {' '}<Link style={{ fontSize: fontSizePara }} to="http://www.speechandhearing.net/" target="_blank">
                  Mark Huckvale, University College London
                </Link>
                . The app is still in development, and updates are added regularly.
              </Paragraph>
              <Paragraph style={paragraphStyle}>
                Our group aims to provide free and accessible gender-affirming voice coaching software that can be used by trans people as a supplement to professional voice coaching or as a stand-alone tool by trans people who cannot access professional voice coaching. We acknowledge that pitch is not the only component of gender-affirming voice coaching, but have focused on it in this first app since it is easily understood by beginners. In the future, we plan to expand to apps for coaching resonance and other aspects of transgender voice.
              </Paragraph>
              <Paragraph style={paragraphStyle}>
                If you would like to discuss the project, feel free to reach out to Prof. Novak at
                <Link style={{ fontSize: fontSizePara }} to="mailto:novakdn@ucmail.uc.edu"> novakdn@ucmail.uc.edu</Link>.
              </Paragraph>
            </Typography>
          </Col>
          <Col span={5}></Col>
        </Row>
      </div>
      <div style={{ width: '100%', background: 'white', padding: '20px 0', textAlign: 'center', paddingLeft: "1.2rem"  }}>
        <Row justify="center" align="middle" style={{ width: '100%' }}>
          <Col span={10}>
            <Paragraph style={{width:"40%"}}>
              Professor Vesna Novak
              <Link to="mailto:novakdn@ucmail.uc.edu"> novakdn@ucmail.uc.edu</Link>
            </Paragraph>
          </Col>
          <Col span={10} offset={3}>
            <Paragraph style={{width:"60%", paddingLeft: "0.3rem"}}>
              Rhodes 833, 2851 Woodside Ave., Cincinnati OH 45221
            </Paragraph>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default About;
