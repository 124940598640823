import {waveMiniData, diagrams, waveGraphData, bgPeaksData} from './tutorialWaveData'

/*
    A createTutorialData() provides the data for populating tutorial templates and svg group ids. Once a new tutorial session is started, createTutorialData() will return a new copy of this array, called tutData in the tutorial.js.
    
    State properties in tutData should only be modified in tutorial.js.
    Tutorial.js will pass tutData to tutorialDOM scripts to create or update DOM eles as needed. 

    Basically treat this as readOnly, which is only read once at init. 
    The scripts should NOT modify or return tutData. 
*/

/*  DOCUMENTATION -------------------------------------------

    tutDataItem = {

        ------------------------
        id: string       Step id, per design docs

        ------------------------
        nav: {                          

            title: string           "Step 1: The Wave > Peaks",
                                        display text for onHover tooltip

            bubText: string         "1"
                                        this is the int in bubble btn

            style: [ 'string, ],    ['navBubBig'],
                                        css classNames

            state: enum string      options: 'active', 'post', null 
                                        nav.state is used to apply css styles
        },


        ------------------------ #TODO
        copyBox: {}, 
        
        waveMini: [     3 slots only
            TODO: this could be simplified, now that we are no longer flipping the buttong positions (init pos could just become the arr index #)
            -- to update this we would need to update the ids in waveMini.html, which may not be worth the trouble.
        ],

        waveBox: {
            waveLive: bool      Used to show/hide the spectrogram element
            defaultDraw: "graphic", //"img" ]] "graphic";

            waveGraphics: [

                { 
                    bgPeaks: bgPeaksData.oooNY,
                    model: waveGraphData.oooNY,  
                    style: "filled", top: false, highlightLine: 0,
                },
                { 
                    bgPeaks: bgPeaksData.oooCA,
                    model: waveGraphData.oooCA,  
                    style: "filled", top: false, highlightLine: 0,
                },
            ],

            slider: bool    Used to show hide the starfish slider element.
        },

        ------------------------
        postLeft: { bgClass: "rope", },
        
        ------------------------
        postRight: { 

            fn: string          'pausePlay', 'none'    
                                    Addds test and handlers for wave's Pause/Paly fn.
                                    Currently 'pausePlay' is the only string that we have. If this btn is needed for something else, we add it here.
            isPaused: bool      
        }
        
        ------------------------
        charScene: 'p0s1',

*/
const createTutorialData = () => {

    return [
        {
            id: 'p0s1',
            nav: {
                title: "StaRt Tutorial",
                bubText: "",
                style: [],
                state: "active",
            },
            copyBox: {
                class: "tutCopyBox",
                content: [
                    {
                        id: "copy01",
                        class: "",
                        html: "Welcome to Speech Beach!",
                    },
                    {
                        id: "copy02",
                        class: "",
                        html: "This tutorial will show you how to use the staRt wave as part of your speech therapy. We encourage the student and the speech pathologist to complete this together.",
                    },
                    {
                        id: "copy03",
                        class: "",
                        html: "The tutorial should take about 5 minutes.",
                    },
                ],
            },
            waveBox: { waveLive: true, slider: false, },
            postLeft: { bgClass: "rope", },
            postRight: { fn: 'pausePlay', },
            charScene: 'p0s1',
        },
        {
            id: 'p0s2',
            nav: {
                title: "Mic Check",
                style: [],
                state: "",
            },
            copyBox: {
                // class: "tutCopyBox",
                content: [
                    {
                        id: "copy01",
                        class: "",
                        html: "StaRt works best with an external microphone plugged into your device, even if it's just a set of earbuds with an inline microphone.",
                    },
                    // {
                    //     id: "copy02",
                    //     class: "",
                    //     html: "If your wave does not look like the models in this tutorial you may want to try...",
                    // },
                ],
            },
            waveBox: { waveLive: true, slider: false, },
            postLeft: { bgClass: "rope", },
            postRight: { fn: 'pausePlay', },
            charScene: 'p0s2',
        },
        {
            id: 'p1s1',
            nav: {
                title: "Step 1: The Wave",
                bubText: "1",
                style: ['navBubBig'],
                state: "",
            },
            copyBox: {
                content: [
                    {
                        id: "copy01",
                        class: "",
                        html: "StaRt makes a picture of your speech sounds in real-time.",
                    },
                    {
                        id: "copy02",
                        class: "",
                        html: "Speak into your microphone.",
                    },
                    {
                        id: "copy03",
                        class: "",
                        html: "See how the wave moves around when you talk?",
                    },
                ],
            },
            waveBox: { waveLive: true, slider: false, },
            postLeft: { bgClass: "rope", },
            postRight: { fn: 'pausePlay', },
            charScene: 'p1s1',
        },
        {
            id: 'p1s2',
            nav: {
                title: "Step 1: The Wave > Peaks",
                style: [],
                state: "",
            },
            copyBox: {
                content: [
                    {
                        id: "copy01",
                        class: "",
                        html: "Peaks create your wave's shape.",
                    },
                    {
                        id: "copy02",
                        class: "",
                        html: "We are going to focus on the bumps or peaks in your wave.",
                    },
                    {
                        id: "copy03",
                        class: "",
                        html: "Press the 'Start Wave' sign to see how the peaks move when you talk.",
                    },
                ],
            },
            waveBox: {
                waveLive: false,
                defaultDraw: "graphic", //"img" ]] "graphic";
                // imgUrls: [ { url: "p1s2" }, ],
                waveGraphics: [
                    {
                        model: waveGraphData.p1, style: 'filled', top: false,
                        diagram: diagrams.p1s2, showDiagram: true,
                    }
                ],
                slider: false,
            },
            postLeft: { bgClass: "rope", },
            postRight: { fn: 'pausePlay', },
            charScene: 'p1s2',
        },

        {
            id: 'p1s3',
            nav: {
                title: "Step 1: The Wave > Lines",
                style: [],
                state: "",
            },
            copyBox: {
                // class: "",
                content: [
                    {
                        id: "copy01",
                        class: "",
                        html: "The white lines are there to help you find peaks in your wave, but sometimes, you will see a peak without a line, or a line without a peak.",
                    },
                    {
                        id: "copy02",
                        class: "",
                        html: "Pay more attention to the overall shape of the wave.",
                    },
                    {
                        id: "copy03",
                        class: "",
                        html: "In StaRt, peaks are more important than lines.",
                    },
                ],
            },
            waveBox: {
                waveLive: false,
                defaultDraw: "graphic", //"img" ]] "graphic";
                // imgUrls: [ { url: "p1s3" }, ],
                waveGraphics: [
                    {
                        model: waveGraphData.p1, style: 'filled', top: false,
                        diagram: diagrams.p1s3, showDiagram: true,
                    }
                ],
                slider: false,
            },
            postLeft: { bgClass: "rope", },
            postRight: { fn: 'none', }
        },
        {
            id: 'p2s1',
            nav: {
                title: "Step 2: 'Eee' Sounds",
                bubText: "2",
                style: ['navBubBig'],
                state: "",
            },
            copyBox: {
                class: 'tutCopyBox-p2s1',
                content: [
                    {
                        id: "copy01",
                        class: "",
                        html: "Here is a picture of my wave for an 'eee' sound.",
                    },
                    {
                        id: "copy02",
                        class: "p2s1_2 ",
                        html: "the 1st peak is all the way to the left,",
                    },
                    {
                        id: "copy03",
                        class: "p2s1_3 ",
                        html: "the 2nd peak is pretty far to the right, and",
                    },
                    {
                        id: "copy04",
                        class: "p2s1_4 ",
                        html: "the 3rd is even further to the right.",
                    },
                ],
            },
            waveBox: {
                waveLive: false,
                defaultDraw: "graphic", //"img" ]] "graphic";
                // imgUrls: [ { url: "p2s1" }, ],
                waveGraphics: [
                    {
                        bgPeaks: bgPeaksData.eee,
                        model: waveGraphData.eee, style: 'filled', top: false,
                        diagram: diagrams.eee, showDiagram: true,
                    }
                ],
                slider: false,
            },
            postLeft: { bgClass: "rope", },
            postRight: { fn: 'none', },
        },
        {
            id: 'p2s2',
            nav: {
                title: "Step 2: 'Eee' Sounds > Now You Try",
                style: [],
                state: "",
            },
            copyBox: {
                //class: "",//"tutCopyBoxShort",
                content: [
                    {
                        id: "copy01",
                        class: "",
                        html: "Now you try!",
                    },
                    {
                        id: "copy02",
                        class: "",
                        html: "How does your wave look when you say 'eee?'",
                    },
                    {
                        id: "copy03",
                        class: "",
                        html: "Don’t worry if your peaks are higher or lower than the model - just focus on the location.",
                    },
                ],
            }, // copyBox
            // waveMini: [], //removed
            waveBox: {
                waveLive: true,
                defaultDraw: "graphic", //"img" ]] "graphic";
                imgUrls: [{ url: "p2s2" },],
                waveGraphics: [
                    {
                        bgPeaks: bgPeaksData.eee,
                        model: waveGraphData.eee, style: 'lined', top: true,
                    }
                ],
                slider: false,
            },
            postLeft: { bgClass: "rope", },
            postRight: { fn: 'pausePlay', }
        },
        {
            id: 'p3s1',
            nav: {
                title: "Step 3: 'Ahh' Sounds",
                bubText: "3",
                style: ['navBubBig'],
                state: "",
            },
            copyBox: {
                class: "tutCopyBoxShort",
                content: [
                    {
                        id: "copy01",
                        class: "",
                        html: "Different sounds have peaks in different places.",
                    },
                    {
                        id: "copy02",
                        class: "",
                        html: "If I say an 'ahh' sound as in 'father,' my wave looks like this.",
                    },
                    {
                        id: "copy03",
                        class: "",
                        html: "Toggle the models to see how the peaks in 'ahh' look different from the peaks in 'eee.'",
                    },
                ],
            },
            waveMini: [
                {
                    initPos: "top",
                    type: "waveBtn",
                    btnContent: waveMiniData.ahh,
                    audio: false,
                    stateON: true,
                },
                {
                    initPos: "mid",
                    type: "switch",
                    btnContent: {
                        text: "switch wave order",
                        color: ["red", "navy"],
                    },
                    audio: false,
                    stateON: true,
                },
                {
                    initPos: "bottom",
                    type: "waveBtn",
                    btnContent: waveMiniData.eee,
                    audio: false,
                    stateON: false,
                }
            ], // end waveMini
            waveBox: {
                waveLive: false, //true,
                defaultDraw: "graphic", //"img" ]] "graphic";
                waveGraphics: [
                    {
                        bgPeaks: bgPeaksData.ahh,
                        model: waveGraphData.ahh, style: "filled", top: false, highlightLine: 0,
                        showDiagram: false,
                    },
                    {
                        bgPeaks: bgPeaksData.eee,
                        model: waveGraphData.eee,
                        style: "filled", top: false, highlightLine: 0,
                        showDiagram: false,
                    }
                ],
                slider: false,
            },
            postLeft: { bgClass: "rope", },
            postRight: { fn: 'none', },
        },
        {
            id: 'p3s2',
            nav: {
                title: "Step 3: 'Ahh' Sounds > Now You Try",
                // bubText: "",
                style: [],
                state: "",
            },
            copyBox: {
                class: "tutCopyBoxShort",
                content: [
                    {
                        id: "copy01",
                        class: "",
                        html: "Now you try!",
                    },
                    {
                        id: "copy02",
                        class: "",
                        html: "How does your wave look when you say 'ahh'?",
                    },
                ],
            },
            // waveMini: [], // removed
            waveBox: {
                waveLive: true,
                defaultDraw: "graphic", //"img" ]] "graphic";
                waveGraphics: [
                    {
                        bgPeaks: bgPeaksData.ahh,
                        model: waveGraphData.ahh, style: "lined", top: true, highlightLine: 0,
                        showDiagram: false,
                    },
                    // { model: waveGraphData.eee, 
                    //     style: "lined", stateON: true, showDiagram: false,
                    // }
                ],
                slider: false,
            },
            postLeft: { bgClass: "rope", },
            postRight: { fn: 'pausePlay', }
        },
        {
            id: 'p4s1',
            nav: {
                title: "Step 4: 'Ooo' Sounds",
                bubText: "4",
                style: ['navBubBig'],
                state: "",
            },
            copyBox: {
                // class: "tutCopyBoxShort",
                content: [
                    {
                        id: "copy01",
                        class: "",
                        html: "When I say 'ooo,' as in 'cool', my wave looks like this.",
                    },
                    {
                        id: "copy02",
                        class: "",
                        html: "How does your wave look when you say 'ooo'?",
                    },
                ],
            },
            // waveMini: [], // removed
            waveBox: {
                waveLive: true,
                defaultDraw: "graphic", //"img" ]] "graphic";
                waveGraphics: [
                    // { model: waveGraphData.ahh, 
                    //     style: "lined", stateON: false, showDiagram: false,
                    // },
                    // { model: waveGraphData.eee, 
                    //     style: "lined", stateON: false, showDiagram: false,
                    // },
                    {
                        bgPeaks: bgPeaksData.ooo,
                        model: waveGraphData.ooo,
                        style: "lined", top: true, highlightLine: 0,
                        showDiagram: false,
                    }
                ],
                slider: false,
            },
            postLeft: { bgClass: "rope", },
            postRight: { fn: 'pausePlay', }
        },
        {
            id: 'p4s2',
            nav: {
                title: "Step 4: 'Ooo' Sounds > Regional Differences",
                bubText: "",
                style: [],
                state: "",
            },
            copyBox: {
                class: "tutCopyBoxShort",
                content: [
                    {
                        id: "copy01",
                        class: "",
                        html: "Your wave might look different depending on where you are from.",
                    },
                    {
                        id: "copy02",
                        class: "",
                        html: "For example, people from California and people from New york tend to have very different 'ooo' sounds.",
                    },
                ],
            },
            waveMini: [
                {
                    initPos: "top",
                    type: "waveBtn",
                    btnContent: waveMiniData.oooNY,
                    audio: false,
                    stateON: true,
                },
                {
                    initPos: "mid",
                    type: "switch",
                    btnContent: {
                        text: "switch wave order",
                        color: ["white", "navy",],
                    },
                    audio: false,
                    stateON: true,
                },
                {
                    initPos: "bottom",
                    type: "waveBtn",
                    btnContent: waveMiniData.oooCA,
                    audio: false,
                    stateON: false,
                },
            ], // end waveMini
            waveBox: {
                waveLive: false,
                defaultDraw: "graphic", //"img" ]] "graphic";
                waveGraphics: [

                    {
                        bgPeaks: bgPeaksData.oooNY,
                        model: waveGraphData.oooNY,
                        style: "filled", top: false, highlightLine: 0,
                    },
                    {
                        bgPeaks: bgPeaksData.oooCA,
                        model: waveGraphData.oooCA,
                        style: "filled", top: false, highlightLine: 0,
                    },
                ],
                slider: false,
            },
            postLeft: { bgClass: "rope", },
            postRight: { fn: 'none', },
        },
        {
            id: 'p5s1',
            nav: {
                title: "Step 5: 'R' Sounds",
                bubText: "5",
                style: ['navBubBig'],
                state: "",
            },
            copyBox: {
                class: "tutCopyBox",
                content: [
                    {
                        id: "copy01",
                        class: "",
                        html: "Let's talk about 'R!' Here is a picture of a good 'r' sound.",
                    },
                    {
                        id: "copy02",
                        class: "",
                        html: "Focus on the 3rd peak. Look how close it is to the 2nd peak.",
                    },
                    {
                        id: "copy03",
                        class: "",
                        html: "Good 'r' sounds are unique because their 3rd peaks are really close to their 2nd peaks.",
                    },
                ],
            },
            // diagram: "true",
            waveBox: {
                waveLive: false,
                defaultDraw: "graphic", //"img" ]] "graphic";
                // imgUrls: [ { url: "p5s1" }, ],
                waveGraphics: [
                    {
                        bgPeaks: bgPeaksData.goodR3,
                        model: waveGraphData.goodR3, style: 'filled', top: false,
                        diagram: diagrams.p5s1, showDiagram: true,
                    },
                ],
                slider: false,
            },
            postLeft: {
                idx: 0,
                bgClass: "rope",
                fzSign: "",
                signBig: [
                    {
                        labelFor: "goodR3_p3", // ---------------------
                        label: "3rd Peak",
                        value: "1737",
                        color: "waveBlue",
                        tooltipText: "",
                    },
                ],
            },
            postRight: { fn: 'none', },
            character: {
                url: "str",
                top: 0,
                left: 0,
                height: 0,
                width: 0,
            }
        },
        {
            id: 'p5s2',
            nav: {
                title: "Step 5: 'R' Sounds > The 3rd Peak",
                bubText: "",
                style: [],
                state: "",
            },
            copyBox: {
                class: "tutCopyBoxShort",
                content: [
                    {
                        id: "copy01",
                        class: "",
                        html: "Notice the different locations of the waves' 3rd peaks.",
                    },
                    {
                        id: "copy02",
                        class: "",
                        html: "The green wave shows an 'r' that is not so good. It sounds like 'uhh.'",
                    },
                    {
                        id: "copy03",
                        class: "",
                        html: "The blue wave shows a really good 'r'. It sounds like 'err' and rhymes with 'her'.",
                    },
                ],
            },
            waveMini: [
                {
                    initPos: "top",
                    type: "waveBtn",
                    btnContent: waveMiniData.poorR,
                    audio: false,
                    stateON: true,
                },

                {
                    initPos: "mid",
                    type: "switch",
                    btnContent: {
                        text: "switch wave order",
                        color: ["spruce", "white"],
                    },
                    audio: false,
                    // stateON: true,
                },

                {
                    initPos: "bottom",
                    type: "waveBtn",
                    btnContent: waveMiniData.goodR3,
                    audio: false,
                    stateON: false,
                },
            ], // end waveMini
            // diagram: "true",
            waveBox: {
                waveLive: false,
                defaultDraw: "graphic", //"img" ]] "graphic";
                // imgUrls: [ { url: "p5s2a" }, { url: "p5s2b" }, ],
                waveGraphics: [
                    {
                        bgPeaks: bgPeaksData.poorR,
                        model: waveGraphData.poorR, style: 'filled', top: false,
                        diagram: diagrams.p5s2_poorR, showDiagram: true,
                    },
                    {
                        bgPeaks: bgPeaksData.goodR3,
                        model: waveGraphData.goodR3, style: 'filled', top: false,
                        diagram: diagrams.p5s2_goodR3, showDiagram: true,
                    },
                ],
                slider: false,
            },
            postLeft: {
                idx: 0,
                bgClass: "ropeCrab",
                fzSign: "",
                signBig: [
                    {
                        labelFor: "poorR_p3", // ---------------------
                        label: "3rd Peak",
                        value: "2791",
                        color: "spruce",
                        tooltipText: "",
                    },
                    {
                        labelFor: "goodR3_p3", // ---------------------
                        label: "3rd Peak",
                        value: "1737",
                        color: "waveBlue",
                        tooltipText: "",
                    },
                ],
            },
            postRight: { fn: 'none', },
            character: {
                url: "str",
                top: 0,
                left: 0,
                height: 0,
                width: 0,
            }
        },
        {
            id: 'p5s3',
            nav: {
                title: "Step 5: 'R' Sounds > Setting a Visual Target",
                bubText: "",
                style: [],
                state: "",
            },
            copyBox: {
                class: "tutCopyBoxShort",
                content: [
                    {
                        id: "copy01",
                        class: "",
                        html: "You can practice correct 'r' sounds by setting a visual cue for the 3rd peak",
                    },
                    {
                        id: "copy02",
                        class: "",
                        html: "In these images, the starfish and the line are the target.",
                    },
                    {
                        id: "copy03",
                        class: "",
                        html: "See how the 3rd peak aligns with the target in the 'Good R' image?",
                    },
                ],
            },
            waveMini: [
                {
                    initPos: "top",
                    type: "waveBtn",
                    btnContent: waveMiniData.poorR,
                    audio: false,
                    stateON: true,
                },

                {
                    initPos: "mid",
                    type: "switch",
                    btnContent: {
                        text: "switch wave order",
                        color: ["spruce", "white"],
                    },
                    audio: false,
                    stateON: true,
                },

                {
                    initPos: "bottom",
                    type: "waveBtn",
                    btnContent: waveMiniData.goodR3,
                    audio: false,
                    stateON: false,
                },
            ], // end waveMini
            // diagram: "true",
            waveBox: {
                waveLive: false,
                defaultDraw: "graphic", //"img" ]] "graphic";
                //imgUrls: [ { url: "p5s3a" }, { url: "p5s3b" }, ],
                waveGraphics: [
                    {
                        bgPeaks: bgPeaksData.goodR3,
                        model: waveGraphData.goodR3, style: 'filled', top: false,
                        diagram: diagrams.p5s3_goodR3, showDiagram: true,
                    },
                    {
                        bgPeaks: bgPeaksData.poorR,
                        model: waveGraphData.poorR, style: 'filled', top: false,
                        diagram: diagrams.p5s3_poorR, showDiagram: true,
                    },
                ],
                slider: {
                    isVisible: true,
                    pos: 1737,
                }
            },
            postLeft: {
                idx: 0,
                bgClass: "", // none
                fzSign: "",
                signStarfish: [
                    {
                        labelFor: "starfish", // ---------------------
                        label: "Starfish",
                        value: "1737",
                        color: "gold",
                        tooltipText: "",
                    },
                ],
                signBig: [
                    {
                        labelFor: "goodR3_p3", // ---------------------
                        label: "3rd Peak",
                        value: "1737",
                        color: "waveBlue",
                        tooltipText: "",
                    },
                    {
                        labelFor: "poorR3_p3", // ---------------------
                        label: "3rd Peak",
                        value: "2791",
                        color: "spruce",
                        tooltipText: "",
                    },
                ],
            },
            postRight: { fn: 'none', },
            character: {
                url: "str",
                top: 0,
                left: 0,
                height: 0,
                width: 0,
            }
        },
        {
            id: 'p5s4',
            nav: {
                title: "Step 5: 'R' Sounds > Sneaky Peaks",
                bubText: "",
                style: [],
                state: "",
            },
            copyBox: {
                class: "tutCopyBoxShort",
                content: [
                    {
                        id: "copy01",
                        class: "",
                        html: "Sometimes the 2nd and 3rd peaks move so close together that they look like just one peak.",
                    },
                    {
                        id: "copy02",
                        class: "",
                        html: "If you see just two peaks for 'r', focuses on getting the 2nd peak of the target line. In other words, try to move the 2nd peak closer to the crab.",
                    },
                ],
            },
            waveMini: [
                {
                    initPos: "top",
                    type: "waveBtn",
                    btnContent: waveMiniData.goodR2,
                    audio: false,
                    stateON: true,
                },

                {
                    initPos: "mid",
                    type: "switch",
                    btnContent: {
                        text: "switch wave order",
                        color: ["navy", "white"],
                    },
                    audio: false,
                    stateON: true,
                },

                {
                    initPos: "bottom",
                    type: "waveBtn",
                    btnContent: waveMiniData.goodR3,
                    audio: false,
                    stateON: false,
                },
            ], // end waveMini
            waveBox: {
                waveLive: false,
                defaultDraw: "graphic", //"img" ]] "graphic";
                //imgUrls: [ { url: "p5s4a" }, { url: "p5s4b" }, ],
                waveGraphics: [
                    {
                        bgPeaks: bgPeaksData.goodR2,
                        model: waveGraphData.goodR2, style: 'filled', top: false,
                        diagram: diagrams.goodR2, showDiagram: true,
                    },
                    {
                        bgPeaks: bgPeaksData.goodR3,
                        model: waveGraphData.goodR3, style: 'filled', top: false,
                        diagram: diagrams.p5s3_goodR3, showDiagram: true,
                    },
                ],
                slider: { isVisible: false, }
            },
            postLeft: {
                idx: 0,
                bgClass: "ropeCrab",
                fzSign: false,
                signBig: [
                    {
                        labelFor: "goodR2_p3", // ---------------------
                        label: "3rd Peak",
                        value: "1629",
                        color: "navy",
                        tooltipText: "",
                    },
                    {
                        labelFor: "goodR3_p3", // ---------------------
                        label: "3rd Peak",
                        value: "1737",
                        color: "waveBlue",
                        tooltipText: "",
                    },
                ],
            },
            postRight: { fn: 'none', },
            character: {
                url: "str",
                top: 0,
                left: 0,
                height: 0,
                width: 0,
            }
        },
        {
            id: 'p5s5',
            nav: {
                title: "Step 5: 'R' Sounds > Now You Try",
                bubText: "",
                style: [],
                state: "",
            },
            copyBox: {
                // class: "",
                content: [
                    {
                        id: "copy01",
                        class: "",
                        html: "Now you try!",
                    },
                    {
                        id: "copy02",
                        class: "",
                        html: "Can you get the wave's 3rd peak to match the target line when you say 'r'?",
                    },
                ],
            },
            waveBox: {
                waveLive: true,
                slider: {
                    isVisible: true,
                    pos: 1737,
                }
            },
            postLeft: {
                idx: 0,
                bgClass: "ropeCrab",
                fzSign: true,
            },
            postRight: { fn: 'pausePlay', },
            character: {
                url: "str",
                top: 0,
                left: 0,
                height: 0,
                width: 0,
            }
        },
    ]
}

export default createTutorialData;

