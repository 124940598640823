import * as chainData from './data/chain.json';
import RefTable from './table/RefTable';
import { vowelMapping } from './GavtForm';

import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { Card, Button, Flex, Tag, Modal, message } from 'antd';
import { RetweetOutlined, LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons';

interface WordProps {
    refFreqArray: {
      vowel: string;
      freq: number;
    }[];
    currentVowelIdx: number;
    onNextVowel: () => void;
}

const reversedVowelMapping: Record<string, string> = Object.fromEntries(
    Object.entries(vowelMapping).map(([key, value]) => [value, key])
);

const Word: React.FC<WordProps> = ({ refFreqArray, currentVowelIdx, onNextVowel }) => {
    const [phraseIndex, setPhraseIndex] = useState(0);
    const [chainId, setChainId] = useState(1);
    const [phrases, setPhrases] = useState<string[]>([]);
    const [currentPhrase, setCurrentPhrase] = useState('');
    const [refOpen, setRefOpen] = useState(false);
    const navigate = useNavigate();
    const currentVowel = useMemo(() =>refFreqArray?.[currentVowelIdx] || { vowel: '', freq: 0 }, [refFreqArray, currentVowelIdx]);

    useEffect(() => {
        if (!refFreqArray || refFreqArray.length === 0 || currentVowelIdx >= refFreqArray.length) {
          message.error('No vowel data provided. Please select vowels first. Redirecting to home page...');
          setTimeout(() => {
            navigate('/resonance/selection');
          }, 2000);
        }
      }, [refFreqArray, currentVowelIdx, navigate]);

    useEffect(() => {
        setChainId(1);
        setPhraseIndex(0);
    
        if (currentVowel.vowel) {
            const vowelData = chainData[currentVowel.vowel as keyof typeof chainData];
            const firstChain = vowelData[0];
            if (firstChain) {
                setPhrases(firstChain.phrases);
                setCurrentPhrase(firstChain.phrases[0]);
            }
        }
    }, [currentVowel]);

    useEffect(() => {
        if (currentVowel.vowel) {
            const vowelData = chainData[currentVowel.vowel as keyof typeof chainData];
            const chain = vowelData.find(chain => chain.chainId === chainId);
            if (chain) {
                setPhrases(chain.phrases);
                setCurrentPhrase(chain.phrases[0]);
            }
        }
    }, [currentVowel, chainId]);

    const handleMoreComplex = () => {
        if (phraseIndex < phrases.length - 1) {
            setPhraseIndex(phraseIndex + 1);
            setCurrentPhrase(phrases[phraseIndex + 1]);
        }
    };

    const handleLessComplex = () => {
        if (phraseIndex > 0) {
            setPhraseIndex(phraseIndex - 1);
            setCurrentPhrase(phrases[phraseIndex - 1]);
        }
    };

    const handleNextChain = () => {
        const newChainId = (chainId % 3) + 1;
        setChainId(newChainId);

        const newChain = chainData[currentVowel.vowel as keyof typeof chainData].find(chain => chain.chainId === newChainId);
        if (newChain) {
            setPhrases(newChain.phrases);
            setCurrentPhrase(newChain.phrases[0]);
            setPhraseIndex(0);
        }
    };
    
    return(
        <>
            <div>
            {/* <p>Vowels you just chose to practice: <Tag color='purple'>{refFreqArray.map(f => reversedVowelMapping[f.vowel] || f.vowel).join(', ')}</Tag></p> */}
            <p>Read the text and try to match the second peak (R2) to the slider. </p>
            </div>
            <p>Current target vowel is: <Tag color='purple'>{reversedVowelMapping[currentVowel.vowel] || currentVowel.vowel}</Tag></p>
            {/* <p>
                <a onClick={()=>setRefOpen(true)}>Reference Frequency Value </a>
                of <Tag color='purple'>{reversedVowelMapping[currentVowel.vowel] || currentVowel.vowel}</Tag>: <Tag color='yellow'>{currentVowel.freq} Hz </Tag>.
            </p> */}
            <Flex gap="small" style={{margin:'10px'}}>
                <Button icon={<RetweetOutlined />} onClick={onNextVowel} disabled={refFreqArray.length === 1}>Try a different vowel</Button>
                <Button icon={<RetweetOutlined />} onClick={handleNextChain}>Try a different phrase chain</Button>
            </Flex>
        
            <Card 
                hoverable
                title={`${currentPhrase}`}
                bordered={false}
                style={{ width: 300 }}
            >
                <p>{`${phrases[0]} `}</p>
            </Card>
            <Flex gap="small" style={{margin:'10px'}}>
                <Button icon={<LeftCircleOutlined />} onClick={handleLessComplex} disabled={phraseIndex === 0}>Less complex</Button>
                <Button type="primary" icon={<RightCircleOutlined />} onClick={handleMoreComplex} disabled={phraseIndex === phrases.length - 1}>More complex</Button>
            </Flex>
            <Modal
                title="Reference Frequency Values"
                centered
                open={refOpen}
                onOk={() => setRefOpen(false)}
                onCancel={() => setRefOpen(false)}
                width={1000}
                footer={[
                <Button key="submit" type="primary" onClick={() => setRefOpen(false)}>
                    OK
                </Button>
                ]}
            >
                <RefTable />
            </Modal>
        </>
    )
};

export default Word;