import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import getRefFreq from './getRefFreq';
import { vowelMapping } from './GavtSelection';

interface ResonanceMenuProps {
  formValues?: {
    vowels: string[];
    gender: 'man' | 'woman';
    shuffleOrder: boolean;
  };
  navigate?: (path: string, state?: any) => void;
}

const ResonanceMenu: React.FC<ResonanceMenuProps> = ({ formValues, navigate }) => {
  const location = useLocation();
  const routerNavigate = useNavigate();


  const menuItems = [
    { path: '/resonance', name: 'Tutorial' },
    { path: '/resonance/selection', name: 'Setup' },
    { path: '/resonance/practice', name: 'Practice' },  
  ];

  const activeNavigate = navigate || routerNavigate;

  const handlePracticeClick = async (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {

    if (location.pathname === '/resonance') {
      alert('You must select at least one vowel on Setup Page first');
      e.preventDefault();
      return;
    }

    if (location.pathname === '/resonance/selection') {
      if (formValues && formValues.vowels && formValues.vowels.length > 0) {
        const mappedVowels = formValues.vowels.map((vowel) => vowelMapping[vowel]);
        const frequencies = await getRefFreq(mappedVowels, formValues.gender, formValues.shuffleOrder);
        activeNavigate('/resonance/practice', { state: { frequencies } });
      } else {
        alert('You must select at least one vowel first');
        e.preventDefault();
      }

    }
  };


  return (
    <nav className="nav">
      {menuItems.map((item, index) => (
        <React.Fragment key={item.name}>
        <Link 
            to={item.path} 
            className={`link ${location.pathname === item.path ? 'active' : ''}`}
            onClick={item.path === '/resonance/practice' ? handlePracticeClick : undefined}
          >
            {item.name}
          </Link>
          {index < menuItems.length - 1 && <span className="separator">|</span>}
        </React.Fragment>
      ))}
    </nav>
  );
}

export default ResonanceMenu;