import React, { useState, useEffect, lazy, Suspense } from 'react'; //add lazy, suspense for tmp audio authority loading test
import { Layout, Menu, ConfigProvider, Button, Drawer, Avatar, Modal, Space, Radio, Slider, Switch, Dropdown } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { BrowserRouter as Router, Routes, Route, Link, useLocation, useNavigate } from 'react-router-dom';
// import About from './Route/About';
// import Sample from './Route/Sample';

import { FreqProvider } from './gavt/FreqContext';
import Gavt from './Route/Gavt'
import GavtSelection from './gavt/GavtSelection';
import GavtPractice from './gavt/GavtPractice';
import GavtCali from './gavt/GavtCali';
import GavtTutorial from './gavt/Tutorial/GavtTutorial';

// import SampleVolume from './Route/SampleVolume';
// import Assessment from './Route/Assessment';
// import Help from './Route/Help';
import Main from './Route/HomePage';
import logoImage from '../src/icon/new-icon-removebg-preview.png';
import './CSS/MainLayout.css';
import type { MenuProps } from 'antd';
import backgroundImage from './icon/HomePage-img.png';

import { Flex } from 'antd';
import { InfoCircleOutlined, CustomerServiceFilled } from '@ant-design/icons';
import { createFromIconfontCN } from '@ant-design/icons';
import ReactDOM from 'react-dom/client';
import ReactAudioPlayer from 'react-audio-player';
import { Tooltip } from 'antd';
import AudioPlayer from 'react-h5-audio-player';
import { useOptionsHooks } from './hooks/useOptionsHooks';

const PlayIcon = createFromIconfontCN({
  scriptUrl: '//at.alicdn.com/t/c/font_4566050_zpduwnlv2t.js',
});

interface CustomButtonProps {
  label: string;
  handleIconClick?: (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
}

const CustomButton: React.FC<CustomButtonProps> = ({ label, handleIconClick }) => {
  return (
    <Button
      style={{
        display: 'flex',
        alignItems: 'center',
      }}
      className='dropdownIcon'
      icon={
        <PlayIcon
          style={{
            fontSize: '1.7em',
          }}
          onClick={handleIconClick}
          type="icon-audioPlay"
        />
      }
    >
      {label}
    </Button>
  );
};

const { Header, Content, Footer } = Layout;
const defaultBackColor = "linear-gradient(to bottom, #d7d2e6, #c9d9f3)";
const defaultBackColor_2 = "url('/transvoice/HomePage-img.png')";

const marksRange = {
  50: '50Hz',
  600: '600Hz',
};

const marksBallPos = {
  0: '0%',
  1: '100%',
};

const marksVol = {
  50: '50dB',
  90: '90dB',
};

// Lazy load other modules
const About = lazy(() => import('./Route/About'));
const Sample = lazy(() => import('./Route/Sample'));
const SampleVolume = lazy(() => import('./Route/SampleVolume'));
const Assessment = lazy(() => import('./Route/Assessment'));
const Help = lazy(() => import('./Route/Help'));

const MainLayout = () => {
  const {
    gender,
    setGender,
    genderName,
    audioPlayerNew,
    audioKey,
    audioSrc,
    fetchAudioData,
    handleIconClick,
    itemsAvatar,
  } = useOptionsHooks();

  const [isClickListen, setIsClickListen] = useState<number>(0);

  const location = useLocation();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [openSetting, setOpenSetting] = useState(false);
  const [selectedItem, setSelectedItem] = useState('Settings');
  const [showNotesPar, setShowNotesPar] = useState(true);
  const [enableVol, setEnableVol] = useState(false);
  const [menuKey, setMenuKey] = useState<string>('4');
  const [backgroundImg, setBackgroundImg] = useState<string>(defaultBackColor);


  useEffect(() => {
    fetchAudioData();
  }, [genderName]);


  useEffect(() => {
    switch (menuKey) {
      case '4':
        setBackgroundImg(defaultBackColor_2);
        break;
      case '6':
        setBackgroundImg(defaultBackColor_2);
        break;
      case '8':
        setBackgroundImg(defaultBackColor);
        break;
      default:
        setBackgroundImg("transparent");
    }

    window.scrollTo(0, 0);
  }, [menuKey]);

  useEffect(() => {
    const pathIn = location.pathname;
    switch (pathIn) {
      case "/HomePage":
        setMenuKey('4');
        break;
      case "/Home":
        setMenuKey('7');
        break;
      case '/pitch':
        setMenuKey('3');
        break;
      case '/volume':
        setMenuKey('5');
        break;
      case pathIn.startsWith('/resonance') ? pathIn : undefined:
        setMenuKey('8');
        break;
      case '/assessment':
        setMenuKey('6');
        break;
      case '/about':
        setMenuKey('1');
        break;
      case '/Help':
        setMenuKey('2');
        break;
      default:
        setMenuKey('4');
    }
  }, [location.pathname]);

  const navigateToPitch = () => {
    setMenuKey('3');
    navigate('/pitch');
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const showDrawer = () => {
    setOpenSetting(true);
  };

  const closeDrawer = () => {
    setOpenSetting(false);
  };

  const onClickMenu = (e: any) => {
    setSelectedItem(e.key);
  };

  const onChangeHz = ({ target: { value } }: any) => {
    setShowNotesPar(value !== 'hz');
  };

  const onChangeRange = (rangeValue: number[]) => {
    // handle change for pitch display range
  };

  const onChangePosition = (positionValue: number) => {
    // handle change for pitch indicator horizontal position
  };

  const onChangeSpeed = (speedValue: number) => {
    // handle change for pitch indicator speed
  };

  const EnableVoluneMode = (e: boolean) => {
    setEnableVol(e);
  };

  const onChangeThreshold = (e: number) => {
    // handle change for threshold
  };

  const formatterdB = (value: number | undefined) => `${value} dB`;
  const formatterHz = (value: number | undefined) => `${value} Hz`;
  const formatterPos = (value: number | undefined) => `${value ? (value * 100).toFixed(0) : '0'}%`;

  // render profile page
  const renderContent = () => {
    switch (selectedItem) {
      case 'Profile':
        return <p>profile</p>;
      case 'Settings':
        return (<div>
          <Flex vertical gap="middle">
            <Space>
              <p># Model Selection: </p>

              {/* <Dropdown
                menu={{ items: itemsAvatar, selectable: true }}
                placement="bottom"
                trigger={["click"]}
                overlayClassName='custom-dropdown'
              // open={showDropdown}
              >

                <Space.Compact block className='dropdownButton'>
                  <Tooltip title="Play Audio">
                    <Button icon={
                      <PlayIcon
                        style={{
                          fontSize: '1.7em',
                        }}
                        onClick={handleIconClick}
                        type="icon-audioPlay"
                         />
                    }
                      className="dropdownIcon"
                    >

                    </Button>

                  </Tooltip>

                  <Tooltip title="Change Avatar">
                    <Button className="dropdownLastItem" onClick={handleDropdownClick}>
                      {genderName === "none" ? "Model Voice" : gender === "Female" ? genderName : 'Male01'}
                    </Button>
                  </Tooltip>


                </Space.Compact>


              </Dropdown> */}

              <Dropdown.Button
                menu={{ items: itemsAvatar }}
                placement="bottom"
                trigger={["click"]}
                overlayClassName='custom-dropdown'
                buttonsRender={([leftButton, rightButton]) => [
                  <Tooltip>
                    <Button
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                      onClick={handleIconClick}
                      icon={
                        <PlayIcon
                          style={{
                            fontSize: '1.7em',
                          }}

                          type="icon-audioPlay"
                        />
                      }
                    >
                      {genderName === "none" ? "Model Voice" : gender === "Female" ? genderName : 'Male01'}

                    </Button>

                  </Tooltip>

                  //   <Space.Compact block className='dropdownButton'>
                  //   <Tooltip title="Play Audio">
                  //     <Button icon={
                  //       <PlayIcon
                  //         style={{
                  //           fontSize: '1.7em',
                  //         }}
                  //         onClick={handleIconClick}
                  //         type="icon-audioPlay"
                  //          />
                  //     }
                  //       className="dropdownIcon"
                  //     >

                  //     </Button>

                  //   </Tooltip>

                  //   <Tooltip title="Change Avatar">
                  //     <Button className="dropdownLastItem" onClick={handleDropdownClick}>
                  //       {genderName === "none" ? "Model Voice" : gender === "Female" ? genderName : 'Male01'}
                  //     </Button>
                  //   </Tooltip>


                  // </Space.Compact>

                  ,

                  React.cloneElement(rightButton as React.ReactElement<any, string>, { loading: false }),

                ]}
              // open={showDropdown}
              >

              </Dropdown.Button>

            </Space>
            <br />
          </Flex>

        </div>)
      case 'Other':
        return <p>other</p>;
      default:
        return null;
    }
  };


  const menuClicked: MenuProps['onClick'] = (e) => {
    console.log(e.key);
    setMenuKey(e.key);
  };

  const exerciseMenu = (
    <Menu className="exercise-menu">
      <Menu.Item onClick={menuClicked} key="3">
        <Link to="/pitch">PITCH</Link>
      </Menu.Item>
      <Menu.Item onClick={menuClicked} key="5">
        <Link to="/volume">VOLUME</Link>
      </Menu.Item>
      <Menu.Item onClick={menuClicked} key="8">
        <Link to="/resonance">RESONANCE</Link>
      </Menu.Item>
    </Menu>
  );

  const itemsMenu = [
    {
      label: 'Profile',
      key: 'Profile',
    },
    {
      label: 'Settings',
      key: 'Settings',
    },
    {
      label: 'Other',
      key: 'Other',
    },
  ];

  return (
    <div
      className="LayoutBGP"
    >


      <Layout style={{
        ...(menuKey === '4'
          ? {
            backgroundImage: defaultBackColor_2,
            backgroundColor: 'linear-gradient(to bottom, #d7d2e6, #c9d9f3)', width: '200vw', backgroundSize: '10rem', maxWidth: '100vw', flex: 1,

          }
          : { backgroundColor: 'transparent' }
        ),

        isolation: "isolate",
        ...(menuKey === '6'
          ? {
            backgroundImage: defaultBackColor_2,
            backgroundColor: 'linear-gradient(to bottom, #d7d2e6, #c9d9f3)', width: '100vw', backgroundSize: '10rem', height: "1300px"

          }
          : { backgroundColor: 'transparent' }
        ),
      }} className="layout">
        <Header style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', background: 'transparent' }}>
          <div className="TVox">
            <img className="logoImg" src={logoImage} alt="Logo" />
          </div>
          <ConfigProvider
            theme={{
              token: {
                borderRadius: 0,
              },
              components: {
                Menu: {
                  itemColor: "rgb(78, 120, 195)",
                  horizontalItemSelectedBg: "rgb(134, 120, 169)",
                  horizontalItemSelectedColor: "white",
                },
              },
            }}
          >
            <Menu disabledOverflow={true} className="Menu" theme="light" mode="horizontal" selectedKeys={[menuKey]}>
              <Menu.Item key="4" onClick={menuClicked} className="menu-item">
                <Link to="/">HOME</Link>
              </Menu.Item>
              <Dropdown overlay={exerciseMenu} placement="bottom">
                <Menu.Item key="exercises" className="menu-item">
                  EXERCISES
                </Menu.Item>
              </Dropdown>
              <Menu.Item key="6" onClick={menuClicked} className="menu-item">
                <Link to="/assessment">ASSESMENT</Link>
              </Menu.Item>
              <Menu.Item key="2" onClick={menuClicked} className="menu-item">
                <Link to="/Help">HELP</Link>
              </Menu.Item>
              <Menu.Item key="1" onClick={menuClicked} className="menu-item">
                <Link to="/about">CONTACT</Link>
              </Menu.Item>
            </Menu>
          </ConfigProvider>
          <div className="userIcon">
            <Button
              shape="circle"
              type="default"
              icon={<UserOutlined />}
              onClick={showModal} // Show modal on click
            />
          </div>
        </Header>

        <Content className="ContentMain" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: "0rem", width: "100%", background: 'transparent', overflow: 'hidden' }}>
          <FreqProvider>
            <Suspense fallback={<div>Loading...</div>}>
              <Routes>
                <Route path="/" element={<Main goToSample={navigateToPitch} isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} showModal={showModal} handleCancel={handleCancel} />} />
                {/* ---------GAVT RELATED START--------*/}
                <Route path="/resonance/practice" element={<GavtPractice />} />
                <Route path="/resonance" element={<Gavt />} />
                <Route path="/resonance/selection" element={<GavtSelection />} />
                <Route path="/resonance/calibrate" element={<GavtCali />} />
                <Route path="/resonance/tutorial" element={<GavtTutorial />} />

                <Route path="/about" element={<About isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} showModal={showModal} handleCancel={handleCancel} />} />
                <Route path="/pitch" element={<Sample 
                
                  gender={gender}
                  setGender={setGender}
                  genderName={genderName}
                  // audioPlayerNew={audioPlayerNew}
                  audioKey={audioKey}
                  audioSrc={audioSrc}
                  fetchAudioData={fetchAudioData}
                  handleIconClick={handleIconClick}
                  itemsAvatar={itemsAvatar}
                
                
                />} />
                <Route path="/volume" element={<SampleVolume />} />
                {/* ---------GAVT RELATED END--------*/}
                <Route path="/assessment" element={<Assessment isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} showModal={showModal} handleCancel={handleCancel} />} />
                <Route path="/Help" element={<Help />} />
              </Routes>
            </Suspense>
          </FreqProvider>
        </Content>



        <Modal
        title="Settings"
        open={isModalOpen}
        className='AvatarModal'
        onCancel={() => setIsModalOpen(false)}
        footer={null}
      >
        <div style={{ textAlign: 'center' }}>
          <Avatar size={64} icon={<UserOutlined />} />
          <p>Name Name</p>

          <Menu disabledOverflow={true}
            onClick={onClickMenu}
            style={{ justifyContent: "center" }}
            mode="horizontal"
            selectedKeys={[selectedItem]}
            items={itemsMenu} />
          {renderContent()}

          <Button onClick={handleCancel}>Close</Button>
        </div>


      </Modal>
      {/* <ReactAudioPlayer
        className="audio-player-hidden"
        src={audioSrc}
        ref={audioPlayer}
        key={audioKey}
        onEnded={() => console.log('audio ended')}
        onError={(e) => console.log('audio error', e)}
      /> */}

        <AudioPlayer    
        autoPlayAfterSrcChange={false}
        src={audioSrc} 
        ref={audioPlayerNew}
        key={audioKey}
        style={{display: 'none'}}
        onEnded={() => console.log('audio ended')}
        onError={(e) => console.log('audio error', e)}
        />

        <Footer style={{ textAlign: 'center', overflow: 'hidden', background: 'transparent', fontSize: '20px', position: 'relative', width: '100%', color: 'blue', padding: '25px' }}>
          TruVox ©{new Date().getFullYear()} Created by the University of Cincinnati and New York University (PIs: Novak and McAllister)
        </Footer>
      </Layout>

    </div>
  );
}

export default MainLayout;
