import { Alert, Space, Button, Table } from 'antd'
import { useNavigate } from 'react-router-dom';

const Gavt: React.FC = () => {
  const navigate = useNavigate();

  return(
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    }}>
      <h2>Resonance</h2>
      <Space direction="vertical" style={{ width: '50%', marginBottom: '20px'}}>
        <Alert
          style={{ 
            backgroundColor: '#FFEFEF',
            borderColor: '#FFEFEF'
          }} 
          description={
            <>
              <div>
                Welcome! Here you can use a real-time display of your speech to work on resonance. NOTE: Please use the Chrome browser to access this module. Other browsers are not supported at this time.
                <br/>
                <br/>
                Resonance can be challenging to understand! We encourage you to watch at least the first three tutorial sections (video clips 3-5 minutes long) before you try out the software. 
                <br/>
                <br/>
                Our software works best with an external microphone plugged into your device, even if it's just a set of earbuds with an in-line microphone.
              </div>
            </>
          }
          type="info"
        />
      
      <Table
      bordered
      pagination={false}
      showHeader={false}
      dataSource={[
        { key: '1', part1: 'Tutorial: Getting Started', part2: 'Tutorial: Next Steps' },
        { key: '2', part1: <a href="">Part 1. What is resonance?</a>, part2: <a href="">Part 4. Strategies for resonance</a> },
        { key: '3', part1: <a href="">Part 2. Bright and dark resonance</a>, part2: <a href="">Part 5. Resonance chains</a> },
        { key: '4', part1: <a href="">Part 3. Modifying resonance</a>, part2: <a href="">Part 6. Navigating the app</a> },
      ]}
      columns={[
        {
          dataIndex: 'part1',
          key: 'part1',
          render: (text, record, index) => {
            if (index === 0) {
              return {
                children: text,
                props: {
                  colSpan: 1,
                },
              };
            }
            return text;
          },
        },
        {
          dataIndex: 'part2',
          key: 'part2',
          render: (text, record, index) => {
            if (index === 0) {
              return {
                children: text,
                props: {
                  colSpan: 1,
                },
              };
            }
            return text;
          },
        },
      ]}
    />
    </Space>
    <Button onClick={() => navigate('/resonance/selection')}>
      Skip Tutorial
    </Button>
    
    </div>


  )
}
export default Gavt;