/*
    These objects contain the svg data for creating and updating wave shapes.
    They are used by tutorialData.js to provide the drawing coords for specific tutorial content. 
*/

const waveMiniData = {
    eee: {
        id: "eee",
        text: "'eee' wave",
        color: ["navy"],
        waveModel: "m0,40V8.48c1.1.02,2.5-.11,3.88-.82,1.82-.93,2.28-2.18,3.47-2.16,1.02.02,1.37.95,2.88,2.32.57.52,1.13.94,1.7,1.31,1.14,2.36,2.33,4.02,3.68,5.91,1.48,2.07,2.95,3.97,5.72,5.46,3.28,1.76,6.64,3.12,9.72,4.16,16.15,2.25,28.97,2.97,28.97,2.97,28.71,1.61,34.23,1.62,40.43,1.4,0,0,22.83-.8,36.54-8.44,6.45-3.59,8.99-5.64,8.99-5.64,4.04-3.26,5.05-5.22,7.3-5.83.15-.01.31-.02.48-.03.66-.04,1.25-.02,1.83.03,2.02.61,3.92,2.77,7.87,5.12,4.37,2.6,8.01,3.13,12.59,3.79.8.12,2.57.37,4.91.47,1.82.18,4.46-.21,6.8-3.18.17-.21.61-.79,1.35-1.71,2.37-2.97,3.22-3.78,4.16-4.3.14-.07.25-.14.36-.2.31-.05.51-.08.73-.08.3,0,.57.03.82.08,1.02.66,1.75,2.53,3.04,3.99,1.21,1.36,2.22,1.59,2.65,1.68,1.22.25,2.94.61,4.55-1.78.39-.58,1-1.66,1.83-1.47.49.11.78.63,1,.94,1.62,2.37,3.48,5.17,3.76,5.98v21.53H0Z",
        lines: [ // x1,y1,x2,y2
            [7, 5, 7, 40],
            [154, 8, 154, 40],
            [194, 8, 194, 40],
        ],
    },
    ahh: {
        id: "ahh",
        text: "'ahh' wave",
        color: ["red"],
        waveModel: "m0,40V12.7c7.08-.14,12.88-.75,17.16-1.36,7.68-1.08,11.64-1.66,14.7-3.75,3.51-2.4,4.53-4.84,7.23-4.72,2.93.13,3.12,3.07,7.9,4.56,2.54.79,5.31.84,6.85.87,2.3.04,2.82-.2,4.09-.16,3.89.13,6.42,2.63,8.79,4.34,4.52,3.26,9.14,4.06,18.38,5.65,3.68.63,4.38.45,12.15,1.02,17.79,1.3,28.23,3.31,35.89,1.4,5.07-1.27,7.74-2.06,7.74-2.06,4.63-1.37,4.59-1.62,5.9-1.69,3.6-.21,4.53,1.59,10.24,3.23,1.98.57,4.7,1.33,8.23,1.33,3.72,0,6.92-.83,9.77-1.57,3.97-1.03,4.58-1.65,6.82-1.64,2.6,0,3.97.84,6.93,1.96,0,0,6.24,2.41,14.34,3.3,2.16.24,5.39.45,9.16.16v16.45H0Z",
        lines: [
            [38.4, 2, 38.4, 40],
            [59.6, 6.08, 59.6, 40],
            [146.75, 13.15, 146.75, 40],
        ],
    },
    ooo: {
        id: "ooo",
        text: "'ooo' wave",
        color: ["navy"],
        waveModel: "m0,40V9.86c1.42-.03,3.38-.22,5.5-1.1,3.93-1.64,4.35-3.92,6.84-4.33,3.29-.53,4.09,3.91,9.4,4.8,1.89.32,3.85.13,5.15,0,3.57-.35,4.2-1.16,6.1-.79,2.04.4,2.52,1.56,5.21,3.41,0,0,1.91,1.31,6.65,3.2,12.09,4.8,40.93,6.45,40.93,6.45,17.17.99,29.51,1.67,44.23,1.55,2.3-.02,4.9-.06,8.78.02,12.5.27,15.27,1.33,27.97,2.12,13.2.82,19.8,1.23,25.26.27,1.65-.29,5.8-1.14,11.59-.97,3.63.1,6.51.57,8.4.94v14.56H0Z",
        lines: [ // x1,y1,x2,y2
            [12.01, .9, 12.01, 40],
            [32.74, 4.67, 32.74, 40],
            [203.26, 22.1, 203.26, 40],
        ],
    },
    oooNY: {
        id: "oooNY",
        text: "New York 'ooo'",
        color: ["white"],
        waveModel: "m0,39.95V8.86c1.88.3,6.06.75,10.16-.94,1.36-.56,2.99-1.3,4.47-2.88,1.31-1.4,1.47-2.31,2.27-2.46,1.6-.29,2.92,3.01,5.23,5.84,4.24,5.2,10.28,6.87,12.56,7.5,2.35.65,6.93,1.56,12.19.57,3.52-.66,3.88-1.52,6.54-1.51,3.59.01,5.12,1.59,10.19,3.65,0,0,4.2,1.71,9.43,2.82,5.23,1.12,7.45.59,14.13.83,9.02.32,8.44,1.4,16.82,1.77,8.29.37,9.53-.66,18.25-.59,6.87.06,7.91.71,17.58,1.53,14.73,1.26,26.25.93,31.45.71,11.15-.49,16.73-.73,19.79-1.88,0,0,8.08-3.05,13.06-2.71.14,0,1.77.13,4.1.31,1.61.12,2.86.23,3.81.31v18.28l-212-.05Z",
        lines: [ // x1,y1,x2,y2
            [17.25, 1.22, 17.25, 40],
            [53.97, 13.91, 53.97, 40],
        ],
    },
    oooCA: {
        id: "oooCA",
        text: "California 'ooo'",
        color: ["navy"],
        waveModel: "m0,39.94V11.68c1.88.21,4.46.24,7.07-.94,4.58-2.08,5.56-6.23,8.99-6.4,1.48-.08,2.17.66,5.32,2.64.44.27,3.72,1.99,10.27,5.43,6.04,3.17,9.06,4.76,12.26,5.82,6.79,2.26,12.52,2.21,15.37,2.17,7.88-.13,13.8-.22,17.94-4.3,2.75-2.71,3.32-5.8,5.53-5.83,2.02-.03,2.76,2.53,5.27,5.18,5.02,5.31,12.48,6.11,18.12,6.72,5.37.58,5.32-.46,12.18-.03,6.26.39,9.45,1.45,14.38,2.42,10.39,2.04,18.77,1.74,25.91,1.49,10.42-.37,15.63-.55,21.93-2.43,2.53-.76,6.21-2.07,11.5-2.21,1.67-.05,3.17.04,9.13.64,4.44.45,8.15.87,10.81,1.18.04,5.59-.04,11.19,0,16.78l-211.99-.06Z",
        lines: [ // x1,y1,x2,y2
            [16.15, 2.87, 16.15, 40],
            [82.67, 8.67, 82.67, 40],
            [192.26, 20.26, 192.26, 40],
        ],
    },
    goodR2: {
        id: "goodR2",
        text: "good 'r' with 2 peaks",
        color: ["navy"],
        waveModel: "m212,40H0V10.82c6.39.81,11.01-.23,14.13-1.41,5.19-1.96,7.66-4.84,11.78-4.24,2.96.44,3.71,2.23,7.07,4.71,8.31,6.14,18.51,6.12,24.03,6.12,5.68,0,8.52,0,11.78-1.41,1.86-.8,3.58-1.99,6.12-1.88,2.94.13,5.03,1.87,5.65,2.35,7.8,6.01,37.1,8.17,57,8,3.9-.03,7.32-.07,14.17-.44,9.7-.53,14.46-1.32,26.82-1.44,7.73-.08,19.25.17,33.45,1.88v16.94Z",
        lines: [ // x1,y1,x2,y2
            [24.89, 2.41, 24.89, 40],
            [75.14, 9.87, 75.14, 40],
            [178.79, 19.27, 178.79, 40],
        ],
    },
    goodR3: {
        id: "goodR3",
        text: "good 'r' with 3 peaks",
        color: ["white"],
        waveModel: "m0,40V9.85c3.3.66,6.69,1.21,11.03,1.07,4.63-.14,8.24-1.02,9.88-1.47,4.1-1.13,3.88-1.76,5.96-2.12,6.47-1.13,8.8,4.9,17.43,6.2,2.02.3,7.65,1.15,12.08-1.96,4.15-2.92,4.25-7.22,6.93-7.38,3.24-.2,4.15,6.02,8.62,6.6,3.54.46,5.06-3.17,8.61-2.59,2.73.45,3.47,2.87,6.15,5.73,5.66,6.04,13.69,7.35,17.7,8.01,5.83.95,5.62-.64,14.66-.09,7.27.45,7.88,1.51,14.9,2.12,7.31.64,7.52-.43,18.68-.46,10.86-.03,10.38.98,19.42.7,10.31-.33,10.58-1.64,17.22-.93,4.84.51,7.38,1.51,13.82,1.81,3.82.18,7.04.01,8.92-.16v15.08H0Z",
        lines: [ // x1,y1,x2,y2
            [28.75, 6.5, 28.75, 40],
            [63.3, 1.86, 63.3, 40],
            [80.16, 7.07, 80.16, 40],
        ],
    },
    poorR: {
        id: "poorR",
        text: "not-so-good 'r'",
        color: ["spruce"],
        waveModel: "m0,40V14.56c8.01-.29,14.45-1.47,18.86-2.55,6.75-1.65,9.07-3.05,13.59-2.39,5.39.78,5.68,3.27,13.09,5.4,3.25.93,7.85,2.25,13.22,1.19,5.99-1.18,6.03-3.96,10.49-4.24,5.41-.34,7.5,3.61,15.28,6.93,5.24,2.24,9.52,2.68,14.42,3.18,12.97,1.33,16.85-2.71,29.67-2.58,8.01.08,5.53,1.65,18.89,3.1,14.22,1.55,30.91,3.37,37.23-4.14.88-1.05,2.84-3.8,5.72-3.91,2.46-.09,4.16,1.78,5.34,2.79,2.75,2.35,7.73,4.72,16.21,4.63v18.02H0Z",
        lines: [  // x1,y1,x2,y2
            [32.11, 8.49, 32.11, 40],
            [128.83, 18.62, 128.83, 40],
            [190.19, 14.29, 190.19, 40],
            [70.63, 9.39, 70.63, 40]
        ],
    },
}


/* ==========================================================
    These are the tapered peak lines (polygons) that display behind the waveGraphs. */
const bgPeaksData = {
    eee: { // DONE
        polygonClass: "navy",
        polygons: [
            {
                label: "1st Peak",
                fzVal: 138, // (23) * 4600)/768,
                points: "36 54.5 39 324 33 324 36 54.5 36 54.5"
            },
            {
                label: "2nd Peak",
                fzVal: 3330, // (556 * 4600)/768,
                points: "427 56.5 430 323.5 424 323.5 427 56.5 427 56.5"
            },
            {
                label: "3rd Peak",
                fzVal: 4199, // (701 * 4600)/768,
                points: "619 56.5 622 324.5 616 324.5 619 56.5 619 56.5",
            },
        ],
    },
    ahh: { //DONE
        polygonClass: "red",
        polygons: [
            {
                label: "1st Peak",
                //fzVal: 839, // (140 * 4600)/768,
                points: "170.45 55 173.45 325 167.45 325 170.45 55 170.45 55",
            },
            {
                label: "2nd Peak",
                //fzVal: 1282, // (214 * 4600)/768,
                points: "249.41 54 252.41 324 246.41 324 249.41 54 249.41 54",
            },
            {
                label: "3rd Peak",
                //fzVal: 3192, // (533 * 4600)/768,
                points: "517.27 54 520.27 324 514.27 324 517.27 54 517.27 54",
            },
        ],
    },
    ooo: { //DONE
        polygonClass: "gold600",
        polygons: [
            {
                label: "1st Peak",
                //fzVal: 282, // (47 * 4600)/768,
                points: "44.55 54 47.55 324 41.55 324 44.55 54 44.55 54",
            },
            {
                label: "2nd Peak",
                //fzVal: 713, // (119 * 4600)/768,
                points: "167.59 54 170.59 324 164.59 324 167.59 54 167.59 54",
            },
            {
                label: "3rd Peak",
                //fzVal: 4420, // (738 * 4600)/768,
                points: "633.2 54 636.2 324 630.2 324 633.2 54 633.2 54",
            },
        ],
    },
    oooNY: { // DONE
        polygonClass: "white",
        polygons: [
            {
                label: "1st Peak",
                // fzVal: 341,
                points: "61.98 54 64.98 324 58.98 324 61.98 54 61.98 54"
            },
            {
                label: "2nd Peak",
                // fzVal: 1174,
                points: "195.98 54 198.98 324 192.98 324 195.98 54 195.98 54",
            },
        ],
    },
    oooCA: {  // DONE
        polygonClass: "navy",
        polygons: [
            {
                label: "1st Peak",
                // fzVal: 0, //(# * 4600)/768,
                points: "57.1 54 60.1 324 54.1 324 57.1 54 57.1 54",
            },
            {
                label: "2nd Peak",
                // fzVal: 0, //(# * 4600)/768,
                points: "299.91 54 302.91 324 296.91 324 299.91 54 299.91 54",
            },
            {
                label: "3rd Peak",
                // fzVal: 0, //(# * 4600)/768,
                points: "694.6 54 697.6 324 691.6 324 694.6 54 694.6 54",
            },
        ],
    },
    goodR3: { // DONE
        polygonClass: "white",
        polygons: [
            {
                label: "1st Peak",
                // fzVal: 0, //(# * 4600)/768,
                points: "100 323.5 103 53.5 106 323.5 100 323.5 100 323.5",
            },
            {
                label: "2nd Peak",
                // fzVal: 0, //(# * 4600)/768,
                points: "225 324 228 54 231 324 225 324 225 324",
            },
            {
                label: "3rd Peak",
                // fzVal: 0, //(# * 4600)/768,
                points: "286 324 289 54 292 324 286 324 286 324",
            },
        ],
    },
    goodR2: { // DONE
        polygonClass: "navy",
        polygons: [
            {
                label: "1st Peak",
                fzVal: 0, //(89 * 4600)/768,
                points: "86.42 324 89.42 54 92.42 324 86.42 324 86.42 324",
            },
            {
                label: "2nd Peak",
                fzVal: 0, //(272 * 4600)/768,
                points: "268.6 324 271.6 54 274.6 324 268.6 324 268.6 324",
            },
            {
                label: "3rd Peak",
                fzVal: 0, //(272 * 4600)/768,
                points: "647.37 324 650.37 54 653.37 324 647.37 324 647.37 324",
            },
        ],
    },
    poorR: { // DONE
        polygonClass: "spruce",
        polygons: [
            {
                label: "1st Peak",
                fzVal: 0, //(# * 4600)/768,
                points: "107.15 323.5 110.15 53.5 113.15 323.5 107.15 323.5 107.15 323.5",
            },
            {
                label: "2nd Peak",
                fzVal: 0, //(# * 4600)/768,
                points: "250.33 324 253.33 54 256.33 324 250.33 324 250.33 324",
            },
            {
                label: "3rd Peak",
                fzVal: 0, //(# * 4600)/768,
                points: "459.1 324 462.1 54 465.1 324 459.1 324 459.1 324",
            },
        ],
    },
}

/* ==========================================================
    bubs ['text (str)', 'transform (str)', 'class (str)', bub {cx, cy, r, 'style']
    lines: [ x1, y1, x2, y2, 'style' ],
    rects: [  x, y, w, h, 'style' ],
*/

const diagrams = {
    p1s2: { //done
        text: [
            {
                text: "peak",
                transform: "translate(69.16 90.39)",
                class: "diagramText fill-navy",
            },
            {
                text: "peak",
                transform: "translate(178.29 90.53)",
                class: "diagramText fill-navy",
            },
            {
                text: "peak",
                transform: "translate(260.66 90.53)",
                class: "diagramText fill-navy",
            },
        ],
        polygons: [
            {
                label: "1st Peak",
                class: "fill-white",
                points: "63 324 66 54 69 324 63 324 63 324",
            },
            {
                label: "2nd Peak",
                class: "fill-white",
                points: "172 324 175 54 178 324 172 324 172 324",
            },
            {
                label: "3rd Peak",
                class: "fill-white",
                points: "256.5 169 258 54 259.5 169 256.5 169 256.5 169",
            },
        ],
    },
    p1s3: {
        text: [
            {
                text: "peaks with lines",
                transform: "translate(69.16 90.39)",
                class: "diagramText fill-navy",
            },
            {
                text: "peak without lines",
                transform: "translate(261.66 141.82)",
                class: "diagramText fill-navy",
            },
        ],
        polygons: [
            {
                label: "1st Peak",
                class: "fill-white",
                points: "63 324 66 54 69 324 63 324 63 324",
            },
            {
                label: "2nd Peak",
                class: "fill-white",
                points: "172 324 175 54 178 324 172 324 172 324",
            },
            {
                label: "3rd Peak",
                class: "fill-white",
                points: "256.5 169 258 54 259.5 169 256.5 169 256.5 169",
            },
        ],
    },
    eee: {
        bubs: [
            {
                text: "1",
                transform: "translate(30.53 82.07)",
                class: "navy",
                circle: {
                    cx: 35.9,
                    cy: 73.67,
                    r: 18,
                    style: "fill-white",
                },
            },
            {
                text: "2",
                transform: "translate(419.7 81.07)",
                class: "navy",
                circle: {
                    cx: 427,
                    cy: 73.67,
                    r: 18,
                    style: "fill-white",
                },
            },
            {
                text: "3",
                transform: "translate(612.66 81.07)",
                class: "navy",
                circle: {
                    cx: 619,
                    cy: 73.67,
                    r: 18,
                    style: "fill-white",
                },
            },
        ],
    },

    p5s1: {
        bubs: [
            {
                text: "1",
                transform: "translate(97.63 96.51)",
                class: "waveBlue",
                circle: {
                    cx: 104,
                    cy: 89,
                    r: 18,
                    style: "fill-blueSky",
                },
            },
            {
                text: "2",
                transform: "translate(221.57 96.51)",
                class: "waveBlue",
                circle: {
                    cx: 228,
                    cy: 89,
                    r: 18,
                    style: "fill-blueSky",
                },
            },
            {
                text: "3",
                transform: "translate(282.74 96.51)",
                class: "waveBlue",
                circle: {
                    cx: 289,
                    cy: 89,
                    r: 18,
                    style: "fill-white",
                },
            },
        ],
        // lines: [ [ x1, y1, x2, y2, 'style' ], ],
        lines: [
            [242.5, 88.5, 263.5, 88.5, "stroke-waveBlue"]
        ],
        //rects: [  x, y, w, h, 'style' ],
        rects: [
            [241, 78.75, 3, 19.5, "fill-waveBlue"],
            [262, 78.75, 3, 19.5, "fill-waveBlue"]
        ],
    },
    p5s2_poorR: { // p5s2a
        bubs: [
            {
                text: "2",
                transform: "translate(245.57 97.43)",
                class: "spruce",
                circle: {
                    cx: 252,
                    cy: 89,
                    r: 18,
                    style: "fill-blueSky",
                },
            },
            {
                text: "3",
                transform: "translate(455.74 97.43)",
                class: "spruce",
                circle: {
                    cx: 462,
                    cy: 89,
                    r: 18,
                    style: "fill-white",
                },
            }
        ],
        lines: [
            [271, 89.46, 437.5, 89.46, "stroke-spruce"]
        ],
        rects: [
            [269.5, 79.71, 3, 19.5, "fill-spruce"],
            [436, 79.71, 3, 19.5, "fill-spruce"],
        ],
    },
    p5s2_goodR3: { // p5s2b
        bubs: [
            {
                text: "2",
                transform: "translate(221.57 96.51)",
                class: "waveBlue",
                circle: {
                    cx: 228,
                    cy: 89,
                    r: 18,
                    style: "fill-blueSky",
                },
            },
            {
                text: "3",
                transform: "translate(282.74 96.51)",
                class: "waveBlue",
                circle: {
                    cx: 289,
                    cy: 89,
                    r: 18,
                    style: "fill-white",
                },
            },
        ],
        lines: [
            [242.5, 88.5, 263.5, 88.5, "stroke-waveBlue"]
        ],
        rects: [
            [241, 78.75, 3, 19.5, "fill-waveBlue"],
            [262, 78.75, 3, 19.5, "fill-waveBlue"]
        ],
    },
    p5s3_goodR3: {
        bubs: [
            {
                text: "3",
                transform: "translate(282.74 96.51)",
                class: "waveBlue",
                circle: {
                    cx: 289,
                    cy: 89,
                    r: 18,
                    style: "fill-white",
                },
            },
        ]
    },
    p5s3_poorR: {
        bubs: [
            {
                text: "3",
                transform: "translate(455.74 97.43)",
                class: "spruce",
                circle: {
                    cx: 462,
                    cy: 89,
                    r: 18,
                    style: "fill-white",
                },
            }
        ],
    },
    goodR2: {
        bubs: [
            {
                text: "2",
                transform: "translate(264.28 98.04)",
                class: "navy",
                circle: {
                    cx: 271.59,
                    cy: 89,
                    r: 18,
                    style: "fill-white",
                },
            },
            {
                text: "3",
                transform: "translate(265.57 128.77)",
                class: "navy",
                circle: {
                    cx: 271.59,
                    cy: 120.57,
                    r: 18,
                    style: "fill-white",
                },
            },
            {
                text: "4",
                transform: "translate(641.22 98.04)",
                class: "navy",
                circle: {
                    cx: 650.59,
                    cy: 89,
                    r: 18,
                    style: "fill-blueSky",
                },
            },
        ],
    },
}


const waveGraphData = {
    p1: {
        id: "p1",
        color: "waveBlue", // fill for wave model
        waveModel: "m747,255.72c-15.14-1.42-21.13-6.2-32.52-8.68-15.61-3.39-16.26,2.9-40.52,4.47-15.92,1.03-19.3-1.44-30.71-2.68-7.96-.42-17.8-.7-31.14-.66-54.5.16-55.56,5.3-91.26,2.23-34.32-2.96-37.3-8.05-72.8-10.19-44.17-2.67-43.15,5-71.62.42-19.6-3.16-58.84-9.48-86.47-38.49-2.9-3.05-5.34-5.99-7.51-8.74-7.67-4.69-12.16-8.27-22.55-9.08-17.35-1.35-24.75,7.08-42.06,6.02-21.85-1.34-26.29-15.77-42.11-15.31-13.09.38-13.59,10.36-33.87,17.14-1.35.45-2.72.86-4.1,1.25-4.02,2.29-8.18,4-12.35,5.26-8.42,5.28-16.9,2.68-20.49,1.58-4.48-1.38-8.24-3.86-11.54-6.83-11.67-5.08-17.62-13.14-29.49-11.09-3.19.55-4.07,1.35-6.96,2.57-1.71.72-3.45,1.37-7.06,2.35-7.33,2-20.98,1.73-29.25,3.27-5.05.94-12.32,3.35-20.6,10.21.34,24.27.55,48.71.62,73.3h-.62v50h768v-69.03c-4.44.81-12.01,1.6-21,.75Z",
        lines: [
            [66.95, 182.5, 65.95, 324],    // peakLine 1    
            [174.75, 175.5, 174.75, 324],  // peakLine 2
        ],
        lineColor: "white",
        topPath: "",
    },
    eee: { // done
        id: "eee",
        color: "waveBlue",
        waveModel: "m710,249.4c-17.86-3.2-28.83-14.42-41-27.31-33.37-35.32-34.64-68.29-49-68.71-8.42-.24-13.61,10.93-22,22.9-24.69,35.21-74.38,73.34-105,67.82-40.94-7.38-48.68-92.95-67-90.73-4.62.56-6.56,6.46-12,20.26-16.65,42.26-24.98,63.38-35,73.99-15.74,16.66-38.62,19.47-81,24.66-17.58,2.16-56.35,6.55-104-5.29-35.84-8.9-74.32-18.69-98-48.45-21.93-27.56-44.92-88.73-47-94.25-1.37-3.64-6.99-18.03-13-18.5-4.7-.37-7.34,7.96-16,19.38-7.05,9.3-14.53,15.95-20,20.26v178.54h768v-92.22c-6.01,4.68-30.31,22.58-58,17.62Z",
        lineColor: "navy",
        lines: [
            [36, 105, 36, 324],
            [426.5, 153.5, 426.5, 324],
            [619.5, 150, 619.5, 324],
        ],
        topPath: "m768,231.94c-6.01,4.68-30.31,22.58-58,17.62-17.86-3.2-28.83-14.42-41-27.31-33.37-35.32-34.64-68.29-49-68.71-8.42-.24-13.61,10.93-22,22.9-24.69,35.21-74.38,73.34-105,67.82-40.94-7.38-48.68-92.95-67-90.73-4.62.56-6.56,6.46-12,20.26-16.65,42.26-24.98,63.38-35,73.99-15.74,16.66-38.62,19.47-81,24.66-17.58,2.16-56.35,6.55-104-5.29-35.84-8.9-74.32-18.69-98-48.45-21.93-27.56-44.92-88.73-47-94.25-1.37-3.64-6.99-18.03-13-18.5-4.7-.37-7.34,7.96-16,19.38-7.05,9.3-14.53,15.95-20,20.26",
    },
    ahh: { // done
        id: "ahh",
        color: "waveBlue",
        waveModel: "m739,282c-15.13-3.79-34.94-11.58-36-12-8.56-3.39-13.8-5.84-22-6-8.14-.16-14.13,2.03-17,3-19.92,6.71-43.35,6.16-53,5-23.01-2.78-38.94-17.66-50-28-26.81-25.05-29.63-49.35-44-49-7.43.18-10.48,6.76-22,18,0,0-23.28,22.71-50,33-40.16,15.47-102.14-26.02-133-68-33.63-45.75-46-86-46-86-4.96-16.15-8.38-33.33-16-34-13.41-1.17-19.94,50.54-45,54-14.3,1.97-19.31-13.89-34-12-18.32,2.35-17.18,27.87-46,53-5.12,4.47-29.58,25.79-58,23-5.04-.49-14.79-1.45-24-8-8.17-5.81-9.13-11.19-17-18-4.85-4.19-12.85-9.39-26-12v185.39c256,.19,512,.37,768,.56,0-12.65,0-25.3,0-37.95-12.46-.41-22.28-2.32-29-4Z",
        lineColor: "red",
        lines: [
            [170.5, 99.5, 170.5, 324],
            [249.5, 59, 249.5, 324],
            [517.5, 190, 517.5, 324], //197.5
        ],
        topPath: "m768,286c-12.46-.41-22.28-2.32-29-4-15.13-3.79-34.94-11.58-36-12-8.56-3.39-13.8-5.84-22-6-8.14-.16-14.13,2.03-17,3-19.92,6.71-43.35,6.16-53,5-23.01-2.78-38.94-17.66-50-28-26.81-25.05-29.63-49.35-44-49-7.43.18-10.48,6.76-22,18,0,0-23.28,22.71-50,33-40.16,15.47-102.14-26.02-133-68-33.63-45.75-46-86-46-86-4.96-16.15-8.38-33.33-16-34-13.41-1.17-19.94,50.54-45,54-14.3,1.97-19.31-13.89-34-12-18.32,2.35-17.18,27.87-46,53-5.12,4.47-29.58,25.79-58,23-5.04-.49-14.79-1.45-24-8-8.17-5.81-9.13-11.19-17-18-4.85-4.19-12.85-9.39-26-12",
    },
    ooo: {
        id: "ooo",
        color: "waveBlue",
        waveModel: "m768,287c-21.13,1.53-47.97-.36-71-16-44.73-30.38-45.42-92.44-64-92-15.8.38-12.13,45.2-46,72-21.22,16.79-35.16,9.08-106,18-73.97,9.31-77.8,20.11-122,18-25.6-1.22-58.6-2.8-93-22-31.31-17.48-59.89-33.43-75-58-2.71-4.41-9.93-17.13-22-19-6.65-1.03-10.47,1.9-19,4,0,0-13.8,3.39-35-1-38.13-7.89-50.82-62.13-71-58-7.42,1.52-7.6,9.23-25,19-7.67,4.31-14.54,6.69-19,8v164h768v-37Z",
        lineColor: "gold500",
        lines: [
            [44.55, 131.5, 44.55, 324],
            [167.59, 176, 167.59, 324],
            [633.31, 180.5, 633.31, 324]
        ],
        topPath: "m0,160c4.46-1.31,11.33-3.69,19-8,17.4-9.77,17.58-17.48,25-19,20.18-4.13,32.87,50.11,71,58,21.2,4.39,35,1,35,1,8.53-2.1,12.35-5.03,19-4,12.07,1.87,19.29,14.59,22,19,15.11,24.57,43.69,40.52,75,58,34.4,19.2,67.4,20.78,93,22,44.2,2.11,48.03-8.69,122-18,70.84-8.92,84.78-1.21,106-18,33.87-26.8,30.2-71.62,46-72,18.58-.44,19.27,61.62,64,92,23.03,15.64,49.87,17.53,71,16",
    },
    oooNY: {
        id: "oooNY",
        color: "waveBlue",
        waveModel: "m754.21,230.5c-8.46-.66-14.35-1.11-14.85-1.15-18.05-1.26-47.31,10.13-47.31,10.13-11.07,4.31-31.28,5.22-71.68,7.05-18.84.85-60.58,2.05-113.92-2.64-35.04-3.08-38.79-5.52-63.68-5.73-31.58-.26-36.1,3.57-66.11,2.2-30.35-1.38-28.24-5.41-60.93-6.61-24.21-.88-32.25,1.08-51.19-3.1-18.95-4.18-34.15-10.56-34.15-10.56-18.37-7.71-23.91-13.6-36.91-13.65-9.64-.04-10.92,3.18-23.69,5.66-19.04,3.7-35.63.3-44.15-2.14-8.25-2.36-30.12-8.61-45.49-28.06-8.38-10.61-13.17-22.95-18.96-21.85-2.9.55-3.45,3.95-8.21,9.19-5.36,5.9-11.26,8.67-16.18,10.77-14.87,6.33-29.98,4.66-36.81,3.52v140.45h768v-92.35c-3.41-.29-7.97-.69-13.79-1.15Z",
        lineColor: "white",
        lines: [
            [61.99, 160, 61.99, 324],
            [195.99, 206.5, 195.99, 324],
        ],
        topPath: "m.24,185.48c25.37,4.11,37.84-1.62,44.53-7.47,8.61-7.53,10.59-17.85,17.01-17.94,6.51-.08,9.62,10.46,16.01,19.93,17.07,25.27,53.8,37.19,81.05,34.88,20-1.7,20.9-9.87,38.02-7.97,17.12,1.9,20.29,10.52,47.03,18.93,14.66,4.62,26.67,6.1,34.02,6.98,20.59,2.45,26.78,0,47.03,1,23.91,1.18,25.82,5.12,48.53,7.47,26.36,2.73,35.64-1.35,63.54-2.49,28.22-1.15,28.64,2.62,77.05,5.98,46.38,3.22,79.93,2.12,114.07,1,48.16-1.58,59.18-4.55,64.04-5.98,12.33-3.61,23.21-8.46,41.03-9.96,14.75-1.24,27.02.42,35.02,1.99",
    },
    oooCA: {
        id: "oooCA",
        color: "waveBlue",
        waveModel: "m767.96,288.5c-.11-19.43.13-38.86,0-58.29-9.67-1.12-23.09-2.63-39.18-4.27-21.59-2.19-27.01-2.48-33.06-2.32-19.15.52-32.49,5.26-41.67,8.01-22.83,6.83-41.7,7.49-79.45,8.82-25.86.91-56.23,1.97-93.86-5.4-17.86-3.5-29.43-7.35-52.1-8.76-24.84-1.55-24.68,2.22-44.11.12-20.41-2.21-47.46-5.13-65.63-24.36-9.09-9.62-11.77-18.88-19.08-18.77-8,.12-10.08,11.32-20.03,21.12-14.99,14.76-36.44,15.11-65,15.57-10.33.17-31.1.33-55.7-7.85-11.6-3.85-22.56-9.6-44.43-21.1-23.71-12.46-35.62-18.7-37.2-19.69-11.43-7.18-13.92-9.83-19.28-9.55-12.42.64-15.97,15.66-32.57,23.2-9.45,4.3-18.79,4.17-25.61,3.41v135.61h768v-35.5h-.04Z",
        lineColor: "navy",
        lines: [
            [57.1, 162, 57.1, 324],
            [299.91, 183, 299.91, 324],
            [694.6, 223.75, 694.6, 324],
        ],
        topPath: "m-1,190.83c5.67.27,14.15-.09,23.03-3.99,17.88-7.85,21.22-22.93,34.04-23.96,6.94-.56,10.93,3.46,22.03,10.98,13.83,9.38,29.43,15.86,44.06,23.96,40.52,22.44,83.67,23.89,86.11,23.96,24.3.75,53.18,1.63,71.09-16.97,9.06-9.41,10.82-19.13,19.02-19.97,11.65-1.18,14.14,19.2,35.05,30.95,15.15,8.51,30,9.79,59.08,11.98,27.31,2.06,32.08-3.28,55.07,1,10.72,2,13.66,3.75,31.04,6.99,16.78,3.13,29.97,4.46,36.05,4.99,8.95.79,21.2,1.27,47.06,1,25.42-.26,46.04-.48,71.09-3.99,34.37-4.82,36.25-12.97,62.08-12.98,12.65,0,28.89,1.71,41.05,3,12.89,1.36,23.57,2.86,31.04,3.99",
    },

    goodR3: {
        id: "goodR3",
        color: "waveBlue",
        waveModel: "m735.68,232.96c-23.31-1.07-32.52-4.68-50.05-6.54-24.02-2.56-25.03,2.19-62.37,3.37-32.74,1.04-31.01-2.64-70.35-2.52-40.41.12-41.2,4-67.67,1.68-25.45-2.23-27.66-6.07-53.98-7.68-32.75-2.01-32,3.77-53.11.31-14.53-2.38-43.63-7.14-64.12-29.01-9.72-10.37-12.39-19.14-22.3-20.76-12.86-2.11-18.35,11.05-31.19,9.39-16.2-2.1-19.49-24.61-31.22-23.89-9.71.59-10.07,16.17-25.12,26.74-16.02,11.26-36.43,8.19-43.75,7.09-31.28-4.71-39.72-26.56-63.14-22.45-7.54,1.32-6.71,3.57-21.58,7.68-5.91,1.63-18.99,4.79-35.77,5.31-15.73.49-28.01-1.51-39.96-3.89v146.23h768v-91.61c-6.83.61-18.48,1.2-32.32.57Z",
        lineColor: "white",
        lines: [
            [103, 169.7, 103, 324],  // peak1
            [228, 157.5, 228, 324],  // peak2
            [289, 171, 289, 324],   // peak3
        ],
        topPath: "",
    },
    poorR: {
        id: "poorR",
        color: "waveBlue",
        waveModel: "m709.26,192.95c-4.28-3.66-10.45-10.45-19.34-10.11-10.43.4-17.52,10.35-20.72,14.16-26.14,31.09-97.26,27.47-134.86,15.01-26.3-8.71-37.34-13.01-37.34-13.01-18.76-7.3-24.07-10.56-34-11-13.54-.6-19.59,4.74-36,11-2.89,1.1-33.73,12.56-68.56,11.11-14.87-.62-32.13-2.94-52.25-11.54-28.17-12.04-35.75-26.36-55.34-25.12-16.15,1.02-16.3,11.08-38,15.36-19.45,3.84-36.13-.94-47.9-4.32-26.85-7.71-27.87-16.74-47.41-19.57-16.39-2.38-24.79,2.69-49.24,8.67-15.98,3.91-39.29,8.21-68.31,9.25v141.16h768v-114.28c-30.72.33-48.79-8.25-58.74-16.77Z",
        lineColor: "spruce",
        lines: [
            [110.15, 165, 110.15, 324],      // peak1
            [253.33, 173.5, 253.33, 324],      // peak2
            [462.1, 188, 462.1, 324],     // peak3
        ],
        topPath: "",
    },
    goodR2: {
        id: "goodR2",
        color: "waveBlue",
        waveModel: "m647,210c-14.9.44-10.21,3.9-43,9-21.76,3.38-41.13,4.55-54.33,5.34-2.8.17-15.03.89-31.24,1.3-7.27.18-13.49.24-20.07.3-72.11.61-178.24-7.17-206.51-28.83-2.28-1.74-9.84-8.02-20.48-8.48-9.23-.4-15.43,3.89-22.19,6.78-11.79,5.05-22.08,5.07-42.67,5.09-19.99.02-56.92.06-87.04-22.05-12.16-8.93-14.88-15.39-25.6-16.96-14.92-2.18-23.87,8.2-42.67,15.26-11.31,4.25-28.06,8.01-51.2,5.09v142.16h768v-98.06c-22.45,4.04-39.84,2.74-51,1.06-32.9-4.97-41.53-17.85-70-17Z",
        lineColor: "navy",
        lines: [
            [90.04, 160.32, 90.04, 324],      // peak1
            [271.86, 188.58, 271.86, 324],    // peak2
            [649.84, 209.5, 649.84, 324],     // peak3
        ],
        topPath: "",
    }
}

export {waveMiniData, diagrams, waveGraphData, bgPeaksData};