import { useState } from 'react';
import type { CollapseProps } from 'antd';
import { Typography, Row, Col,  } from 'antd';
import type { TourProps } from 'antd';


const { Title, Paragraph, Text, Link } = Typography;

const fontSizePara = 17;
const fontAlign = "justify";
const paragraphStyle:React.CSSProperties = {
  textAlign: fontAlign,  
  fontSize: fontSizePara,
  fontFamily: "'Roboto', 'sans-serif'",
};

export function useTemString() {
  const [syllablesString, setSyllablesString] = useState<string[][]>([
    ['Big', '-ger', 'and', 'bet', '-ter'],
    ['Drum', 'and', 'bu', '-gle', 'corps'],
    ['He', 'has', 'a', 'good', 'job'],
    ['Be', '-fore', 'and', 'af', '-ter'],
    ['Come', 'back', 'right', 'a', '-way'],
    ['Blow', 'up', 'the', 'bal', '-loon']
  ]);

  const [stairFilenames, setStairFilenames] = useState<string[]>([
    "Bigger and better",
    "Drum and bugle corps",
    "He has a good job",
    "Before and after",
    "Come back right away",
    "Blow up the balloon"
  ]);

  const [heteronymFilenames, setHeteronymFilenames] = useState<string[]>([
    "ADdress adDRESS",
    "CONduct conDUCT",
    "CONflict conFLICT",
    "CONtent conTENT",
    "CONsole conSOLE",
    "CONtest conTEST",
    "CONtrast conTRAST",
    "CONtract conTRACT",
    "DEsert deSERT",
    "CONvert conVERT",
    "EScort esCORT",
    "EXport exPORT",
    "CONvict conVICT",
    "EXtract exTRACT",
    "IMport imPORT",
    "DIgest diGEST",
    "INcrease inCREASE",
    "INsult inSULT",
    "PROduce proDUCE",
    "REcord reCORD",
    "TRANSfer transFER",
    "IMprint imPRINT",
    "INsert inSERT",
    "COMpound comPOUND",
    "INvalid inVALID",
    "OBject obJECT",
    "MINute minUTE",
    "PERmit perMIT",
    "PROject proJECT",
    "PREsent preSENT"
  ]);
  const items_3: CollapseProps['items'] = [
    {
      key: '1',
      label: <Typography.Title id="part-3-1" level={4} style={{ margin: 0 }}>Setting</Typography.Title>,
      children: (
        <>
          <Paragraph>The possible settings are:</Paragraph>
          <Paragraph>
            <ul>
              <li>
                <p><strong>Model selection</strong>: Lets you pick between several prerecorded voices to serve as a target "avatar". These are currently used only in the Syllables exercise.</p>
              </li>
              <li>
                <p><strong>Exercise selection</strong>: Lets you pick between Constant, Stair, and Syllable exercises.</p>
              </li>
              <li>
                <p><strong>Coordinate Display Mode</strong>: changes the pitch display between hertz and musical notes. You can also change this by clicking the vertical scale on the graph itself.</p>
              </li>
              <li>
                <p><strong>Pitch display range</strong>: Changes the scale of the pitch graph. The blue part represents the current scale, and the white balls on the left and right can be dragged with your mouse to change the minimum or maximum of the scale. By default, it shows 100-300 Hz, but the maximum range is 50-600  Hz.</p>
              </li>
              <li>
                <p><strong>Pitch Indicator position (Only appears in the Constant exercise)</strong>: Changes where the "current pitch" Pitch indicator appears in the graph.</p>
              </li>
              <li>
                <p><strong>Pitch Indicator speed</strong>: Changes the speed with which the "current pitch" indicator ball moves across the screen.</p>
              </li>
              <li>
                <p><strong>Auto-Start when Voice Detected</strong>: If this is enabled and the pitch module is paused, it will automatically start tracking pitch when the module detects sound on your microphone. If this is disabled, you must manually start it after pausing it.</p>
              </li>
            </ul>
          </Paragraph>
        </>
      ),
    },
    {
      key: '2',
      label: <Typography.Title id="part-3-2" level={4} style={{ margin: 0 }}>Constant exercise</Typography.Title>,
      children: (
        <>
          <Paragraph>
            The Constant exercise is the most basic exercise. When you talk, it shows your current pitch with a large black dot and the last few seconds of your pitch with small red dots. If you stop talking, the graph keeps moving but does not show new red dots.
          </Paragraph>
          <Paragraph>
            On the right side of the graph, you can see a vertical slider. Moving this slider up and down creates a constant pitch target that is shown as a horizontal dark blue line across the screen. You can try to match this target. If your pitch is close enough to the target, the target line changes from blue to yellow. You can change the target as you exercise if you want.
          </Paragraph>
          <Paragraph>
            If you want something to read while you practice matching a target pitch, you can use the Upload txt file button below the graph to upload a .txt file with text of your choosing. In the .txt file, a semicolon should indicate a new line . While reading, you can then move between lines using the Forward/Backward buttons underneath the text. Other file formats are not currently supported but are planned for future releases.
          </Paragraph>
        </>
      ),
    },
    {
      key: '3',
      label: <Typography.Title id="part-3-3" level={4} style={{ margin: 0 }}>Stair Exercise</Typography.Title>,
      children: (
        <>
          <Paragraph>
            When you talk, the Staircase exercise shows your current pitch with a large black dot and the last few seconds of your pitch with small red dots. If you stop talking, the graph keeps moving but does not show new red dots. This is the same as in the Constant exercise.
          </Paragraph>
          <Paragraph>
            A dark blue line shows a sequence of pitches for you to match within a few seconds. You can change the location of these 'target' pitches using the light blue vertical slider to the right of the graph – drag the bottom white ball up/down to move the lowest target or drag the top white ball to move the highest target.
          </Paragraph>
          <Paragraph>
            Pressing the Play button starts moving the current pitch indicator. Press it when you are ready to try matching the pitches. You can pause at any time, and once the indicator gets to the end, you can press Play again to restart. As you speak, the dark blue target pitch curve will change to yellow if your pitch is close to it.
          </Paragraph>
          <Paragraph>
            If you would like to have some sounds to make while matching the target pitches, the Show/Hide buttons  below the graph display a 5-syllable phrase. The full phrase is shown below the Show/Hide buttons, and each individual syllable is shown on one of the target pitch lines. The Forward/Backward buttons let you move to the next/previous phrase, and the Retry button resets the current phrase.  You don't have to use this function, it's just if you want to have something to say.
          </Paragraph>
        </>
      ),
    },
    {
      key: '4',
      label: <Typography.Title id="part-3-3" level={4} style={{ margin: 0 }}>Heteronyms Exercise</Typography.Title>,
      children: (
        <>
          <Paragraph>
          Heteronyms are words that are spelled the same way but pronounced differently - for example, by stressing a different syllable. In the Heteronyms exercise, the text below the graph shows two different ways to stress the same word, with the stressed variable in capital letters - for example, ADdress and adDRESS.          </Paragraph>
          <Paragraph>
          Pressing the Play button starts moving the current pitch indicator, as in the Constant and Stair exercises. Speak the word with the two variants of word stress and see how your pitch changes during the word - the stressed syllable should have a higher pitch.          </Paragraph>
          <Paragraph>
          The target curve on the graph (shown in dark blue) is simply a descending pitch followed by an ascending pitch. You do not need to match this curve exactly, it's just a reminder that the stressed syllable should have a higher pitch.          </Paragraph>
          <Paragraph>
          Finally, the Forward/Backward buttons let you move to the next or previous pair of heteronyms, and the Retry button lets you try the same pair of heteronyms again.          </Paragraph>
          <Paragraph>
          The heteronyms exercise is a bit different from the Constant and Stair exercises since it does not help you directly maintain a higher pitch - it simply teaches the concept of upward and downward pitch within a word, which can serve as the basis for other gender-affirming voice exercises.
           </Paragraph>
        </>
      ),
    },
    {
      key: '5',
      label: <Typography.Title id="part-3-4" level={4} style={{ margin: 0 }}>Syllables Exercise</Typography.Title>,
      children: (
        <>
          <Paragraph>
            To use the Syllables exercise, you must first select an avatar in the Options menu. Once you have done that, the Syllables exercise shows pitch curves from that recorded avatar while speaking 2-, 3-, 4-, or 5-syllable phrases in dark blue. As in the other two exercises, the graph shows your current pitch with a large black dot and the last few seconds of your pitch with small red dots. If you stop talking, the graph keeps moving but does not show new red dots.
          </Paragraph>
          <Paragraph>
            The goal of the exercise is to match the avatar's pitch curve as you say the same phrase. The target phrase is shown below the graph in green. Pressing the Play button starts moving the current pitch indicator. Press it when you are ready to try matching the curve. You can pause at any time, and once the indicator gets to the end, you can press Play again to restart the same phrase. As you speak, the dark blue target pitch curve will change to yellow if your pitch is close to it.
          </Paragraph>
          <Paragraph>
            Once the phrase finishes, you can press the Retry button to try matching it again, or press Forward/Backward to move to the next or previous phrase. The next two phrases are shown in grey below the current phrase.
          </Paragraph>
          <Paragraph>
            If you want to hear the phrase spoken by your selected avatar, press the Listen button. The dark blue curve will change to yellow as the phrase is played to indicate the timing.
          </Paragraph>
        </>
      ),
    },
    {
      key: '6',
      label: <Typography.Title id="part-3-5" level={4} style={{ margin: 0 }}>Suggestions</Typography.Title>,
      children: (
        <>
          <Paragraph>
            If you have limited ability to control your pitch, we recommend starting with the Constant exercise. Measure your current pitch and set the target pitch (with the slider on the right) to somewhere above your regular pitch. The "stereotypical" value for female pitch is about 200 Hz. Make sustained sounds (for example, "aaaaaaaaaaa") and try to consistently match the target pitch. Once you can consistently match the target pitch with all five vowels (a, e, i, o, u), try speaking longer phrases at the target pitch.
          </Paragraph>
          <Paragraph>
            When you feel comfortable matching the target pitch in the Constant exercise for short periods of time, add the Staircase exercise to your practice rotation. You can start by hiding the target text and just matching the target pitches with a sustained vowel sound (for example, "aaaaa" moving up and down in pitch). Once you can do that with all five vowel sounds, turn the target text on and try speaking the phrases. Once you can do that, you may also try the Syllables exercise, though you do not necessarily need to do the Syllables exercise – the Constant and Staircase will give you the fundamentals.
          </Paragraph>
          <Paragraph>
            If this is all of your voice practice, do it for 10-15 minutes two times a day. It will help you learn pitch control, though you should keep in mind that pitch is not the only component of transgender voice. You will want to move on to other components of voice as well.
          </Paragraph>
        </>
      ),
    },
  ];
  // const [component, setComponent] = useState<string>("ConstantTxt");
  const shortDescription = (component:string): React.ReactNode => {
    switch (component) {
      // case 'Constant':
      //   return <Constant ballPosition={ballPosition} isRetry={isRetry} setPlayingPause={setPlayingPause} setPlaying={setPlaying} COLORS={COLORS} showNotesPar={showNotesPar} size={size} config={config} isPlaying={isPlaying} />;
      case 'ConstantTxt':
        return (
          <Row>
          <Col span={5}>
          </Col>
          <Col span={14}>
          <Typography style={{ paddingLeft: "30px", paddingRight: "40px" }}>
            {/* <div className="centerTitle">
              <Title className="customTitle">CONSTANT EXERCISES</Title>
            </div> */}
            <Paragraph style={paragraphStyle}>
            The Constant exercise is the most basic exercise. Try to match a constant pitch target using whatever sounds you prefer - short sounds, full monologues, or select/upload a text file for you to read below. You can change the pitch target using the slider to the right.  
            </Paragraph >
          </Typography>
          </Col>
        
          <Col span={5}>
          </Col>
          </Row>
        )
        // <>1</>
      case 'Stair':
        return (
          <Row>
          <Col span={5}>
          </Col>
          <Col span={14}>
          <Typography style={{ paddingLeft: "30px", paddingRight: "40px" }}>
            {/* <div className="centerTitle">
              <Title className="customTitle">STAIR EXERCISES</Title>
            </div> */}
            <Paragraph style={paragraphStyle}>
            The Stair exercise asks you to match a series of five pitch targets. You can change the lowest and highest target using the slider to the right. You can use whatever sounds you prefer, or you can use the Show/Hide button below to show 5-syllable phrases that you can say at the five target pitches.
            </Paragraph >
          </Typography>
          </Col>
        
          <Col span={5}>
          </Col>
          </Row>
        )
      case 'Fixed':
        return (
          <Row>
          <Col span={5}>
          </Col>
          <Col span={14}>
          <Typography style={{ paddingLeft: "30px", paddingRight: "40px" }}>
            {/* <div className="centerTitle">
              <Title className="customTitle">HUMAN CURVE EXERCISES</Title>
            </div> */}
            <Paragraph style={paragraphStyle}>
            The human curve exercise gives you pitch curves of actual humans speaking different phrases (2 to 5 syllables long). Try to match the pitch curve with your own speech! You can use the Listen button below to hear the actual recording, and you can select different model speakers in the Options menu.            </Paragraph >
          </Typography>
          </Col>
        
          <Col span={5}>
          </Col>
          </Row>
        )
      case 'Heteronym':
        return (
          <Row>
          <Col span={5}>
          </Col>
          <Col span={14}>
          <Typography style={{ paddingLeft: "30px", paddingRight: "40px" }}>
            {/* <div className="centerTitle">
              <Title className="customTitle">HETERONYMS EXERCISES</Title>
            </div> */}
            <Paragraph style={paragraphStyle}>
            The heteronyms exercise asks you to speak two words that are written the same way but pronounced differently - for example, address as a verb vs. address as a noun. Practice stressing different syllables to make your pitch curve go up or down!
            </Paragraph >
          </Typography>
          </Col>
        
          <Col span={5}>
          </Col>
          </Row>
        )
      default:
        return (
          <Row>
          <Col span={5}>
          </Col>
          <Col span={14}>
          <Typography style={{ paddingLeft: "30px", paddingRight: "40px" }}>
            {/* <div className="centerTitle">
              <Title className="customTitle">CONSTANT EXERCISES</Title>
            </div> */}
            <Paragraph style={paragraphStyle}>
            The Constant exercise is the most basic exercise. Try to match a constant pitch target using whatever sounds you prefer - short sounds, full monologues, or select/upload a text file for you to read below. You can change the pitch target using the slider to the right.  
            </Paragraph >
          </Typography>
          </Col>
        
          <Col span={5}>
          </Col>
          </Row>
        )
    }
  }

  return {
    syllablesString,
    stairFilenames,
    heteronymFilenames,
    items_3,
    shortDescription,


  };
}