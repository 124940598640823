/*
    This is a lib of fns that are used by waveAudio.js & waveDrawLoop.js.
    They process the magnitude and peak data returned from the LPC in order to prepare it for the canvas drawLoop. 
*/


const getMean = (arr) => {
    let result = 0;
    for (let i = 0; i < arr.length; i++) {
        result += arr[i];
    }
    return result / arr.length;
};

// const scale = (lpc) => {
//     const sensitivityValueEle = document.getElementById(
//         "sensitivitySlider"
//     );

//     if (sensitivityValueEle !== null) {
//         for (let i = 0; i < lpc.length; i++) {
//             let factor = parseInt(sensitivityValueEle.value) / 100.0;
//             lpc[i] = lpc[i] * factor;
//         }
//     }
//     return lpc;
// };

const updateMagnitudes = (newMagnitudes, magnitudes) => {
    const FADE_JUMP_UP = 0.1;
    const FADE_JUMP_DOWN = 0.1;
    const TARGET_SPACE = 0.001;

    const newMags = [];
    for (let i = 0; i < magnitudes.length; i++) {
        if (
            magnitudes[i] > newMagnitudes[i] - TARGET_SPACE / 2 &&
            magnitudes[i] < newMagnitudes[i] + TARGET_SPACE / 2
        ) {
            newMags.push(magnitudes[i]); // Do nothing.
        } else if (magnitudes[i] < newMagnitudes[i]) {
            let diff = newMagnitudes[i] - magnitudes[i];
            newMags.push(magnitudes[i] + diff * FADE_JUMP_UP);
        } else {
            let diff = magnitudes[i] - newMagnitudes[i];
            newMags.push(Math.max(magnitudes[i] - FADE_JUMP_DOWN * diff, 0));
        }
    }
    return newMags;
};

const createArrayWithSameValue = (value, size) => {
    const arr = new Array();
    for (let i = 0; i < size; i++) {
        arr.push(value);
    }
    return arr;
};

const normalizeIfNecessary = (magsToAdjust) => {
    const maxMag = Math.max.apply(Math, magsToAdjust);
    let adjustedMags = magsToAdjust;
    if (maxMag > 1.0) {
        adjustedMags = new Array();
        for (let i = 0; i < magsToAdjust.length; i++) {
            adjustedMags.push(magsToAdjust[i] / maxMag);
            if (adjustedMags[i] > 1.0) {
                console.log(adjustedMags[i]);
            }
        }
    }
    return adjustedMags;
};

const expandArraySmoothly = (source, targetResolution) => {
    const numSteps = targetResolution / source.length;
    const newArray = new Array();
    for (let i = 0; i < source.length; i++) {
        let sourceRes = source[i];
        let stepDiff = 0;
        if (i < source.length - 1) {
            stepDiff = (source[i + 1] - source[i]) / numSteps;
        }
        for (let j = 0; j < numSteps; j++) {
            newArray.push(sourceRes + stepDiff * j);
        }
    }
    return newArray;
};

export {    getMean, updateMagnitudes, createArrayWithSameValue, 
            normalizeIfNecessary, expandArraySmoothly 
        }