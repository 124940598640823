// GavtForm.tsx
import React, { useState }from 'react';
import { useNavigate } from 'react-router-dom';

import getRefFreq from './getRefFreq';

import { Form, Checkbox, Radio, Button, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

// const vowels = ['/i/', '/ɪ/', '/ɛ/', '/æ/', '/ɑ/', '/ɔ/', '/ʌ/', '/u/', '/ʊ/'];
// const vowels = ['ee', 'ih', 'eh', 'ae', 'ah', 'aw', 'uh', 'ooh', 'oo'];
const vowels = [
  { label: <span>s<strong>ee</strong>d</span>, value: 'ee' },
  { label: <span>h<strong>i</strong>d</span>, value: 'ih' },
  { label: <span>h<strong>ea</strong>d</span>, value: 'eh' },
  { label: <span>s<strong>a</strong>d</span>, value: 'ae' },
  { label: <span>b<strong>u</strong>d</span>, value: 'uh' },
  { label: <span>g<strong>oo</strong>d</span>, value: 'oo' },
  { label: <span>g<strong>o</strong>d</span>, value: 'ah' },
  { label: <span>l<strong>aw</strong>n</span>, value: 'aw' },
  { label: <span>sp<strong>oo</strong>n</span>, value: 'ooh' },
  
];
const regions = ['Mid_Atlantic', 'New_England', 'North', 'Midland', 'South', 'West'];

export const vowelMapping: Record<string, string> = {
  'ee': '/i/',
  'ih': '/ɪ/',
  'eh': '/ɛ/',
  'ae': '/æ/',
  'ah': '/ɑ/',
  'aw': '/ɔ/',
  'uh': '/ʌ/',
  'ooh': '/u/',
  'oo': '/ʊ/'
};

interface FormValues {
  vowels: string[];
  gender: 'man' | 'woman';
  region: string;
  shuffleOrder: boolean;
}

const GavtForm: React.FC = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const onFinish = async (values: FormValues) => {
    const { vowels, gender, region, shuffleOrder } = values;
    try {
      const mappedVowels = vowels.map(vowel => vowelMapping[vowel]);
      // const frequencies = await getRefFreq(mappedVowels, gender, region, shuffleOrder);
      const frequencies = await getRefFreq(mappedVowels, gender, shuffleOrder);
      if (Object.keys(frequencies).length > 0) {
        console.log(frequencies);
        navigate('/resonance/practice', { state: { frequencies } });
      } else {
        console.log('No resonance frequencies found.');
      }
    } catch (error) {
      console.error('Error fetching resonance frequencies:', error);
    }
  };

  return (
    <Form 
      form={form}
      onFinish={onFinish}
      layout="vertical"
      initialValues={{
        gender: 'woman',
        region: 'Mid_Atlantic',
        shuffleOrder: false,
      }}
    >
      <Form.Item 
        name="vowels"
        label={ <span>
          Which vowel(s) would you like to practice?
          <Tooltip title={
            <span>
            “ee” like in “seed”<br />
            “ih” like in “hid”<br />
            “eh” like in “head”<br />
            “ae” like in “sad”<br />
            “uh” like in “bud”<br />
            “oo” like in “good”<br />
            “ah” like in “god”<br />
            “aw” like in “lawn”<br />
            “ooh” like in “spoon”<br />
            </span>
          }
          >
            <InfoCircleOutlined style={{ marginLeft: 5}} />
          </Tooltip>
        </span>}
        rules={[{ required: true }]}
      >
        <Checkbox.Group options={vowels}/>
      </Form.Item>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
          <span>brighter vowels</span>
          <span>darker vowels</span>
      </div>
      <br />

      <Form.Item name="gender" label="Are you trying to feminize or masculinize your voice?" rules={[{ required: true }]}>
        <Radio.Group>
          <Radio value="woman">Feminize</Radio>
          <Radio value="man" disabled>Masculinize</Radio>
          <Radio value="non-binary" disabled>
            Non-binary
            <Tooltip title="We are still working on developing resonant frequency reference data for non-binary speakers. For the time being, we encourage you to use the Calibration routine to select custom targets based on your individual preferences.">
              <InfoCircleOutlined style={{ marginLeft: 5}} />
            </Tooltip>
          </Radio>
        </Radio.Group>
      </Form.Item>

      {/* <Form.Item name="region" label="Which dialect region would you like to use to set your reference targets? " rules={[{ required: true }]}>
        <Radio.Group options={regions.map(region => ({ label: region, value: region }))} />
      </Form.Item> */}

      <Form.Item name="shuffleOrder" valuePropName="checked">
        <Checkbox>Shuffle vowels' order?</Checkbox>
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit">Start Practicing</Button>
      </Form.Item>
    </Form>
  );
};

export default GavtForm;
