import React, { useState, useEffect, useRef } from 'react';
import { Button, Flex, Segmented } from 'antd';
import { AppstoreOutlined, MailOutlined, SettingOutlined, CustomerServiceOutlined } from '@ant-design/icons';
import { Col, Row, Tour } from 'antd';
import { FrownOutlined, SmileOutlined, CaretRightOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Slider, Input } from 'antd';
import { Breadcrumb, Layout, Menu, Dropdown, theme } from 'antd';
import { Drawer, Cascader, Radio } from 'antd';
import { Select } from 'antd';
import { SearchOutlined, RedoOutlined, PauseOutlined, StepForwardOutlined, StepBackwardOutlined } from '@ant-design/icons';
import { Tooltip, FloatButton, Space, Switch } from 'antd';
import '../CSS/sample.css';
import ConstantVol from '../Canvas/ConstantVol'
import type { RadioChangeEvent } from 'antd';
import type { MenuProps } from 'antd';
import type { TourProps } from 'antd';
import type { SliderSingleProps } from 'antd';
import type { UploadProps } from 'antd';
import { UploadOutlined, ArrowLeftOutlined, RollbackOutlined } from '@ant-design/icons';
import { message, Upload } from 'antd';
import hark from 'hark';
import { useLocation } from 'react-router-dom';
import divide_1 from '../icon/Divider2-half.png'
import { Image } from 'antd';
// @ts-ignore
import pdfToText from 'react-pdftotext';
import useCanvasAdjustHeight from '../hooksUseEffect/useCanvasAdjustHeight';
import AudioPlayer from 'react-h5-audio-player';
import { createFromIconfontCN } from '@ant-design/icons';
import { useOptionsHooks } from '../hooks/useOptionsHooks';
import { Typography } from 'antd';

const { Header, Content } = Layout;

const { TextArea } = Input;
const { Title, Paragraph, Text, Link } = Typography;
type MenuTheme = 'show' | 'hide';

interface FloatButtonProps {
  children?: React.ReactNode; // Add this line to accept child elements
}
interface Option {
  value: string;
  label: string;
  disabled?: boolean;
}

const marksRange: SliderSingleProps['marks'] = {
  50: '50Hz',
  600: '600Hz'
};

const marksBallPos: SliderSingleProps['marks'] = {
  0: '0%',
  1: '100%'
};

const marksVol: SliderSingleProps['marks'] = {
  50: '50dB',
  90: '90dB'
};
function Sample() {
  const [open, setOpen] = useState(false);
  const [txtShow, setTxtShow] = useState<string>("true");
  const [txtShowBoo, setTxtShowBoo] = useState<boolean>(true);
  const size = [400, 1400];
  const location = useLocation();

  const [initialRange, setInitialRange] = useState<number[]>([100, 300]);
  const [config, setConfig] = useState({
    SRATE: 48000,
    fxmin: 50,
    fxlow: 50 + 50, // This initial value will be updated by useEffect below
    fxhigh: 600 - 200, // This initial value will be updated by useEffect below
    fxmax: 600
  });
  const COLORS = {
    realVoiceColor: '#FF0000', // red
    targetVoiceColor: '#00008B', // blue
    closeVoiceColor: '#FFFF00', // yellow
  };

  const [maxLyricCount, setMaxLyricCount] = useState<number>(2);
  const [isPlaying, setIsPlaying] = useState(true);
  const [isRetry, setIsRetry] = useState(false);
  const setPlaying = () => {
    setIsPlaying(true);
  };
  const setPlayingPause = () => {
    setIsPlaying(false);
  };
  const handleRetry = () => {
    setIsRetry(!isRetry);
  };

  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const [volume, setVolume] = useState(0);
  const [historyMode, setHistoryMode] = useState("None");
  const [divisor, setDivisor] = useState<number>(10);
  const [openTour, setOpenTour] = useState<boolean>(false);
  const [isFixed, setIsFixed] = useState(true);
  const [showNotesPar, setShowNotesPar] = useState(true);
  const [component, setComponent] = useState<string>("ConstantTxt");
  // const [component, setComponent] = useCookieState<string | number>("Constant", "initialValue");
  // const [component, setComponent] = useCookieState<string>('component11',"Constant");
  const [ballPosition, setBallPosition] = useState<number>(0.5);
  // const [selectedComponent, setSelectedComponent] = useState<React.ReactNode>(<Constant ballPosition={ballPosition} isRetry={isRetry} setPlayingPause={setPlayingPause} setPlaying={setPlaying} COLORS={COLORS} showNotesPar={showNotesPar} size={size} config={config} isPlaying={isPlaying} />);
  const [currentSelection, setCurrentSelection] = useState('Constant'); //The initial state is empty
  const [enableVol, setEnableVol] = useState<boolean>(false);
  const [playLyricCount, setPlayLyricCount] = useState<number>(0);
  const [gender, setGender] = useState<string>("none");
  const [genderName, setGenderName] = useState<string>("none");
  const [syllableCount, setSyllableCount] = useState<string>("none");
  const [baseFilenames, setBaseFilenames] = useState<string[]>([]);
  const [stairFilenames, setStairFilenames] = useState<string[]>([
    "Bigger and better",
    "Drum and bugle corps",
    "He has a good job",
    "Before and after",
    "Come back right away",
    "Blow up the balloon"
  ]);
  const [heteronymFilenames, setHeteronymFilenames] = useState<string[]>([
    "ADdress adDRESS",
    "CONduct conDUCT",
    "CONflict conFLICT",
    "CONtent conTENT",
    "CONsole conSOLE",
    "CONtest conTEST",
    "CONtrast conTRAST",
    "CONtract conTRACT",
    "DEsert deSERT",
    "CONvert conVERT",
    "EScort esCORT",
    "EXport exPORT",
    "CONvict conVICT",
    "EXtract exTRACT",
    "IMport imPORT",
    "DIgest diGEST",
    "INcrease inCREASE",
    "INsult inSULT",
    "PROduce proDUCE",
    "REcord reCORD",
    "TRANSfer transFER",
    "IMprint imPRINT",
    "INsert inSERT",
    "COMpound comPOUND",
    "INvalid inVALID",
    "OBject obJECT",
    "MINute minUTE",
    "PERmit perMIT",
    "PROject proJECT",
    "PREsent preSENT"
  ]);

  const [threshold, setThreshold] = useState<number>(70);
  // const [showThreshold, setShowThreshold] = useState<boolean>(false);
  const [displayText, setDisplayText] = useState('Input Txt');
  const [audioSrcFromChild, setAudioSrcFromChild] = useState<string>('');
  const [fileContent, setFileContent] = useState<string[]>([]);
  const [showNextPre, setShowNextPre] = useState<boolean>(false);
  const [showListen, setShowListen] = useState<boolean>(false);
  const [enablePre, setEnablePre] = useState<boolean>(false);
  const [enableNext, setEnableNext] = useState<boolean>(true);
  const parentRef = useRef<HTMLDivElement>(null);
  const [currentColorScheme, setCurrentColorScheme] = useState("blue");
  const [LAMP_COLORS, setLAMP_COLORS] = useState<string[]>([
    'rgb(3, 4, 242)', // low
    'rgb(106, 0, 147)', // mid
    'rgb(221, 0, 34)', // high
  ]);

  const LAMP_COLORSBLUE = [
    'rgb(3, 4, 242)', // low
    'rgb(106, 0, 147)', // mid
    'rgb(221, 0, 34)', // high
  ];

  const LAMP_COLORSGREEN = [
    'rgb(98, 187, 70)', // low
    'rgb(255, 194, 14)', // mid
    'rgb(241, 91, 64)', // high
  ];
  useEffect(() => {
    if (currentColorScheme === "blue") {
      setLAMP_COLORS(LAMP_COLORSBLUE);
    } else {
      setLAMP_COLORS(LAMP_COLORSGREEN);
    }
  }, [currentColorScheme]);


  // const [showPre, SetShowPre] = useState<boolean>(false);
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const getLampColor = (vol: number) => {
    let temvol = vol + 130;
    if (temvol <= 50) return LAMP_COLORS[0];
    if (temvol <= 80) return LAMP_COLORS[1];
    return LAMP_COLORS[2];
  };
  const lampStyle = (color: string) => ({
    backgroundColor: color,
    opacity: color === getLampColor(volume) ? 1 : 0.2,
    width: '20px',
    height: '20px',
    borderRadius: '50%',
    margin: '5px',
  });


  // const drawerRef = useRef(null);
  const handleAfterOpenChange = (open: boolean) => {
    if (open) {
      setTooltipVisible(true);
      scrollDrawerContent();
    } else {
      setTooltipVisible(false);
    }
  };
  const scrollDrawerContent = () => {
    const drawerElement = document.querySelector('.ant-drawer-body');
    const scrollOffset = 13;

    if (drawerElement) {
      drawerElement.scrollTop = scrollOffset;
    }
  };

  const handleBeginTour = () => {
    setOpenTour(true);
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const init = queryParams.get('init');
    if (init === 'true') {
      handleBeginTour();
    }
  }, [location]);

  const props: UploadProps = {
    name: 'file',
    accept: '.txt',
    beforeUpload: (file) => {
      const reader = new FileReader();
      reader.onload = (e: ProgressEvent<FileReader>) => {
        if (e.target?.result) {
          const content = e.target.result as string;
          // const separators = /[;,./]/;
          const separators = /[;,./\n]/;
          const splitContent = content.split(separators)
            .map(s => s.trim())
            .filter(Boolean);
          console.log(splitContent);
          setFileContent(splitContent);
          setMaxLyricCount(splitContent.length - 1);
          setPlayLyricCount(0);
        }
      };

      reader.readAsText(file);

      return false;
    },
    onChange(info) {
      if (info.file.status === 'done') {
        message.success(`${info.file.name} file read successfully`);
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file read failed.`);
      }
    },
  };

  const steps: TourProps['steps'] = [
    {
      title: 'Canvas Display',
      description: 'Visualize your voice pitch and target pitch curve in real-time.',
      placement: 'top',
      target: () => ref1.current,
    },
    {
      title: 'Function Buttons',
      // description: 'Save your changes.',
      cover: (
        <ul style={{ textAlign: 'left' }}>
          <li>Start/Stop: Begin or stop recording.</li>
          <li>Retry: Clear display and restart.</li>
          <li>Upload txt File (optional): Upload text for you (in .txt format) to read while recording.</li>
          <li>Previous Next (if text uploaded): Switch between lines of text.</li>
        </ul>
      ),
      target: () => ref2.current,
    },
    {
      title: 'Slider',
      description: 'Use this slides to change the target pitch value that you want to aim for.',
      target: () => parentRef.current as HTMLDivElement,
    },
    {
      title: 'Options menu',
      description: 'Switch between different exercises and adjust settings such as the pitch display range.',
      target: () => ref3.current,
    },

  ];

  const handleHistoryMode = (e: any) => {
    setHistoryMode(e.target.innerText);
  }
  const handleColorStyle = (e: any) => {
    const newColorScheme = e.target.innerText.includes("Blue-Red") ? "blue" : "green";
    setCurrentColorScheme(newColorScheme);
  };

  const renderTruncatedFilenames = (filenames: string[]) => {
    return filenames.map((text, index) => {
      const maxIndexToShow = playLyricCount + 2 >= filenames.length ? filenames.length - 1 : playLyricCount + 2;
      const shouldShow = index >= playLyricCount && index <= maxIndexToShow;
      const isNearEnd = filenames.length - playLyricCount <= 2;
      const startIndex = isNearEnd ? filenames.length - 3 : playLyricCount;
      const showCondition = index >= startIndex && index <= startIndex + 2;

      return (
        showCondition && (
          <li key={index} className={playLyricCount === index ? 'highlighted' : ''}>{text}</li>
        )
      );
    });
  };

  const handleMenuClick = (e: any) => {
    console.log('choose:', e);
    const [componentType, genderType, syllableCountType] = e.key.split('-');
    if (componentType == "Constant") {
      setCurrentSelection("Constant");
    } else if (componentType == "ConstantTxt") {
      setCurrentSelection("Constant");
    } else if (componentType == "Stair") {
      setCurrentSelection("Staircase");
    } else {
      let tt = "Syllables " + syllableCountType
      setCurrentSelection(tt);
    }

    setComponent(componentType);
    setGender(genderType);
    setSyllableCount(syllableCountType);
    setCurrentSelection(e.key + "syllable " + genderName);
  };

  /**
 * Defines the items for the syllable menu based on the gender.
 * If the gender is 'none', it only includes a single item prompting the user to select an avatar.
 * Otherwise, it includes multiple items representing the number of syllables, with corresponding click handlers.
 */
  const itemsSyllable: MenuProps['items'] = gender === 'none' ? [
    {
      key: 'SelectAvatar',
      label: 'Please select avatar',
    },
  ] : [
    {
      key: `Fixed-${gender}-2`,
      label: "2 syllables",
      onClick: handleMenuClick,
    },
    {
      key: `Fixed-${gender}-3`,
      label: "3 syllables",
      onClick: handleMenuClick,
    },
    {
      key: `Fixed-${gender}-4`,
      label: "4 syllables",
      onClick: handleMenuClick,
    },
    {
      key: `Fixed-${gender}-5`,
      label: "5 syllables",
      onClick: handleMenuClick,
    },
  ];

  const showDrawer = () => {
    setOpen(true);
  };


  const onChangeStair = (e: RadioChangeEvent) => {
    setTxtShow(e.target.value);
    console.log(e);
  };


  const ClearTxt = () => {
    setFileContent([]);
    setMaxLyricCount(1);
  };
  const onClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    navigator.mediaDevices.getUserMedia({ audio: true, video: false })
      .then(stream => {
        const harkInstance = hark(stream, {});

        harkInstance.on('volume_change', (volume, threshold) => {
          // console.log('Volume:', volume, 'Threshold:', threshold);
          setVolume(volume);
        });

        return () => harkInstance.stop();
      })
      .catch(error => {
        console.error('Error accessing the microphone', error);
      });
  }, []);



  useEffect(() => {
    if (volume > threshold - 100 && enableVol) {
      setIsPlaying(true);
    }
  }, [volume]);


  const renderSelectedComponent = (): React.ReactNode => {
    switch (component) {
      // case 'Constant':
      //   return <Constant ballPosition={ballPosition} isRetry={isRetry} setPlayingPause={setPlayingPause} setPlaying={setPlaying} COLORS={COLORS} showNotesPar={showNotesPar} size={size} config={config} isPlaying={isPlaying} />;
      case 'ConstantTxt':
        return <ConstantVol LAMP_COLORS={LAMP_COLORS} historyMode={historyMode} volume={volume} ref={parentRef} initialRange={initialRange} divisor={divisor} ballPosition={ballPosition} isRetry={isRetry} setPlayingPause={setPlayingPause} setPlaying={setPlaying} COLORS={COLORS} showNotesPar={showNotesPar} size={size} config={config} isPlaying={isPlaying} />;
      default:
        return <span>Canvas: Picture</span>;
    }
  }

  const renderSelectedComponentLyric = (): React.ReactNode => {
    switch (component) {
      case 'ConstantTxt':
        return (
          <div className='lyric'>

            <Space size="middle">
              <Space.Compact>


                <Button onClick={ClearTxt} icon={<RedoOutlined />}></Button>

                <Upload maxCount={1} {...props}>
                  <Button icon={<UploadOutlined />}>Upload Txt File</Button>
                </Upload>
              </Space.Compact>
            </Space>

            <ul>
              {fileContent.map((text, index) => {
                const maxIndexToShow = playLyricCount + 2 >= fileContent.length ? fileContent.length - 1 : playLyricCount + 2;

                const shouldShow = index >= playLyricCount && index <= maxIndexToShow;
                const isNearEnd = fileContent.length - playLyricCount <= 2;
                const startIndex = isNearEnd ? fileContent.length - 3 : playLyricCount;
                const showCondition = index >= startIndex && index <= startIndex + 2;

                return (
                  showCondition && (
                    <li key={index} className={playLyricCount === index ? 'highlighted' : ''}>{text}</li>
                  )
                );
              })}
            </ul>
          </div>
        )
      case 'Stair':
        return (
          <div className='lyric'>
            <Radio.Group onChange={onChangeStair} defaultValue="off">
              <Radio.Button value="true">Show</Radio.Button>
              <Radio.Button value="false">Hide</Radio.Button>
            </Radio.Group>
            {(() => {
              if (txtShow === 'true') {
                return (
                  <ul>
                    {renderTruncatedFilenames(stairFilenames)}
                  </ul>
                );
              }
              return null;
            })()}

          </div>
        )
      case 'Heteronym':
        return (
          <div className='lyric'>
            <Radio.Group onChange={onChangeStair} defaultValue="off">
              <Radio.Button value="true">Show</Radio.Button>
              <Radio.Button value="false">Hide</Radio.Button>
            </Radio.Group>
            {(() => {
              if (txtShow === 'true') {
                return (
                  <ul>
                    {renderTruncatedFilenames(heteronymFilenames)}
                  </ul>
                );
              }
              return null;
            })()}

          </div>
        )
      case 'Fixed':
        return (
          <div className='lyric'>
            <ul>
              {renderTruncatedFilenames(baseFilenames)}
            </ul>
          </div>
        )
      default:
        return <span>Canvas: Picture</span>;
    }
  }

  useEffect(() => {
    if (component == "ConstantTxt" && fileContent.length === 0) {
      setShowNextPre(false);
      setShowListen(false);
    } else if (component == "ConstantTxt" && fileContent.length != 0) {
      setShowNextPre(true);
      setShowListen(false);
    }
    else if (component == "Fixed") {
      setShowNextPre(true);
      setShowListen(true);
    } else if (component == "Stair") {
      setShowNextPre(true);
      setShowListen(false);
    } else if (component == "Heteronym") {
      setShowNextPre(true);
      setShowListen(false);
    } else {
      setShowNextPre(true);
      setShowListen(false);
    }
  }
    , [component, fileContent]);

  useEffect(() => {

  }, [playLyricCount]);
  const onChangeRange = (rangeValue: number[]) => {
    console.log(rangeValue[1]);
    setInitialRange([rangeValue[0], rangeValue[1]]);

  }

  const onChangePosition = (positionValue: number) => {
    setBallPosition(positionValue);
  }

  const onChangeSpeed = (speedValue: number) => {
    const mappedValue = Math.round((speedValue * 20) + 4);
    setDivisor(mappedValue);
  }

  const onChangeHz = ({ target: { value } }: RadioChangeEvent) => {
    console.log(value);
    if (value === 'hz') {
      setShowNotesPar(false);
    } else {
      setShowNotesPar(true);
    }
  }

  const EnableVoluneMode = (e: boolean) => {
    console.log(e);
    if (e) {
      setEnableVol(true);

    } else {
      setEnableVol(false);

    }
  }
  // Pause function
  const onChangePause = () => {
    setIsPlaying(!isPlaying);
  }
  const onChangePrevious = () => {
    const tem = playLyricCount;
    if (tem <= 0) {
      setPlayLyricCount(0);
    } else {
      setPlayLyricCount(tem - 1);
    }
  };

  const onChangeNext = () => {
    const tem = playLyricCount;
    console.log(playLyricCount);
    if (tem >= maxLyricCount) {
      setPlayLyricCount(maxLyricCount);
    } else {
      setPlayLyricCount(tem + 1);
    }
  };

  const onChangeThreshold = (e: number) => {
    setThreshold(e);
  };
  const formatterdB = (value: number | undefined) => `${value} dB`;
  const formatterHz = (value: number | undefined) => `${value} Hz`;
  const formatterPos = (value: number | undefined) => `${value ? (value * 100).toFixed(0) : '0'}%`;
  const formatterSpd = (value: number | undefined) => {
    if (value === undefined) {
      return '0';
    }
    const mappedValue = (value - 5) * (50 / 15);
    return `${mappedValue.toFixed(0)}`;
  };


  return (
    // <div className="app-container" >
    <Row>
      <Col span={24}>
        <Layout className="layoutSample">

          <Content>

            <Flex align="center" justify="center" gap="middle" vertical>
              <div className="currentSelection blueFont">
                <p>Volume mode</p>
              </div>
              <div style={{ width: "100%" }}>
                {renderSelectedComponent()}
              </div>
              <Tooltip title={isPlaying ? "Pause" : "Play"}>
                <Button onClick={onChangePause} shape="circle" icon={isPlaying ? <PauseOutlined /> : <CaretRightOutlined />} />
              </Tooltip>
              <div>
                <div style={{ display: 'flex' }}>
                  <div style={lampStyle(LAMP_COLORS[0])}></div>
                  <div style={lampStyle(LAMP_COLORS[1])}></div>
                  <div style={lampStyle(LAMP_COLORS[2])}></div>
                </div>
              </div>
              <Tooltip title="Setting">
                <FloatButton style={{
                  width: 50,
                  height: 50,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }} onClick={showDrawer} ref={ref3} icon={<SettingOutlined />} />
              </Tooltip>

              <Image
                preview={false}
                src={divide_1}
                style={{ width: '100%' }}
              />

            </Flex>
            <Drawer title="Options" placement="right" onClose={onClose} open={open} afterOpenChange={handleAfterOpenChange} width={430}>
              <Flex vertical gap="middle">

                <Space>
                  <p># Coordinate Display Mode</p>
                  <Radio.Group onChange={onChangeHz} defaultValue="notes" buttonStyle="solid">
                    <Radio.Button value="hz"> Hz </Radio.Button>
                    <Radio.Button value="notes">Notes</Radio.Button>
                  </Radio.Group>
                </Space>

                <Space>
                  <p># History Mode Selection: </p>
                </Space>
                <Space>
                  <Button type={historyMode === 'Size' ? 'primary' : 'default'} onClick={handleHistoryMode}>Size</Button>
                  <Button type={historyMode === 'Color' ? 'primary' : 'default'} onClick={handleHistoryMode}>Color</Button>
                  <Button type={historyMode === 'Mixed' ? 'primary' : 'default'} onClick={handleHistoryMode}>Mixed</Button>
                  <Button type={historyMode === 'None' ? 'primary' : 'default'} onClick={handleHistoryMode}>None</Button>
                </Space>

                <Space>
                  <p># Color Mode</p>

                </Space>
                <Space>
                  <Button onClick={handleColorStyle} type={currentColorScheme === "blue" ? 'primary' : 'default'}>Blue-Red</Button>
                  <Button onClick={handleColorStyle} type={currentColorScheme === "green" ? 'primary' : 'default'}>Green-Red</Button>
                </Space>

                <div>
                  <p># Pitch Display Range</p>
                  <Slider
                    range
                    step={10}
                    min={50}
                    max={600}
                    onChange={onChangeRange}
                    // tooltipPlacement={bottom}
                    // tooltip ={{formatter: null}}
                    tooltip={{ formatter: formatterHz }}
                    marks={marksRange}
                    defaultValue={[100, 300]} />
                </div>


                {component !== "Fixed" && component !== "Stair" && (
                  <div>
                    <p># Pitch Indicator Horizontal Position</p>
                    <Slider
                      step={0.1}
                      min={0}
                      max={1}
                      onChange={onChangePosition}
                      tooltip={{ formatter: formatterPos }}
                      // tooltip ={{formatter: null}}
                      defaultValue={0.5}
                      marks={marksBallPos}
                    />
                  </div>
                )}


                {component !== "Fixed" && component !== "Stair" && component !== "Heteronym" && (
                  <div>
                    <p># Pitch Indicator Horizontal Position</p>
                    <Slider
                      step={0.1}
                      min={0}
                      max={1}
                      onChange={onChangePosition}
                      // tooltip={{ formatter: formatterPos }}
                      // tooltip ={{formatter: null}}
                      tooltip={{
                        // placement: 'bottom',
                        formatter: formatterPos,
                        // open: tooltipVisible,
                      }}
                      defaultValue={0.5}
                      marks={marksBallPos}
                    />
                  </div>
                )}

                <div>
                  <p># Pitch Indicator Speed</p>
                  <Slider
                    step={0.05}
                    min={0}
                    max={1}
                    onChange={onChangeSpeed}
                    // tooltip={{ formatter: formatterPos }}
                    tooltip={{
                      // placement: 'bottom',
                      formatter: formatterPos,
                    }}
                    defaultValue={0.3}
                  // tooltip ={{formatter: null}}
                  // marks={marksBall}
                  />
                </div>

                <div>
                  <Space>
                    <p># Auto-Start When Voice Detected</p>
                    <Switch defaultChecked={false} onChange={EnableVoluneMode} />
                  </Space>

                  {enableVol &&
                    <Slider
                      step={1}
                      min={50}
                      max={90}
                      onChange={onChangeThreshold}
                      tooltip={{
                        // placement: 'bottom',
                        formatter: formatterdB,
                        // open: tooltipVisible,

                      }}
                      defaultValue={70}
                      marks={marksVol}
                    />
                  }
                </div>

                <br />
                <Button onClick={onClose}>Close</Button>
              </Flex>


            </Drawer>
          </Content>
        </Layout>

      </Col>
    </Row>

    // </div>
  );
}

export default Sample;
