import React from 'react';
import MainLayout from './MainLayout';
import PitchPanel from './Deprecated/PitchPanel';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import WebFont from 'webfontloader';
import { ConfigProvider } from 'antd';

WebFont.load({
  google: {
    families: [
      'Roboto:100', 
      'Roboto:300', 
      'Roboto:400', 
      'Roboto:500', 
      'Roboto:700', 
      'Roboto:900',
      'Bungee' 
    ]
  }
});

function App() {
  return (
    // <div className="App">
    
<Router basename="/transvoice">
      <MainLayout />

    </Router>
      // {/* <PitchPanel /> }
      
    // </div>
  );
}
export default App;

/*import { BrowserRouter, Routes, Route } from 'react-router-dom';
import HomePage from "./NewRoute/HomePage";
import ContactPage from './NewRoute/ContactPage';
import React from 'react';

function App() {
  return (

      <div className="App">
          <HomePage />
          <ContactPage />

      </div>
  );
}

export default App;*/


/*import { Routes, Route } from 'react-router-dom';
import Home from "./NewRoute/HomePage";
import Contact from "./NewRoute/ContactPage";
import React from 'react';
import MainLayout from './MainLayout';
import PitchPanel from './Deprecated/PitchPanel';
import WebFont from 'webfontloader';
import { Layout, Menu, Avatar, ConfigProvider } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { Button, Modal, Image} from 'antd';
import About from './Route/About'; 
import './CSS/MainLayout.css';
import logoImage from '../src/icon/new-icon-removebg-preview.png';
import Help from './Route/Help';
import { useLocation } from 'react-router-dom';
import SampleVolume from './Route/SampleVolume';
import Assessment from './NewRoute/Assessment';
import { Space, Dropdown, Radio, Slider} from 'antd';
import type { SliderSingleProps } from 'antd';
import type { RadioChangeEvent } from 'antd';
import Sample from './NewRoute/Settings';

function App() {
  return <Routes>
    <Route path='/transvoice' element={<Home />}/>
    <Route path='/' element={<Home />}/>

    <Route path = '/transvoice/ContactPage' element={<Contact />}/>
    <Route path = '/ContactPage' element={<Contact />}/>

    <Route path= '/Assessment' element={<Assessment />}/>
    <Route path= '/Exercise' element={<SampleVolume />}/>

    <Route path= '/Settings' element={<Sample/>}/>

  </Routes>
}

export default App;*/